@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&subset=cyrillic,cyrillic-ext");
@import url("https://fonts.googleapis.com/css?family=Roboto");
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.icon-analyse-adult {
  width: 1.02em;
  height: 1em;
  fill: #777;
}

.icon-analyse-child {
  width: 1.14em;
  height: 1em;
  fill: #777;
}

.icon-analyse {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-arrow-down-blue {
  width: 1.75em;
  height: 1em;
  fill: initial;
}

.icon-arrow-down {
  width: 1.75em;
  height: 1em;
  fill: initial;
}

.icon-arrow-left {
  width: 0.57em;
  height: 1em;
  fill: #000;
}

.icon-arrow-next {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-arrow-prev {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-arrow-right-breadcrumbs {
  width: 0.57em;
  height: 1em;
  fill: initial;
}

.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: initial;
}

.icon-arrow {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-burger {
  width: 1.25em;
  height: 1em;
  fill: initial;
}

.icon-calendar {
  width: 0.9em;
  height: 1em;
  fill: initial;
}

.icon-check {
  width: 1.33em;
  height: 1em;
  fill: initial;
}

.icon-clients {
  width: 0.83em;
  height: 1em;
  fill: #fff;
}

.icon-clock {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-deadline {
  width: 0.96em;
  height: 1em;
  fill: #fff;
}

.icon-dms {
  width: 0.81em;
  height: 1em;
  fill: #777;
}

.icon-doc-cat {
  width: 1.29em;
  height: 1em;
  fill: #fff;
}

.icon-doc-house {
  width: 0.51em;
  height: 1em;
  fill: #fff;
}

.icon-doctor-adult {
  width: 1.06em;
  height: 1em;
  fill: #777;
}

.icon-doctor-child {
  width: 1.13em;
  height: 1em;
  fill: #777;
}

.icon-email {
  width: 1.45em;
  height: 1em;
  fill: initial;
}

.icon-error {
  width: 1em;
  height: 1em;
  fill: #000;
}

.icon-experience {
  width: 0.88em;
  height: 1em;
  fill: #fff;
}

.icon-eye-circle {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-eye {
  width: 1.47em;
  height: 1em;
  fill: initial;
}

.icon-fb {
  width: 0.54em;
  height: 1em;
  fill: initial;
}

.icon-functional-diagnostics {
  width: 1em;
  height: 1em;
  fill: #777;
}

.icon-gift {
  width: 0.95em;
  height: 1em;
  fill: #ffede7;
}

.icon-hand {
  width: 1.99em;
  height: 1em;
  fill: initial;
}

.icon-like {
  width: 1.11em;
  height: 1em;
  fill: initial;
}

.icon-massage-adult {
  width: 1.33em;
  height: 1em;
  fill: #777;
}

.icon-massage-child {
  width: 1.43em;
  height: 1em;
  fill: #777;
}

.icon-metro {
  width: 1.22em;
  height: 1em;
  fill: initial;
}

.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-procedural {
  width: 1.22em;
  height: 1em;
  fill: #777;
}

.icon-review {
  width: 1.18em;
  height: 1em;
  fill: initial;
}

.icon-sale {
  width: 1.06em;
  height: 1em;
  fill: #ffede7;
}

.icon-search {
  width: 1em;
  height: 1em;
  fill: none;
}

.icon-sertificate-adult {
  width: 1.27em;
  height: 1em;
  fill: #777;
}

.icon-sertificate-child {
  width: 1.37em;
  height: 1em;
  fill: #777;
}

.icon-stacsh {
  width: 1em;
  height: 1em;
  fill: #fff;
}

.icon-stage {
  width: 0.88em;
  height: 1em;
  fill: initial;
}

.icon-success {
  width: 1em;
  height: 1em;
  fill: #000;
}

.icon-time {
  width: 1em;
  height: 1em;
  fill: initial;
}

.icon-tw {
  width: 1.23em;
  height: 1em;
  fill: initial;
}

.icon-uzi-adult {
  width: 1.33em;
  height: 1em;
  fill: #777;
}

.icon-uzi-child {
  width: 1.44em;
  height: 1em;
  fill: #777;
}

.icon-vk {
  width: 0.77em;
  height: 1em;
  fill: initial;
}

.icon-work {
  width: 1.25em;
  height: 1em;
  fill: #fff;
}

.icon-youtube {
  width: 0.84em;
  height: 1em;
  fill: initial;
}

.fancybox-enabled {
  overflow: hidden;
}

.fancybox-enabled body {
  overflow: visible;
  height: 100%;
}

.fancybox-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99993;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

/* Make sure that the first one is on the top */
.fancybox-container ~ .fancybox-container {
  z-index: 99992;
}

.fancybox-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #0f0f11;
  opacity: 0;
  transition-timing-function: cubic-bezier(0.55, 0.06, 0.68, 0.19);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-container--ready .fancybox-bg {
  opacity: 0.87;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  opacity: 0;
  z-index: 99994;
  transition: opacity .2s;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  direction: ltr;
}

.fancybox-show-controls .fancybox-controls {
  opacity: 1;
}

.fancybox-infobar {
  display: none;
}

.fancybox-show-infobar .fancybox-infobar {
  display: inline-block;
  pointer-events: all;
}

.fancybox-infobar__body {
  display: inline-block;
  width: 70px;
  line-height: 44px;
  font-size: 13px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  text-align: center;
  color: #ddd;
  background-color: rgba(30, 30, 30, 0.7);
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: subpixel-antialiased;
}

.fancybox-buttons {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  pointer-events: all;
}

.fancybox-show-buttons .fancybox-buttons {
  display: block;
}

.fancybox-slider-wrap {
  overflow: hidden;
  direction: ltr;
}

.fancybox-slider-wrap,
.fancybox-slider {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 99993;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
}

.fancybox-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: auto;
  outline: none;
  white-space: normal;
  box-sizing: border-box;
  text-align: center;
  z-index: 99994;
  -webkit-overflow-scrolling: touch;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  height: 100%;
  width: 0;
}

.fancybox-slide > * {
  display: inline-block;
  position: relative;
  padding: 24px;
  margin: 44px 0 44px;
  border-width: 0;
  vertical-align: middle;
  text-align: left;
  background-color: #fff;
  overflow: auto;
  box-sizing: border-box;
}

.fancybox-slide--image {
  overflow: hidden;
}

.fancybox-slide--image::before {
  display: none;
}

.fancybox-content {
  display: inline-block;
  position: relative;
  margin: 44px auto;
  padding: 0;
  border: 0;
  width: 80%;
  height: calc(100% - 88px);
  vertical-align: middle;
  line-height: normal;
  text-align: left;
  white-space: normal;
  outline: none;
  font-size: 16px;
  font-family: Arial, sans-serif;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  -webkit-overflow-scrolling: touch;
}

.fancybox-iframe {
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  height: 100%;
  background: #fff;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--video .fancybox-iframe {
  background: transparent;
}

.fancybox-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  border: 0;
  z-index: 99995;
  background: transparent;
  cursor: default;
  overflow: visible;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fancybox-image,
.fancybox-spaceball {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  max-width: none;
  max-height: none;
  background: transparent;
  background-size: 100% 100%;
}

.fancybox-controls--canzoomOut .fancybox-placeholder {
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.fancybox-controls--canzoomIn .fancybox-placeholder {
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.fancybox-controls--canGrab .fancybox-placeholder {
  cursor: -webkit-grab;
  cursor: grab;
}

.fancybox-controls--isGrabbing .fancybox-placeholder {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-tmp {
  position: absolute;
  top: -9999px;
  left: -9999px;
  visibility: hidden;
}

.fancybox-error {
  position: absolute;
  margin: 0;
  padding: 40px;
  top: 50%;
  left: 50%;
  width: 380px;
  max-width: 100%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: #fff;
  cursor: default;
}

.fancybox-error p {
  margin: 0;
  padding: 0;
  color: #444;
  font: 16px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
}

.fancybox-close-small {
  position: absolute;
  top: 0;
  right: 0;
  width: 44px;
  height: 44px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  outline: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;
}

.fancybox-close-small:after {
  content: '×';
  position: absolute;
  top: 5px;
  right: 5px;
  width: 30px;
  height: 30px;
  font: 20px/30px Arial,"Helvetica Neue",Helvetica,sans-serif;
  color: #888;
  font-weight: 300;
  text-align: center;
  border-radius: 50%;
  border-width: 0;
  background: #fff;
  transition: background .2s;
  box-sizing: border-box;
  z-index: 2;
}

.fancybox-close-small:focus:after {
  outline: 1px dotted #888;
}

.fancybox-slide--video .fancybox-close-small {
  top: -36px;
  right: -36px;
  background: transparent;
}

.fancybox-close-small:hover:after {
  color: #555;
  background: #eee;
}

/* Caption */
.fancybox-caption-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 60px 30px 0 30px;
  z-index: 99998;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
  background: linear-gradient(to bottom, transparent 0%, rgba(0, 0, 0, 0.1) 20%, rgba(0, 0, 0, 0.2) 40%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.8) 100%);
  opacity: 0;
  transition: opacity .2s;
  pointer-events: none;
}

.fancybox-show-caption .fancybox-caption-wrap {
  opacity: 1;
}

.fancybox-caption {
  padding: 30px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
  font-size: 14px;
  font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
  color: #fff;
  line-height: 20px;
  -webkit-text-size-adjust: none;
}

.fancybox-caption a,
.fancybox-caption button {
  pointer-events: all;
}

.fancybox-caption a {
  color: #fff;
  text-decoration: underline;
}

/* Buttons */
.fancybox-button {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  line-height: 44px;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  background: transparent;
  color: #fff;
  box-sizing: border-box;
  vertical-align: top;
  outline: none;
}

.fancybox-button--disabled {
  cursor: default;
  pointer-events: none;
}

.fancybox-infobar__body, .fancybox-button {
  background: rgba(30, 30, 30, 0.6);
}

.fancybox-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.fancybox-button::before,
.fancybox-button::after {
  content: '';
  pointer-events: none;
  position: absolute;
  border-color: #fff;
  background-color: currentColor;
  color: currentColor;
  opacity: 0.9;
  box-sizing: border-box;
  display: inline-block;
}

.fancybox-button--disabled::before,
.fancybox-button--disabled::after {
  opacity: 0.5;
}

.fancybox-button--left::after {
  left: 20px;
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.fancybox-button--right::after {
  right: 20px;
  top: 18px;
  width: 6px;
  height: 6px;
  background: transparent;
  border-top: solid 2px currentColor;
  border-right: solid 2px currentColor;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--left {
  border-bottom-left-radius: 5px;
}

.fancybox-button--right {
  border-bottom-right-radius: 5px;
}

.fancybox-button--close {
  float: right;
}

.fancybox-button--close::before, .fancybox-button--close::after {
  content: '';
  display: inline-block;
  position: absolute;
  height: 2px;
  width: 16px;
  top: calc(50% - 1px);
  left: calc(50% - 8px);
}

.fancybox-button--close::before {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fancybox-button--close::after {
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Loading spinner */
.fancybox-loading {
  border: 6px solid rgba(100, 100, 100, 0.4);
  border-top: 6px solid rgba(255, 255, 255, 0.6);
  border-radius: 100%;
  height: 50px;
  width: 50px;
  -webkit-animation: fancybox-rotate .8s infinite linear;
  animation: fancybox-rotate .8s infinite linear;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -25px;
  margin-left: -25px;
  z-index: 99999;
}

@-webkit-keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

@keyframes fancybox-rotate {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-controls {
    text-align: left;
  }
  .fancybox-button--left,
  .fancybox-button--right,
  .fancybox-buttons button:not(.fancybox-button--close) {
    display: none !important;
  }
  .fancybox-caption {
    padding: 20px 0;
    margin: 0;
  }
}

/* Fullscreen  */
.fancybox-button--fullscreen::before {
  width: 15px;
  height: 11px;
  left: 15px;
  top: 16px;
  border: 2px solid;
  background: none;
}

/* Slideshow button */
.fancybox-button--play::before {
  top: 16px;
  left: 18px;
  width: 0;
  height: 0;
  border-top: 6px inset transparent;
  border-bottom: 6px inset transparent;
  border-left: 10px solid;
  border-radius: 1px;
  background: transparent;
}

.fancybox-button--pause::before {
  top: 16px;
  left: 18px;
  width: 7px;
  height: 11px;
  border-style: solid;
  border-width: 0 2px 0 2px;
  background: transparent;
}

/* Thumbs */
.fancybox-button--thumbs span {
  font-size: 23px;
}

.fancybox-button--thumbs::before {
  top: 20px;
  left: 21px;
  width: 3px;
  height: 3px;
  box-shadow: 0 -4px 0, -4px -4px 0, 4px -4px 0, 0 0 0 32px inset, -4px 0 0, 4px 0 0, 0 4px 0, -4px 4px 0, 4px 4px 0;
}

.fancybox-container--thumbs .fancybox-controls,
.fancybox-container--thumbs .fancybox-slider-wrap,
.fancybox-container--thumbs .fancybox-caption-wrap {
  right: 220px;
}

.fancybox-thumbs {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: auto;
  width: 220px;
  margin: 0;
  padding: 5px 5px 0 0;
  background: #fff;
  z-index: 99993;
  word-break: normal;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}

.fancybox-thumbs > ul {
  list-style: none;
  position: absolute;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 0;
}

.fancybox-thumbs > ul > li {
  float: left;
  overflow: hidden;
  max-width: 50%;
  padding: 0;
  margin: 0;
  width: 105px;
  height: 75px;
  position: relative;
  cursor: pointer;
  outline: none;
  border: 5px solid #fff;
  border-top-width: 0;
  border-right-width: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  box-sizing: border-box;
}

li.fancybox-thumbs-loading {
  background: rgba(0, 0, 0, 0.1);
}

.fancybox-thumbs > ul > li > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  max-width: none;
  max-height: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-thumbs > ul > li:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  border: 4px solid #4ea7f9;
  z-index: 99991;
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.fancybox-thumbs > ul > li.fancybox-thumbs-active:before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */
@media all and (max-width: 800px) {
  .fancybox-thumbs {
    display: none !important;
  }
  .fancybox-container--thumbs .fancybox-controls,
  .fancybox-container--thumbs .fancybox-slider-wrap,
  .fancybox-container--thumbs .fancybox-caption-wrap {
    right: 0;
  }
}

/*===============================
=            Choices            =
===============================*/
.choices {
  position: relative;
  margin-bottom: 24px;
  font-size: 16px;
}

.choices:focus {
  outline: none;
}

.choices:last-child {
  margin-bottom: 0;
}

.choices.is-disabled .choices__inner, .choices.is-disabled .choices__input {
  background-color: #EAEAEA;
  cursor: not-allowed;
  user-select: none;
}

.choices.is-disabled .choices__item {
  cursor: not-allowed;
}

.choices[data-type*="select-one"] {
  cursor: pointer;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 7.5px;
}

.choices[data-type*="select-one"] .choices__input {
  display: block;
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #DDDDDD;
  background-color: #FFFFFF;
  margin: 0;
}

.choices[data-type*="select-one"] .choices__button {
  background-image: url("../../icons/cross-inverse.svg");
  padding: 0;
  background-size: 8px;
  height: 100%;
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
  margin-right: 25px;
  height: 20px;
  width: 20px;
  border-radius: 10em;
  opacity: .5;
}

.choices[data-type*="select-one"] .choices__button:hover, .choices[data-type*="select-one"] .choices__button:focus {
  opacity: 1;
}

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #00BCD4;
}

.choices[data-type*="select-one"]:after {
  content: "";
  height: 0;
  width: 0;
  border-style: solid;
  border-color: #333333 transparent transparent transparent;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  margin-top: -2.5px;
  pointer-events: none;
}

.choices[data-type*="select-one"].is-open:after {
  border-color: transparent transparent #333333 transparent;
  margin-top: -7.5px;
}

.choices[data-type*="select-one"][dir="rtl"]:after {
  left: 11.5px;
  right: auto;
}

.choices[data-type*="select-one"][dir="rtl"] .choices__button {
  right: auto;
  left: 0;
  margin-left: 25px;
  margin-right: 0;
}

.choices[data-type*="select-multiple"] .choices__inner, .choices[data-type*="text"] .choices__inner {
  cursor: text;
}

.choices[data-type*="select-multiple"] .choices__button, .choices[data-type*="text"] .choices__button {
  position: relative;
  display: inline-block;
  margin-top: 0;
  margin-right: -4px;
  margin-bottom: 0;
  margin-left: 8px;
  padding-left: 16px;
  border-left: 1px solid #008fa1;
  background-image: url("../../icons/cross.svg");
  background-size: 8px;
  width: 8px;
  line-height: 1;
  opacity: .75;
}

.choices[data-type*="select-multiple"] .choices__button:hover, .choices[data-type*="select-multiple"] .choices__button:focus, .choices[data-type*="text"] .choices__button:hover, .choices[data-type*="text"] .choices__button:focus {
  opacity: 1;
}

.choices__inner {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  background-color: #f9f9f9;
  padding: 7.5px 7.5px 3.75px;
  border: 1px solid #DDDDDD;
  border-radius: 2.5px;
  font-size: 14px;
  min-height: 44px;
  overflow: hidden;
}

.is-focused .choices__inner, .is-open .choices__inner {
  border-color: #b7b7b7;
}

.is-open .choices__inner {
  border-radius: 2.5px 2.5px 0 0;
}

.is-flipped.is-open .choices__inner {
  border-radius: 0 0 2.5px 2.5px;
}

.choices__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
}

.choices__list--single {
  display: inline-block;
  padding: 4px 16px 4px 4px;
  width: 100%;
}

[dir="rtl"] .choices__list--single {
  padding-right: 4px;
  padding-left: 16px;
}

.choices__list--single .choices__item {
  width: 100%;
}

.choices__list--multiple {
  display: inline;
}

.choices__list--multiple .choices__item {
  display: inline-block;
  vertical-align: middle;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 3.75px;
  margin-bottom: 3.75px;
  background-color: #00BCD4;
  border: 1px solid #00a5bb;
  color: #FFFFFF;
  word-break: break-all;
}

.choices__list--multiple .choices__item[data-deletable] {
  padding-right: 5px;
}

[dir="rtl"] .choices__list--multiple .choices__item {
  margin-right: 0;
  margin-left: 3.75px;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: #00a5bb;
  border: 1px solid #008fa1;
}

.is-disabled .choices__list--multiple .choices__item {
  background-color: #aaaaaa;
  border: 1px solid #919191;
}

.choices__list--dropdown {
  display: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  background-color: #FFFFFF;
  border: 1px solid #DDDDDD;
  top: 100%;
  margin-top: -1px;
  border-bottom-left-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
  overflow: hidden;
  word-break: break-all;
}

.choices__list--dropdown.is-active {
  display: block;
}

.is-open .choices__list--dropdown {
  border-color: #b7b7b7;
}

.is-flipped .choices__list--dropdown {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: -1px;
  border-radius: .25rem .25rem 0 0;
}

.choices__list--dropdown .choices__list {
  position: relative;
  max-height: 300px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  will-change: scroll-position;
}

.choices__list--dropdown .choices__item {
  position: relative;
  padding: 10px;
  font-size: 14px;
}

[dir="rtl"] .choices__list--dropdown .choices__item {
  text-align: right;
}

@media (min-width: 640px) {
  .choices__list--dropdown .choices__item--selectable {
    padding-right: 100px;
  }
  .choices__list--dropdown .choices__item--selectable:after {
    content: attr(data-select-text);
    font-size: 12px;
    opacity: 0;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable {
    text-align: right;
    padding-left: 100px;
    padding-right: 10px;
  }
  [dir="rtl"] .choices__list--dropdown .choices__item--selectable:after {
    right: auto;
    left: 10px;
  }
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #f2f2f2;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted:after {
  opacity: .5;
}

.choices__item {
  cursor: default;
}

.choices__item--selectable {
  cursor: pointer;
}

.choices__item--disabled {
  cursor: not-allowed;
  user-select: none;
  opacity: .5;
}

.choices__heading {
  font-weight: 600;
  font-size: 12px;
  padding: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: gray;
}

.choices__button {
  text-indent: -9999px;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.choices__button:focus {
  outline: none;
}

.choices__input {
  display: inline-block;
  vertical-align: baseline;
  background-color: #f9f9f9;
  font-size: 14px;
  margin-bottom: 5px;
  border: 0;
  border-radius: 0;
  max-width: 100%;
  padding: 4px 0 4px 2px;
}

.choices__input:focus {
  outline: 0;
}

[dir="rtl"] .choices__input {
  padding-right: 2px;
  padding-left: 0;
}

.choices__placeholder {
  opacity: .5;
}

/*=====  End of Choices  ======*/
.rateit {
  display: -moz-inline-box;
  display: inline-block;
  position: relative;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
}

.rateit .rateit-range {
  position: relative;
  display: -moz-inline-box;
  display: inline-block;
  height: 16px;
  outline: none;
}

.rateit .rateit-range * {
  display: block;
}

.rateit .rateit-hover,
.rateit .rateit-selected {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
}

.rateit .rateit-hover-rtl,
.rateit .rateit-selected-rtl {
  left: auto;
  right: 0;
}

.rateit button.rateit-reset {
  width: 16px;
  height: 16px;
  display: -moz-inline-box;
  display: inline-block;
  float: left;
  outline: none;
  border: none;
  padding: 0;
}

.rateit .rateit-reset span {
  display: none;
}

.rateit button.rateit-reset:hover,
.rateit button.rateit-reset:focus {
  background-position: 0 -16px;
}

.rateit-font {
  font-size: 24px;
  line-height: 1em;
}

.rateit-font .rateit-range {
  background: none;
  height: auto;
}

.rateit-font .rateit-empty {
  color: #ccc;
}

.rateit-font .rateit-range > div {
  background: none;
  overflow: hidden;
  cursor: default;
}

.rateit.rateit-font .rateit-reset {
  font-size: inherit;
  background: none;
  width: 0.6em;
  height: 0.6em;
  margin-top: 0.2em;
  background: gray;
  border-radius: 50%;
  position: relative;
}

.rateit.rateit-font .rateit-reset span {
  display: block;
  font-weight: bold;
  color: white;
  height: calc(50% - 0.045em);
  top: 0;
  position: absolute;
  border-bottom: 0.09em solid white;
  width: 72%;
  margin-left: 14%;
}

.rateit.rateit-font .rateit-reset:hover, .rateit.rateit-font button.rateit-reset:focus {
  background: #e6574b;
  /* Old browsers */
  background: radial-gradient(ellipse at center, #e6574b 55%, #f6836b 77%, #f9d3cc 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  background-position: 0 0;
}

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform .1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

@font-face {
  font-family: MyriadProRegular;
  src: url("fonts/MyriadProRegular.woff2") format("woff2"), url("fonts/MyriadProRegular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoSansCyrl300;
  src: url("fonts/MuseoSansCyrl300.woff2") format("woff2"), url("fonts/MuseoSansCyrl300.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoSansCyrl500;
  src: url("fonts/MuseoSansCyrl500.woff2") format("woff2"), url("fonts/MuseoSansCyrl500.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: MuseoSansCyrl700;
  src: url("fonts/MuseoSansCyrl700.woff2") format("woff2"), url("fonts/MuseoSansCyrl700.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  min-width: 320px;
  font-size: 15px;
  color: #444;
}

body.no-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  body.touch-device .nav__item:hover .nav__drop {
    display: none;
  }
}

body.touch-device .nav__item.hover > .nav__link .icon-arrow-down {
  opacity: 0;
  transform: translateY(-1px);
}

body.touch-device .nav__item.hover > .nav__link .icon-close {
  opacity: 1;
}

@media only screen and (max-width: 767px) {
  body.touch-device .nav__item.hover .nav__drop {
    display: block;
  }
}

textarea {
  border: none;
}

a {
  text-decoration: none;
  color: #444;
}

button {
  background: none;
}

.out {
  position: relative;
  min-height: 100vh;
  background-color: #eceff1;
  overflow: hidden;
}

.btn-scroll {
  position: fixed;
  bottom: 30px;
  left: 200px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  z-index: 1000;
}

.btn-scroll.visible {
  opacity: 1;
  pointer-events: all;
}

@media only screen and (max-width: 1900px) {
  .btn-scroll {
    left: 50px;
  }
}

@media only screen and (max-width: 1600px) {
  .btn-scroll {
    left: 10px;
    bottom: 10px;
    left: 30px;
  }
}

@media only screen and (max-width: 1169px) {
  .btn-scroll {
    bottom: 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .btn-scroll {
    left: 20px;
    bottom: 55px;
  }
}

@media only screen and (max-width: 767px) {
  .btn-scroll {
    left: 5px;
    bottom: 5px;
  }
}

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
}

@media only screen and (max-width: 1023px) {
  .center {
    padding: 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .center {
    padding: 0 15px;
  }
}

.center_small {
  max-width: 660px;
  padding: 0 15px;
}

.center_big {
  max-width: 1360px;
}

.header {
  position: relative;
  z-index: 20;
}

.header__nav {
  display: flex;
  justify-content: center;
}

.header__top {
  padding: 11px 0;
  background: #02519C;
}

@media only screen and (max-width: 767px) {
  .header__top {
    height: 40px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__top .header__center {
    display: none;
  }
}

.header__link {
  margin-right: 38px;
  font-size: 12px;
  line-height: 1.5;
  color: #c5daf9;
  transition: color .2s;
}

.header__link:last-child {
  margin-right: 0;
}

.header__link:hover {
  color: white;
}

.header__container {
  padding: 18px 0 16px;
  background-color: #0277bd;
}

@media only screen and (max-width: 1169px) {
  .header__container {
    padding: 18px 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__container {
    padding: 25px 0;
  }
}

.header__inner {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

@media only screen and (max-width: 1023px) {
  .header__inner {
    align-items: center;
  }
}

.header__logo {
  padding-top: 4px;
}

.header__schedule, .header__address {
  padding: 0 5px;
}

@media only screen and (max-width: 1023px) {
  .header__schedule .schedule__icon {
    display: none;
  }
}

@media only screen and (max-width: 1169px) {
  .header__address {
    max-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .header__address {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    padding: 13px 15px 12px 15px;
  }
}

@media only screen and (max-width: 767px) {
  .header__address .address__icon {
    display: block;
    font-size: 0;
  }
  .header__address .address__icon .icon {
    vertical-align: middle;
  }
}

.header__address .address__icon.active .icon-arrow-down {
  transform: rotate(180deg);
}

@media only screen and (max-width: 767px) {
  .header__address .address__info {
    display: none;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    padding: 0 15px 11px 15px;
    background: #02519C;
  }
}

@media only screen and (max-width: 767px) {
  .header__address .address__info.visible {
    display: block;
  }
}

.header__phone {
  margin-top: -6px;
}

@media only screen and (max-width: 1169px) {
  .header__phone {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__phone {
    position: absolute;
    top: 10px;
    right: 15px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .header__phone .phone__number {
    display: flex;
    margin: 0;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .header__phone .phone__btn {
    display: none;
  }
}

.header__phone .phone__number .icon {
  display: none;
  margin: -4px 10px 0 0;
  fill: white;
}

@media only screen and (max-width: 767px) {
  .header__phone .phone__number .icon {
    display: inline-block;
  }
}

.logo {
  display: inline-block;
  position: relative;
  font-size: 0;
}

.logo__pic {
  width: 184px;
}

@media only screen and (max-width: 1023px) {
  .logo__pic {
    width: 160px;
  }
}

.logo__text {
  position: absolute;
  right: 0;
  bottom: 3px;
  font-size: 12px;
  color: #c5daf9;
}

.logo_footer .logo__pic {
  width: 130px;
}

.schedule {
  display: flex;
  align-items: flex-start;
}

.schedule__icon {
  margin-right: 10px;
}

.schedule__icon .icon {
  font-size: 15px;
  fill: #c5daf9;
}

.schedule__info {
  color: #c5daf9;
}

.schedule__item {
  display: flex;
  margin-bottom: 8px;
  align-items: flex-end;
}

.schedule__item:last-child {
  margin-bottom: 0;
}

.schedule__days {
  margin-right: 3px;
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
}

.schedule__time {
  font-size: 0;
  line-height: 1;
}

.schedule__hours, .schedule__minutes {
  display: inline-block;
  vertical-align: top;
}

.schedule__hours {
  font-size: 15px;
}

.schedule__minutes {
  font-size: 10px;
}

.schedule_black .schedule__info {
  color: #444;
}

.schedule_black .schedule__icon .icon {
  fill: #444;
}

.schedule_opacity .schedule__icon .icon {
  opacity: .5;
}

.schedule_widget .schedule__icon {
  margin-right: 17px;
}

.address {
  display: flex;
  align-items: flex-start;
}

.address__icon {
  display: block;
  margin-right: 13px;
}

@media only screen and (max-width: 1023px) {
  .address__icon {
    margin-right: 5px;
  }
}

.address__icon .icon-metro {
  font-size: 15px;
  fill: #c5daf9;
}

.address__icon .icon-arrow-down {
  display: none;
  font-size: 5px;
  fill: #c5daf9;
}

@media only screen and (max-width: 767px) {
  .address__icon .icon-arrow-down {
    display: inline-block;
    margin-left: 10px;
    font-size: 5px;
    fill: #c5daf9;
  }
}

.address__info {
  color: #c5daf9;
}

.address__metro {
  margin-bottom: 8px;
  font-weight: 700;
  line-height: 1.2;
}

.address__name {
  margin-bottom: 6px;
}

@media only screen and (max-width: 1169px) {
  .address__span {
    display: block;
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 1023px) {
  .address__wrap {
    margin-left: -23px;
  }
}

@media only screen and (max-width: 767px) {
  .address__wrap {
    margin-left: 0;
  }
}

.address__maps {
  border-bottom: 1px dashed #c5daf9;
  color: #c5daf9;
  transition: border-color .2s;
}

.address__maps:hover {
  border-color: transparent;
}

.address_black .address__info {
  color: #444;
}

.address_black .address__maps {
  border-bottom: 1px dashed #3b83eb;
  color: #3b83eb;
}

.address_black .address__maps:hover {
  border-color: transparent;
}

.address_black .address__icon .icon-metro {
  fill: #444;
}

.address_opacity .address__icon .icon-metro {
  opacity: .5;
}

.phone {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phone__number {
  display: inline-block;
  margin-bottom: 10px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
  line-height: 1.38889;
  color: white;
}

.phone__number .icon {
  font-size: 18px;
}

@media only screen and (max-width: 1023px) {
  .phone__btn {
    min-width: 157px;
  }
}

.phone_black .phone__number {
  color: #444;
}

.phone_black .phone__btn {
  min-width: 200px;
}

.phone_simple {
  flex-direction: row;
}

.phone_simple .icon {
  margin: -2px 13px 0 0;
}

.phone_simple .phone__number {
  display: flex;
  align-items: center;
  margin: 0;
}

.phone_widget .icon {
  fill: white;
}

.phone_widget .phone__number {
  font-family: 'MuseoSansCyrl500', serif;
}

.email {
  display: flex;
  align-items: flex-start;
}

.email__icon {
  margin-right: 12px;
}

.email__icon .icon {
  font-size: 13px;
  fill: #959697;
}

.email__info {
  font-family: 'MuseoSansCyrl700', serif;
  color: #444;
}

.email__title {
  margin-bottom: 8px;
}

.email_simple .email__info {
  display: flex;
}

.btn {
  display: inline-block;
  min-width: 174px;
  padding: 17px 15px 15px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 12px;
  text-transform: uppercase;
  transition: background .2s, color .2s, box-shadow .2s;
}

.btn:hover {
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
}

.btn_icon {
  padding: 15px 5px;
}

.btn_icon .icon {
  vertical-align: middle;
  fill: #444;
  transition: fill .2s;
}

.btn_icon .icon-phone {
  margin: -2px 10px 0 0;
  font-size: 15px;
}

.btn_icon .icon-close {
  margin-top: -2px;
  font-size: 12px;
}

.btn_icon .icon-arrow {
  font-size: 14px;
  transform: rotate(90deg);
  fill: white;
}

.btn_icon:hover .icon {
  fill: #3b83eb;
}

.btn_icon .btn__title {
  display: inline-block;
  vertical-align: middle;
}

.btn_white {
  border: 1px solid #f1f1f1;
  background-color: white;
  color: #444;
}

.btn_white:hover {
  color: #3b83eb;
}

.btn_red {
  background-color: #ff5e6a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  color: white;
}

.btn_red:hover {
  background-color: #ff4957;
}

.btn_blue {
  background-color: #3b83eb;
  color: white;
}

.btn_blue:hover {
  background-color: #2f7ceb;
}

.btn.disabled, .btn[disabled] {
  background-color: #e6e8ea;
  box-shadow: none;
  color: #777;
  cursor: not-allowed;
}

.btn.disabled:hover, .btn[disabled]:hover {
  box-shadow: none;
  background-color: #e6e8ea;
}

.btn_square {
  min-width: 40px;
  height: 40px;
}

.btn_circle {
  min-width: 55px;
  height: 55px;
  border-radius: 50%;
  background-color: #3b83eb;
  box-shadow: none;
}

.btn_circle:hover {
  box-shadow: none;
  background: #ff5e6a;
}

.btn_circle:hover .icon {
  fill: white;
}

.btn_small {
  padding: 14px 15px 12px;
}

.btn_big {
  padding: 24px 15px 22px;
}

@media only screen and (max-width: 767px) {
  .btn_big {
    padding: 19px 15px 17px;
  }
}

.nav {
  position: relative;
  background: white;
  z-index: 20;
}

.nav:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 6;
}

.nav__menu {
  display: flex;
  margin: 0 15px 0 35px;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .nav__menu {
    margin: 0;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .nav__inner {
    margin-right: auto;
  }
}

.nav__burger {
  position: relative;
  display: none;
  width: 50px;
  height: 60px;
  z-index: 24;
}

@media only screen and (max-width: 767px) {
  .nav__burger {
    display: block;
  }
}

.nav__burger .icon {
  margin-top: -2px;
  fill: #777;
}

.nav__burger .icon-burger {
  font-size: 13px;
}

.nav__burger .icon-close {
  display: none;
  font-size: 13px;
}

.nav__burger.active .icon-burger {
  display: none;
}

.nav__burger.active .icon-close {
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .nav__box {
    display: none;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    padding: 30px 15px 35px 30px;
    border-radius: 0px 0px 5px 5px;
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 2;
  }
}

.nav__box.visible {
  display: flex;
}

.nav__section {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .nav__section {
    flex-direction: column;
    margin-right: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__section .nav__item {
    margin-bottom: 16px;
  }
  .nav__section .nav__item:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .nav__section .nav__item > .nav__link {
    height: auto;
    padding: 5px 10px;
  }
}

.nav__chapter {
  display: none;
}

@media only screen and (max-width: 767px) {
  .nav__chapter {
    display: flex;
    flex-direction: column;
  }
}

.nav__chapter .nav__link {
  margin-bottom: 5px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 18px;
  color: #777;
}

.nav__chapter .nav__link:last-child {
  margin-bottom: 0;
}

.nav__item {
  display: block;
}

.nav__item > .nav__link {
  position: relative;
  display: flex;
  height: 60px;
  padding: 18px 24px;
  align-items: center;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 14px;
  line-height: 1.78571;
  text-transform: uppercase;
  transition: color .2s, box-shadow .2s;
  z-index: 8;
}

.nav__item > .nav__link .icon {
  transition: opacity .2s, transform .2s;
}

.nav__item > .nav__link .icon-arrow-down {
  margin: -2px 0 0 11px;
  font-size: 5px;
}

.nav__item > .nav__link .icon-close {
  position: absolute;
  top: 25px;
  right: 0;
  font-size: 9px;
  opacity: 0;
}

.nav__menu .nav__link_simple {
  cursor: default;
}

@media only screen and (max-width: 767px) {
  .nav__menu .nav__link_simple {
    padding: 18px 0 18px 0px;
  }
}

.nav__item_search, .nav__item_phone {
  position: relative;
  z-index: 8;
}

.nav__item_search {
  width: 75px;
  height: 60px;
  font-size: 0;
}

.nav__item_search .icon-search {
  fill: #444;
  font-size: 19px;
}

.nav__item_search .icon-close {
  display: none;
  font-size: 14px;
}

.nav__item_search.active {
  box-shadow: inset 0 -3px 0 #e6e8ea;
}

.nav__item_search.active .icon-search {
  display: none;
}

.nav__item_search.active .icon-close {
  display: inline-block;
}

.nav__item_phone {
  display: none;
  width: 46px;
  height: 46px;
  margin-left: 15px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

@media only screen and (max-width: 767px) {
  .nav__item_phone {
    display: block;
  }
}

.nav__item_search > .nav__link {
  padding: 0;
}

.nav__item_phone .icon {
  font-size: 16px;
  fill: #444;
}

.nav__item:hover > .nav__link {
  box-shadow: inset 0 -3px 0 #3b83eb;
  color: #121212;
}

.nav__item:hover .nav__drop {
  display: block;
}

.nav__drop {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
}

.nav__l {
  display: flex;
  margin-bottom: 20px;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  justify-content: center;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .nav__l {
    display: block;
    position: relative;
    margin: 0 -15px;
    overflow: visible;
  }
}

.nav__sidebar {
  position: relative;
  flex: 0 0 300px;
  min-height: 520px;
  background-color: #3b83eb;
}

@media only screen and (min-width: 1023px) and (max-height: 730px) {
  .nav__sidebar {
    min-height: 413px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__sidebar {
    flex: 0 0 283px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__sidebar {
    min-height: 0;
    flex: 0 0 100%;
    z-index: 2;
  }
}

.nav__sort {
  display: flex;
  position: relative;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.nav__sort .nav__link {
  padding: 30px 20px 21px;
  border-bottom: 3px solid transparent;
  font-size: 13px;
  text-transform: uppercase;
  color: #c5daf9;
  transition: color .2s, border-color .2s;
}

.nav__sort .nav__link:hover {
  color: white;
}

.nav__sort .nav__link.active {
  border-color: white;
  color: white;
}

.nav__types {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px 0;
  border-radius: 0px 0px 0px 5px;
  direction: rtl;
  overflow: auto;
  z-index: 6;
}

@media only screen and (max-width: 767px) {
  .nav__types {
    position: relative;
    top: auto;
  }
}

.nav__types::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

.nav__types::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 2px #3270c8;
  background-color: #5f9df6;
  border-radius: 5px;
}

.nav__types::-webkit-scrollbar-track {
  background-color: #3270c8;
  background-image: url("/img/scroll-up.png"), url("/img/scroll-down.png");
  background-repeat: no-repeat, no-repeat;
  background-position: 0 0, 0 100%;
  background-size: contain, contain;
}

.nav__list {
  direction: ltr;
}

.nav__list .nav__link {
  display: flex;
  padding: 11px 38px 10px 20px;
  color: white;
  align-items: center;
  transition: background .2s;
}

@media only screen and (max-width: 1023px) {
  .nav__list .nav__link {
    padding: 11px 21px 10px 20px;
  }
}

.nav__list .nav__link:after {
  content: "";
  display: block;
  position: relative;
  top: 1px;
  margin-left: 10px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 4.5px 0 4.5px 5px;
  border-color: transparent transparent transparent white;
}

.nav__list .nav__link:hover {
  background: #4a8ceb;
}

.nav__list .nav__link.active {
  background: #377adb;
}

.nav__total {
  display: inline-block;
  margin-top: 10px;
}

.nav__container {
  flex: 0 0 calc(100% - 300px);
  max-width: calc(100% - 300px);
  background: white;
}

@media only screen and (max-width: 1023px) {
  .nav__container {
    flex: 0 0 calc(100% - 283px);
    max-width: calc(100% - 283px);
  }
}

@media only screen and (max-width: 767px) {
  .nav__container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 4;
  }
  .nav__container.visible {
    display: block;
  }
}

.nav__back {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  bottom: 0;
  background: #3b83eb;
}

@media only screen and (max-width: 767px) {
  .nav__back {
    display: block;
  }
}

.nav__back .icon {
  font-size: 10px;
  fill: white;
}

.nav__group {
  display: none;
}

@media only screen and (max-width: 1023px) {
  .nav__group_hide {
    display: none;
  }
}

.nav__group.active {
  display: block;
}

.nav__head {
  display: flex;
  align-items: center;
  padding: 21px 30px 23px 42px;
}

@media only screen and (min-width: 1023px) and (max-height: 730px) {
  .nav__head {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

@media only screen and (max-width: 1169px) {
  .nav__head {
    padding: 20px 20px 25px 30px;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__head {
    padding: 23px 5px 25px 27px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__head {
    display: block;
    padding: 15px 15px 20px 20px;
  }
}

.nav__title {
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  line-height: 1.44;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .nav__title {
    font-size: 18px;
  }
}

.nav__categories {
  padding: 13px 15px 0 43px;
  box-shadow: 0 1px 0 #e6e8ea, inset 0 1px 0 #e6e8ea;
}

@media only screen and (max-width: 1169px) {
  .nav__categories {
    padding: 13px 15px 0 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__categories {
    padding: 13px 10px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__categories {
    padding: 13px 0 3px 20px;
  }
}

.nav__categories .nav__link {
  display: inline-block;
  margin: 0 29px 17px 0;
}

@media only screen and (max-width: 767px) {
  .nav__categories .nav__link {
    margin: 0 15px 10px 0;
  }
}

.nav__categories .nav__link:last-child {
  margin-right: 0;
}

.nav__wrap {
  padding: 25px 20px 20px 43px;
}

@media only screen and (min-width: 1023px) and (max-height: 730px) {
  .nav__wrap {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
}

@media only screen and (max-width: 1169px) {
  .nav__wrap {
    padding: 25px 20px 20px 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__wrap {
    padding: 33px 10px 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__wrap {
    padding: 20px;
  }
}

.nav__direction {
  text-align: center;
  padding-top: 40px;
}

.nav__direction-icon {
  margin: 0 0 40px -160px;
}

@media only screen and (max-width: 1169px) {
  .nav__direction-icon {
    margin: 0 0 40px -135px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__direction-icon {
    margin: 0 0 40px -40px;
  }
}

.nav__direction-icon .icon {
  font-size: 200px;
  fill: #e6e8ea;
}

@media only screen and (min-width: 1023px) and (max-height: 730px) {
  .nav__direction-icon .icon {
    font-size: 165px;
  }
}

@media only screen and (max-width: 1169px) {
  .nav__direction-icon .icon {
    font-size: 156px;
  }
}

.nav__direction-text {
  width: 100%;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
}

.nav__btns {
  flex: 0 0 55%;
  text-align: right;
}

@media only screen and (max-width: 1169px) {
  .nav__btns {
    margin-top: 15px;
    flex: 0 0 100%;
    text-align: left;
  }
}

.nav__btns .nav__btn {
  min-width: 190px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .nav__btns .nav__btn {
    min-width: 100%;
  }
}

.nav__btns .nav__btn:not(:first-child) {
  margin-left: 20px;
}

@media only screen and (max-width: 1240px) {
  .nav__btns .nav__btn:not(:first-child) {
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__btns .nav__btn:not(:first-child) {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__btns .nav__btn:not(:first-child) {
    margin: 10px 0 0;
  }
}

.nav__services_links {
  margin-bottom: 20px;
  column-count: 3;
}

@media only screen and (max-width: 1023px) {
  .nav__services_links {
    margin-bottom: 10px;
    column-count: 2;
    column-gap: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__services_links {
    column-count: 1;
    column-gap: 0;
  }
}

.nav__services_preview {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .nav__services_links .nav__link:nth-child(n+9) {
    display: none;
  }
}

.nav__services .nav__link {
  display: block;
  margin-bottom: 20px;
  text-decoration: underline;
  color: #444;
}

.nav__services .nav__link:last-child {
  margin-bottom: 0;
}

.nav__services .nav__link:hover {
  text-decoration: none;
}

.nav__col:first-child {
  flex: 0 0 33.33%;
  padding-right: 10px;
}

@media only screen and (max-width: 1169px) {
  .nav__col:first-child {
    flex: 0 0 40%;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__col:first-child {
    flex: 0 0 100%;
    margin-bottom: 10px;
    column-count: 2;
    column-gap: 45px;
  }
}

@media only screen and (max-width: 767px) {
  .nav__col:first-child {
    column-count: 1;
    column-gap: 0;
  }
}

.nav__col:last-child {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}

@media only screen and (max-width: 1169px) {
  .nav__col:last-child {
    flex: 0 0 60%;
    max-width: 60%;
    padding: 0 50px;
  }
}

@media only screen and (max-width: 1023px) {
  .nav__col:last-child {
    display: none;
  }
}

.nav__all {
  padding-left: 68%;
}

@media only screen and (max-width: 1023px) {
  .nav__all {
    padding-left: 56%;
  }
}

@media only screen and (max-width: 767px) {
  .nav__all {
    padding: 0;
  }
}

.nav__search {
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 4;
}

.link {
  text-decoration: underline;
  color: #3b83eb;
  transition: color .2s;
}

.link:hover {
  color: #ff5e6a;
}

.link:active {
  color: #901148;
}

.search {
  display: none;
  max-width: 770px;
  margin: 0 auto;
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1023px) {
  .search {
    margin: 0 25px;
  }
}

@media only screen and (max-width: 767px) {
  .search {
    margin: 0 15px;
  }
}

.search.visible {
  display: block;
}

.search__input {
  width: 100%;
  height: 80px;
  padding: 0 80px 0 40px;
  background: none;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 18px;
  line-height: 1.44444;
}

.search__input::placeholder {
  color: #7d8b99;
}

@media only screen and (max-width: 1169px) {
  .search__input {
    height: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .search__input {
    height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .search__input {
    padding: 0 50px 0 15px;
  }
}

.search__btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 80px;
  height: 80px;
  transition: fill .2s;
}

@media only screen and (max-width: 1169px) {
  .search__btn {
    height: 60px;
  }
}

@media only screen and (max-width: 1023px) {
  .search__btn {
    height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .search__btn {
    width: 50px;
  }
}

.search__btn:hover .icon-search {
  fill: #3b83eb;
}

.search .icon {
  fill: #444;
}

.search .icon-search {
  font-size: 19px;
}

.social {
  display: flex;
}

.social__item {
  margin-right: 15px;
}

.social__item:last-child {
  margin-right: 0;
}

.social__icon {
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: background .2s;
}

.social__icon .icon {
  fill: white;
  transition: fill .2s;
}

.social__icon .icon-vk {
  font-size: 13px;
}

.social__icon .icon-tw {
  font-size: 11px;
}

.social__icon .icon-youtube {
  font-size: 18px;
}

.social__icon .icon-fb {
  font-size: 20px;
}

.social__text {
  margin-left: 20px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
  color: #121212;
  transition: color .2s;
}

.social_row {
  flex-direction: column;
}

@media only screen and (max-width: 1023px) {
  .social_row {
    flex-direction: row;
  }
}

@media only screen and (max-width: 767px) {
  .social_row {
    flex-direction: column;
  }
}

.social_row .social__item {
  margin: 0 0 15px 0;
}

@media only screen and (max-width: 1023px) {
  .social_row .social__item {
    margin: 0 50px 0 0;
  }
}

@media only screen and (max-width: 767px) {
  .social_row .social__item {
    margin: 0 0 20px 0;
  }
}

.social_gray .social__item {
  margin-right: 8px;
}

.social_gray .social__item:last-child {
  margin-right: 0;
}

.social_gray .social__icon {
  background: #C6C7C9;
}

.social_gray .social__icon .icon {
  fill: #777;
}

.social_gray .social__icon:hover {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
}

.social_gray .social__icon:hover .icon {
  fill: white;
}

.social_gray .social__item_vk:hover .social__icon {
  background-color: #507299;
}

.social_gray .social__item_tw:hover .social__icon {
  background-color: #1da1f2;
}

.social_gray .social__item_youtube:hover .social__icon {
  background-color: #cc181e;
}

.social_color .social__item {
  display: flex;
  align-items: center;
}

.social_color .social__item:last-child {
  margin: 0;
}

.social_color .social__icon {
  width: 55px;
  height: 55px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
}

.social_color .social__item_vk .social__icon {
  background-color: #507299;
}

.social_color .social__item_tw .social__icon {
  background-color: #1da1f2;
}

.social_color .social__item_youtube .social__icon {
  background-color: #cc181e;
}

.social_color .social__item_fb .social__icon {
  background-color: #3b5998;
}

.social_color .social__item_vk:hover .social__text {
  color: #507299;
}

.social_color .social__item_tw:hover .social__text {
  color: #1da1f2;
}

.social_color .social__item_youtube:hover .social__text {
  color: #cc181e;
}

.social_color .social__item_fb:hover .social__text {
  color: #3b5998;
}

.slick-prev,
.slick-next {
  width: 57px;
  height: 57px;
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 50%;
}

.slick-prev .icon,
.slick-next .icon {
  margin-top: 2px;
  font-size: 14px;
  fill: #666;
}

.slick-prev:hover,
.slick-next:hover {
  border: 1px solid #3b83eb;
}

.slick-prev:hover .icon,
.slick-next:hover .icon {
  fill: #3b83eb;
}

.slick-next .icon {
  transform: rotate(180deg);
}

.rating__text {
  margin-bottom: 10px;
}

.rating__scale {
  left: -3px;
}

.rating__result {
  display: flex;
  align-items: center;
  font-family: 'MuseoSansCyrl500', serif;
  color: #ffc400;
}

.rating__value {
  font-size: 18px;
  line-height: 1;
}

.rating__max {
  margin-top: 4px;
  font-size: 12px;
  line-height: 1;
}

.rating_result {
  display: flex;
}

.rating_result .rating__scale {
  margin-right: 12px;
}

.rateit {
  cursor: pointer;
}

.rateit[data-rateit-readonly="true"] {
  cursor: default;
}

.rateit .rateit-range,
.rateit .rateit-hover,
.rateit .rateit-selected {
  background-image: url(/img/stars.png);
  background-repeat: repeat-x;
  background-size: 24px auto;
}

.rateit .rateit-range {
  display: block;
  width: 120px;
  height: 17px;
  background-position: 0 -17px;
}

.rateit .rateit-hover {
  height: 17px;
  background-position: 0 0;
}

.rateit .rateit-selected {
  background-position: 0 0;
}

.rateit button.rateit-reset {
  display: none !important;
}

.rateit:hover:not([data-rateit-readonly="true"]) .rateit-range {
  background-position: 0 -51px;
}

.rateit:hover:not([data-rateit-readonly="true"]) .rateit-selected,
.rateit:hover:not([data-rateit-readonly="true"]) .rateit-hover {
  background-position: 0 -34px;
}

.checkbox,
.radio {
  display: inline-block;
  user-select: none;
  cursor: pointer;
}

.checkbox__input,
.radio__input {
  display: none;
}

.checkbox__in,
.radio__in {
  position: relative;
  padding-left: 25px;
  font-size: 0;
}

.checkbox__tick,
.radio__tick {
  position: absolute;
  top: 1px;
  left: 0;
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #dadada;
  background-color: white;
  transition: border-color .2s;
}

.checkbox__text,
.radio__text {
  font-size: 15px;
  transition: color .2s;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick,
.radio__input:checked + .checkbox__in .checkbox__tick, .checkbox__input:checked +
.radio__in .checkbox__tick,
.radio__input:checked +
.radio__in .checkbox__tick, .checkbox__input:checked + .checkbox__in
.radio__tick,
.radio__input:checked + .checkbox__in
.radio__tick, .checkbox__input:checked +
.radio__in
.radio__tick,
.radio__input:checked +
.radio__in
.radio__tick {
  border: 1px solid #3b83eb;
}

.checkbox__input:checked + .checkbox__in .checkbox__text,
.radio__input:checked + .checkbox__in .checkbox__text, .checkbox__input:checked +
.radio__in .checkbox__text,
.radio__input:checked +
.radio__in .checkbox__text, .checkbox__input:checked + .checkbox__in
.radio__text,
.radio__input:checked + .checkbox__in
.radio__text, .checkbox__input:checked +
.radio__in
.radio__text,
.radio__input:checked +
.radio__in
.radio__text {
  color: #121212;
}

.checkbox:hover .checkbox__tick,
.radio:hover .checkbox__tick, .checkbox:hover
.radio__tick,
.radio:hover
.radio__tick {
  border: 1px solid #3b83eb;
}

.checkbox:hover .checkbox__text,
.radio:hover .checkbox__text, .checkbox:hover
.radio__text,
.radio:hover
.radio__text {
  color: #121212;
}

.checkbox__tick {
  border-radius: 3px;
}

.checkbox__tick .icon-check {
  display: none;
  font-size: 6px;
  fill: white;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick {
  background-color: #3b83eb;
}

.checkbox__input:checked + .checkbox__in .checkbox__tick .icon-check {
  display: block;
}

.checkbox__ruble {
  font-size: 16px;
}

.checkbox_prices {
  display: block;
}

.checkbox_prices .checkbox__tick {
  top: 50%;
  margin-top: -8px;
}

@media only screen and (max-width: 1169px) {
  .checkbox_prices .checkbox__name {
    line-height: 1.6;
  }
}

@media only screen and (max-width: 767px) {
  .checkbox_prices .checkbox__name {
    font-size: 13px;
  }
}

.checkbox_prices .checkbox__text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox_prices .checkbox__in {
  padding: 20px 0 20px 30px;
  border-bottom: 1px solid #dadada;
}

@media only screen and (max-width: 767px) {
  .checkbox_prices .checkbox__in {
    padding: 11px 0 13px 30px;
  }
}

.checkbox_prices:last-child .checkbox__in {
  border: 1px solid transparent;
}

.checkbox_prices .checkbox__number {
  flex: 0 0 100px;
  margin-left: auto;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .checkbox_prices .checkbox__number {
    flex: 0 0 55px;
  }
}

.checkbox_prices .checkbox__code {
  flex: 0 0 70px;
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .checkbox_prices .checkbox__code {
    display: none;
  }
}

.checkbox_prices .checkbox__input:checked + .checkbox__in {
  border-bottom: 1px solid #3b83eb;
}

.checkbox_head {
  cursor: auto;
}

.checkbox_head .checkbox__in {
  padding: 36px 0 22px 30px;
  font-weight: 700;
}

@media only screen and (max-width: 767px) {
  .checkbox_head .checkbox__in {
    padding: 42px 0 14px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .checkbox__show {
    display: none;
  }
}

.checkbox__hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .checkbox__hide {
    display: block;
  }
}

.radio__tick {
  border-radius: 50%;
}

.radio__tick:before {
  display: none;
  content: "";
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #3b83eb;
  transition: .2s;
}

.radio__input:checked + .radio__in .radio__tick:before {
  display: block;
}

.field {
  position: relative;
}

.field__label {
  position: absolute;
  top: 10px;
  left: 0;
  pointer-events: none;
  font-size: 15px;
  color: #444;
  transition: transform .2s, font-size .2s;
  z-index: 5;
}

.field__input, .field__textarea {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #d3d3d3;
  background: none;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #444;
  transition: border-color .2s;
}

.field__input:hover, .field__textarea:hover {
  border-bottom: 1px solid #3b83eb;
}

.field__input.error, .field__textarea.error {
  border-color: #ff4957;
}

.field__input {
  padding: 0 10px;
}

.field__textarea {
  padding: 8px 10px;
}

.field__wrap {
  position: relative;
}

.field__title {
  margin-bottom: 5px;
  color: #444;
}

.field__btn {
  display: flex;
  position: absolute;
  width: 40px;
  top: 0;
  right: -12px;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.field__btn .icon {
  font-size: 14px;
  fill: #121212;
}

.field label.error {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 12px;
  color: #ff4957;
}

.field.active .field__input {
  border-bottom: 1px solid #3b83eb;
}

.field.active .field__label {
  transform: translateY(-18px);
  font-size: 12px;
  color: #777;
}

.field_white .field__label,
.field_white .field__input {
  color: #f8f8f8;
}

.field_white.active .field__label {
  color: #f8f8f8;
}

.field_white .field__input {
  border-bottom: 1px solid #f8f8f8;
}

.field_white .field__input:hover {
  border-bottom: 1px solid #f8f8f8;
}

.field_white.active .field__input {
  border-bottom: 1px solid #f8f8f8;
}

.footer__top {
  padding: 30px 0 32px;
  background: #E6E8EA;
}

.footer__inner {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .footer__inner {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .footer__schedule {
    margin-bottom: 20px;
    padding-left: 20px;
  }
}

.footer__email, .footer__address {
  padding: 0 5px;
}

@media only screen and (max-width: 767px) {
  .footer__email, .footer__address {
    margin-bottom: 20px;
    padding: 0 0 0 20px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__email {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .footer__phone .phone__btn {
    min-width: 100%;
  }
}

.footer__container {
  padding: 33px 0 50px;
  background: white;
}

@media only screen and (max-width: 1169px) {
  .footer__container {
    padding: 45px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__container {
    padding: 45px 0;
  }
}

.footer__row {
  display: flex;
}

.footer__col:nth-child(1) {
  flex: 0 0 19.6%;
  padding-top: 15px;
  border-right: 1px solid #e6e8ea;
}

@media only screen and (max-width: 1169px) {
  .footer__col:nth-child(1) {
    flex: 0 0 22.6%;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:nth-child(1) {
    flex: 0 0 26.6%;
  }
}

@media only screen and (max-width: 767px) {
  .footer__col:nth-child(1) {
    flex: 0 0 100%;
    padding: 0;
    border: none;
    text-align: center;
  }
}

.footer__col:nth-child(2) {
  flex: 0 0 29%;
  padding: 32px 15px 0 70px;
}

@media only screen and (max-width: 1169px) {
  .footer__col:nth-child(2) {
    flex: 0 0 25%;
    padding: 32px 30px 0 30px;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:nth-child(2) {
    flex: 0 0 31%;
    padding: 32px 10px 0 60px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__col:nth-child(2) {
    display: none;
  }
}

.footer__col:nth-child(3) {
  flex: 0 0 51.4%;
  padding: 32px 0 0 30px;
}

@media only screen and (max-width: 1169px) {
  .footer__col:nth-child(3) {
    flex: 0 0 52.4%;
  }
}

@media only screen and (max-width: 1023px) {
  .footer__col:nth-child(3) {
    flex: 0 0 42.4%;
  }
}

@media only screen and (max-width: 767px) {
  .footer__col:nth-child(3) {
    display: none;
  }
}

.footer__logo {
  margin-bottom: 46px;
}

@media only screen and (max-width: 767px) {
  .footer__logo {
    margin-bottom: 34px;
  }
}

.footer__menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .footer__menu {
    align-items: center;
  }
}

.footer__menu .footer__link {
  margin-bottom: 19px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 14px;
  line-height: 1.78571;
  text-transform: uppercase;
  color: #121212;
  transition: color .2s;
}

.footer__menu .footer__link:last-child {
  margin-bottom: 0;
}

.footer__menu .footer__link:hover {
  color: #3b83eb;
}

.footer__title {
  margin-bottom: 18px;
  font-family: 'MuseoSansCyrl700', serif;
  color: #121212;
  font-size: 14px;
  line-height: 1.78571;
  text-transform: uppercase;
}

.footer__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer__list_cols {
  display: block;
  column-count: 2;
  column-gap: 30px;
}

@media only screen and (max-width: 1023px) {
  .footer__list_cols {
    column-count: 1;
    column-gap: 0;
  }
}

.footer__list_cols .footer__link {
  display: block;
}

@media only screen and (max-width: 1023px) {
  .footer__list_cols .footer__link:nth-child(n+9):not(:last-child) {
    display: none;
  }
}

.footer__list .footer__link {
  margin-bottom: 12px;
  transition: color .2s;
  line-height: 1.25;
}

.footer__list .footer__link:last-child {
  margin-bottom: 0;
}

.footer__list .footer__link:hover {
  color: #3b83eb;
}

.footer__link.link {
  display: none;
  margin-top: 10px;
  display: inline-block;
}

.footer__bottom {
  background: #E6E8EA;
}

.footer__bottom .footer__inner {
  position: relative;
  padding: 15px 0;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .footer__bottom .footer__inner {
    padding: 30px 0;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .footer__social {
    margin-bottom: 10px;
  }
}

.footer__copyright-text {
  margin-bottom: 5px;
  font-size: 12px;
  color: #666;
}

@media only screen and (max-width: 767px) {
  .footer__copyright-text {
    text-align: center;
  }
}

.footer__copyright-text:last-child {
  margin-bottom: 0;
}

.title {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 15px;
  text-align: center;
  font-family: 'MuseoSansCyrl300', serif;
  font-size: 30px;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .title {
    margin-bottom: 33px;
    font-size: 24px;
  }
}

.title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 104px;
  height: 6px;
  margin-left: -52px;
  background: url("/img/line-blue.png") repeat-x 0 0/auto 100%;
}

.title_left {
  text-align: left;
}

.title_left:before {
  left: 0;
  margin-left: 0;
}

.title_red:before {
  background: url("/img/line-red.png") repeat-x 0 0/auto 100%;
}

.title_white {
  color: white;
}

.title_white:before {
  background: url("/img/line-white.png") repeat-x 0 0/auto 100%;
}

.title_small {
  font-size: 18px;
  text-transform: uppercase;
}

@media only screen and (max-width: 767px) {
  .title_small {
    font-size: 18px;
  }
}

.title_mb16 {
  margin-bottom: 16px;
}

@media only screen and (max-width: 767px) {
  .title_mb16 {
    margin-bottom: 22px;
  }
}

.title_mb24 {
  margin-bottom: 24px;
}

@media only screen and (max-width: 767px) {
  .title_mb24 {
    margin-bottom: 16px;
  }
}

.title_mb30 {
  margin-bottom: 30px;
}

.title_mb60 {
  margin-bottom: 60px;
}

.info {
  max-width: 615px;
  margin: 0 auto 65px;
  text-align: center;
  font-size: 15px;
  line-height: 1.6;
  color: #666;
}

.section {
  margin-bottom: 20px;
  padding: 50px 0 60px;
  background: #F8F8F8;
}

.section__info {
  margin-bottom: 65px;
}

@media only screen and (max-width: 767px) {
  .section__info {
    margin-bottom: 35px;
  }
}

.section__info_mb75 {
  margin-bottom: 75px;
}

@media only screen and (max-width: 1169px) {
  .section__info_mb75 {
    margin-bottom: 60px;
  }
}

.section__row {
  display: flex;
  margin: 0 -15px;
}

.section__row_one {
  display: block;
}

.section__row_four, .section__row_three, .section__row_six {
  flex-wrap: wrap;
}

.section__row_four .section__col {
  flex: 0 0 calc(25% - 30px);
}

@media only screen and (max-width: 1023px) {
  .section__row_four .section__col {
    flex: 0 0 calc(33.33% - 30px);
  }
}

.section__row_three .section__col {
  flex: 0 0 calc(33.33% - 30px);
}

@media only screen and (max-width: 1023px) {
  .section__row_three .section__col {
    flex: 0 0 calc(50% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .section__row_three .section__col {
    flex: 0 0 calc(100% - 30px);
  }
}

.section__row_six .section__col {
  flex: 0 0 calc(16.66% - 30px);
  justify-content: center;
}

@media only screen and (max-width: 1023px) {
  .section__row_six .section__col {
    flex: 0 0 calc(25% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .section__row_six .section__col {
    flex: 0 0 calc(50% - 20px);
  }
}

@media only screen and (max-width: 1023px) {
  .section__row_tablet {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}

.section__row_tablet .owl-stage-outer {
  padding-bottom: 40px;
}

@media only screen and (max-width: 767px) {
  .section__row_tablet .owl-stage-outer {
    padding-bottom: 30px;
  }
}

.section__row_tablet .owl-nav {
  text-align: center;
}

.section__row_owl .owl-stage-outer {
  padding-bottom: 50px;
}

@media only screen and (max-width: 767px) {
  .section__row_owl .owl-stage-outer {
    padding-bottom: 30px;
  }
}

.section__row_owl .owl-nav {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .section__row_owl[data-items-mobile] {
    width: calc(100% + 30px);
  }
}

@media only screen and (max-width: 767px) {
  .section__row_owl[data-items-mobile] .section__col {
    flex: 0 0 calc(100% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .section__row_four[data-items-mobile] .section__col {
    display: flex;
    height: 100%;
    margin: 0 5px;
    flex-direction: column;
    flex: 0 0 calc(100% - 10px);
  }
}

.section__row_four[data-items-mobile] .owl-stage {
  display: flex;
}

.section__row_four[data-items-mobile] .owl-nav {
  margin-bottom: 30px;
}

.section__row_owl[data-items-tablet] .owl-stage {
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .section__row_owl[data-items-tablet] {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}

@media only screen and (max-width: 1023px) {
  .section__row_owl[data-items-tablet] .section__col {
    margin: 0 15px;
    flex: 0 0 calc(100% - 30px);
  }
}

.section__row_owl[data-items-desktop] {
  display: block;
  width: calc(100% + 30px);
}

.section__col {
  margin: 0 15px;
}

.section__container {
  max-width: calc(70% - 30px);
  flex: 0 0 calc(70% - 30px);
  min-height: 1px;
  margin: 0 15px;
}

@media only screen and (max-width: 1023px) {
  .section__container {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .section__container {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}

.section__container_w58 {
  max-width: calc(58% - 30px);
  flex: 0 0 calc(58% - 30px);
}

@media only screen and (max-width: 1169px) {
  .section__container_w58 {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(70% - 30px);
  }
}

@media only screen and (max-width: 1023px) {
  .section__container_w58 {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}

.section__container_w74 {
  max-width: calc(74.3% - 30px);
  flex: 0 0 calc(74.3% - 30px);
}

@media only screen and (max-width: 1169px) {
  .section__container_w74 {
    max-width: calc(70% - 30px);
    flex: 0 0 calc(70% - 30px);
  }
}

@media only screen and (max-width: 1023px) {
  .section__container_w74 {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}

@media only screen and (max-width: 767px) {
  .section__container_w74 {
    max-width: calc(100% - 30px);
    flex: 0 0 calc(100% - 30px);
  }
}

.section__aside {
  max-width: calc(30% - 30px);
  flex: 0 0 calc(30% - 30px);
  margin: 0 15px;
}

@media only screen and (max-width: 1023px) {
  .section__aside {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 280px;
    margin: 0;
    transform: translateX(280px);
    transition: transform .4s;
    z-index: 99;
  }
}

@media only screen and (max-width: 1023px) {
  .section__scroll {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
    overflow: auto;
    z-index: 4;
  }
}

.section__toggle {
  display: none;
  position: absolute;
  top: 250px;
  left: -40px;
  width: 40px;
  height: 50px;
  border-radius: 5px 0 0 5px;
  background: #fff;
  box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .section__toggle {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .section__toggle {
    top: 50%;
  }
}

@media only screen and (max-width: 1023px) {
  .section__toggle.active .icon {
    transform: rotate(180deg);
  }
}

.section__toggle .icon {
  display: inline-block;
  font-size: 13px;
  fill: #777;
}

@media only screen and (max-width: 1023px) {
  .section__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }
}

.section__foot {
  padding-top: 20px;
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .section__foot {
    padding-top: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .section__foot {
    padding-top: 0;
  }
}

.section__foot .section__btn {
  min-width: 230px;
}

@media only screen and (max-width: 767px) {
  .section__foot .section__btn {
    min-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .section__owl {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}

.section__owl .owl-stage-outer {
  margin-bottom: 15px;
  padding-bottom: 5px;
}

.section__owl .owl-nav {
  text-align: center;
}

.section__article {
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
}

.section_bg {
  background: #ECEFF1;
}

.section_p0 {
  padding: 0;
}

.section_pb30 {
  padding: 60px 0 30px;
}

@media only screen and (max-width: 767px) {
  .section_pb30 {
    padding: 50px 0 30px;
  }
}

.section_pb40 {
  padding: 50px 0 40px;
}

.section_pb55 {
  padding: 50px 0 55px;
}

@media only screen and (max-width: 767px) {
  .section_pb55 {
    padding: 50px 0 40px;
  }
}

.section_p60 {
  padding: 60px 0;
}

.section_def {
  margin-bottom: 40px;
  padding: 0;
  background: none;
}

@media only screen and (max-width: 1023px) {
  .section.visible .section__aside {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 1023px) {
  .section.visible .section__overlay {
    display: block;
    z-index: 30;
  }
}

.action {
  display: block;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  transition: box-shadow .2s;
}

.action_col {
  text-align: center;
}

.action_row {
  display: flex;
  margin-bottom: 23px;
}

@media only screen and (max-width: 767px) {
  .action_row {
    flex-direction: column;
    margin: 0 15px;
  }
}

.action_row:last-child {
  margin-bottom: 0;
}

.action_col .action__pic {
  border-radius: 5px 5px 0 0;
}

.action_row .action__preview {
  flex: 0 0 42.5%;
}

@media only screen and (max-width: 1169px) {
  .action_row .action__preview {
    flex: 0 0 32%;
  }
}

@media only screen and (max-width: 767px) {
  .action_row .action__preview {
    flex: 0 0 auto;
  }
}

.action_row .action__pic {
  border-radius: 5px 0 0 5px;
}

@media only screen and (max-width: 1169px) {
  .action_row .action__pic {
    border-radius: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .action_row .action__pic {
    border-radius: 5px 5px 0 0;
  }
}

.action_col .action__info {
  padding: 28px 25px 55px;
}

@media only screen and (max-width: 1169px) {
  .action_col .action__info {
    padding: 28px 25px 38px;
  }
}

@media only screen and (max-width: 767px) {
  .action_col .action__info {
    padding: 28px 15px 32px;
  }
}

.action_row .action__info {
  padding: 38px 40px 15px;
  text-align: left;
}

@media only screen and (max-width: 1169px) {
  .action_row .action__info {
    padding: 30px 38px 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .action_row .action__info {
    padding: 34px 38px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .action_row .action__info {
    padding: 26px 20px 30px 20px;
  }
}

@media only screen and (max-width: 1169px) {
  .action_row .action__btn {
    min-width: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .action_row .action__btn {
    min-width: 100%;
    margin: 0 0 10px 0;
  }
  .action_row .action__btn:last-child {
    margin: 0;
  }
}

.action_row .action__icon {
  top: 25px;
  left: 0;
}

@media only screen and (max-width: 767px) {
  .action_row .action__icon {
    top: -20px;
    left: 40px;
  }
}

.action_col .action__name {
  max-width: 270px;
  height: 46px;
  margin: 0 auto 15px;
  overflow: hidden;
}

.action_row .action__name {
  margin-bottom: 9px;
}

.action_col .action__text {
  height: 66px;
  margin-bottom: 48px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .action_col .action__text {
    margin-bottom: 32px;
  }
}

.action_row .action__date {
  margin-bottom: 17px;
}

.action_row .action__text {
  margin-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .action_row .action__text {
    height: 66px;
    margin-bottom: 20px;
    overflow: hidden;
  }
}

.action_col:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.action_col:hover .action__btn {
  background-color: #ff5e6a;
}

.action_simple {
  padding: 0 30px;
  background: none;
  border-radius: 0;
  box-shadow: none;
}

@media only screen and (max-width: 1169px) {
  .action_simple {
    padding: 0 20px;
  }
}

.action_simple .action__name {
  height: auto;
}

.action_simple .action__info {
  padding: 35px 0 0;
}

@media only screen and (max-width: 1169px) {
  .action_simple .action__info {
    padding: 35px 0 0;
  }
}

.action_simple .action__date {
  margin-bottom: 0;
}

.action_simple .action__pic {
  border-radius: 0;
}

.action_simple:hover {
  box-shadow: none;
}

.action_full {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .action_full {
    display: block;
  }
}

.action_full .action__preview {
  flex: 0 0 50%;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .action_full .action__preview {
    flex: 0 0 39%;
  }
}

@media only screen and (max-width: 767px) {
  .action_full .action__pic {
    border-radius: 5px 5px 0 0;
  }
}

.action_full .action__info {
  padding: 74px 40px 40px 59px;
  flex: 0 0 50%;
}

@media only screen and (max-width: 1169px) {
  .action_full .action__info {
    padding: 5px 15px 30px 38px;
  }
}

@media only screen and (max-width: 1023px) {
  .action_full .action__info {
    flex: 0 0 61%;
    padding: 31px 15px 30px 33px;
  }
}

@media only screen and (max-width: 767px) {
  .action_full .action__info {
    padding: 20px 10px 35px;
  }
}

.action_full .action__bonus {
  display: flex;
  margin-bottom: 18px;
  align-items: center;
}

.action_full .action__icon {
  position: relative;
  top: auto;
  left: auto;
  margin: 0 15px 0 0;
}

.action_full .action__title {
  display: block;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1023px) {
  .action_full .action__title {
    line-height: 1.1;
  }
}

@media only screen and (max-width: 767px) {
  .action_full .action__title {
    line-height: 1.25;
  }
}

.action_full .action__date {
  margin-bottom: 43px;
}

@media only screen and (max-width: 1169px) {
  .action_full .action__date {
    margin-bottom: 24px;
  }
}

.action_full .action__text {
  margin-bottom: 34px;
}

@media only screen and (max-width: 1169px) {
  .action_full .action__text {
    margin-bottom: 22px;
  }
}

.action_full .action__text_mb48 {
  margin-bottom: 48px;
}

@media only screen and (max-width: 1169px) {
  .action_full .action__text_mb48 {
    margin-bottom: 34px;
  }
}

.action_full .action__name {
  margin-bottom: 10px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .action_full .action__name {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  .action_full .action__btn {
    min-width: 100%;
  }
}

.action__preview {
  position: relative;
}

.action__pic {
  display: block;
  width: 100%;
}

.action__info {
  position: relative;
}

.action__bonus {
  margin-bottom: 15px;
}

.action__bonus_gift .action__icon {
  background: #FCC200;
}

.action__bonus_gift .action__label {
  color: #FCC200;
}

.action__icon {
  display: flex;
  position: absolute;
  top: -20px;
  left: 50%;
  margin-left: -20px;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #ff8a65;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
}

.action__icon .icon {
  fill: #ffede7;
}

.action__icon .icon-sale {
  font-size: 16px;
}

.action__icon .icon-gift {
  font-size: 21px;
}

.action__label {
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
  color: #ff8a65;
}

.action__name {
  display: block;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
}

.action__date {
  margin-bottom: 15px;
  font-weight: 700;
  color: #777;
  font-size: 12px;
  text-transform: uppercase;
}

.action__text {
  line-height: 1.5;
}

.action__btns .action__btn {
  margin-right: 20px;
}

.action__btns .action__btn:last-child {
  margin-right: 0;
}

.action_nav {
  padding: 0 10px;
  box-shadow: none;
  transition: transform .2s;
}

.action_nav:hover {
  box-shadow: none;
}

.action_nav .action__pic {
  border-radius: 5px;
}

.action_nav:hover .action__info {
  background: #3b83eb;
  transform: translateY(-42px);
}

@media only screen and (min-width: 1023px) and (max-height: 730px) {
  .action_nav:hover .action__info {
    transform: translateY(-60px);
  }
}

.action_nav:hover .action__more {
  visibility: visible;
}

.action_nav:hover .action__name {
  color: white;
}

.action_nav .action__info {
  padding: 28px 10px 15px;
  background: white;
  border-radius: 0 0 5px 5px;
  transition: background .2s, transform .2s;
}

.action_nav .action__name {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  color: #444;
  transition: color .2s;
}

.action_nav .action__more {
  min-width: 190px;
  visibility: hidden;
}

.owl-carousel .owl-prev,
.owl-carousel .owl-next {
  width: 57px;
  height: 57px;
  margin: 0 5px;
  background-color: white;
  border: 1px solid #dadada;
  border-radius: 50%;
  transition: border-color .2s, opacity .2s;
}

.owl-carousel .owl-prev .icon,
.owl-carousel .owl-next .icon {
  margin-top: 2px;
  font-size: 14px;
  fill: #666;
  transition: fill .2s;
}

.owl-carousel .owl-prev:hover,
.owl-carousel .owl-next:hover {
  border-color: #3b83eb;
}

.owl-carousel .owl-prev:hover .icon,
.owl-carousel .owl-next:hover .icon {
  fill: #3b83eb;
}

.owl-carousel .owl-prev.disabled,
.owl-carousel .owl-next.disabled {
  border-color: #dadada;
  pointer-events: none;
  opacity: .5;
}

.owl-carousel .owl-prev.disabled .icon,
.owl-carousel .owl-next.disabled .icon {
  fill: #666;
}

.owl-carousel_nav {
  height: 280px;
}

.owl-carousel_nav .owl-prev {
  display: none;
}

.owl-carousel_nav .owl-next {
  position: absolute;
  right: -26px;
  top: 63px;
  width: 40px;
  height: 40px;
  margin: 0;
  border: none;
  background: none;
}

.owl-carousel_nav .owl-next .icon {
  fill: #7d8b99;
}

.owl-carousel_nav .owl-next:hover {
  border: none;
}

.owl-carousel_portfolio .owl-item {
  transition: opacity .2s;
}

@media only screen and (min-width: 1200px) {
  .owl-carousel_portfolio .owl-item {
    opacity: .2;
  }
}

.owl-carousel_portfolio .owl-item.center {
  opacity: 1;
}

.owl-carousel_portfolio .owl-nav {
  padding: 50px 0;
  background: white;
  text-align: center;
}

@media only screen and (max-width: 1169px) {
  .owl-carousel_portfolio .owl-nav {
    padding: 40px 0 50px;
  }
}

@media only screen and (max-width: 767px) {
  .owl-carousel_portfolio .owl-nav {
    padding: 20px 0 50px;
  }
}

.owl-carousel_portfolio .center {
  max-width: 1170px;
  padding: 0;
}

.talk__item {
  margin-bottom: 20px;
  padding: 28px 15px 30px 35px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  background: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 767px) {
  .talk__item {
    padding: 16px 10px 30px 20px;
  }
}

.talk__item:last-child {
  margin-bottom: 0;
}

.talk__meta {
  margin-bottom: 14px;
}

.talk__question {
  margin-bottom: 4px;
  font-family: 'MuseoSansCyrl300', serif;
  font-size: 18px;
  line-height: 1.55;
}

@media only screen and (max-width: 767px) {
  .talk__question {
    font-size: 13px;
    line-height: 1.7;
  }
}

.talk__link {
  display: inline-flex;
  align-items: center;
}

.talk__link.active .talk__link-icon .icon {
  transform: rotate(180deg);
}

.talk__link:hover .talk__link-text {
  text-decoration: none;
}

.talk__link-icon {
  margin-right: 10px;
  font-size: 0;
}

.talk__link-icon .icon {
  font-size: 5px;
  fill: #3b83eb;
}

.talk__link-text {
  text-decoration: underline;
  color: #3b83eb;
}

.talk__answer {
  height: 0;
  overflow: hidden;
}

.talk__in {
  display: flex;
  padding-top: 27px;
}

@media only screen and (max-width: 767px) {
  .talk__in {
    display: block;
  }
}

.talk__wrap {
  padding-left: 20px;
}

@media only screen and (max-width: 767px) {
  .talk__wrap {
    padding-left: 0;
  }
}

.talk__user {
  flex: 0 0 150px;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .talk__user {
    display: flex;
    flex: 0 0 100%;
    margin-bottom: 15px;
  }
}

.talk__preview {
  width: 102px;
  margin: 0 auto 10px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .talk__preview {
    flex: 0 0 50px;
    margin: 0 15px 0 0;
    padding-top: 5px;
  }
}

@media only screen and (max-width: 767px) {
  .talk__box {
    text-align: left;
  }
}

.talk__pic {
  max-width: 100%;
  border-radius: 50%;
}

.talk__name {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 15px;
  line-height: 1.46667;
}

.talk__post {
  height: 15px;
  overflow: hidden;
  font-size: 12px;
  color: #777;
}

.talk__text {
  font-size: 15px;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .talk__text {
    font-size: 13px;
    line-height: 1.7;
  }
}

.talk__head {
  display: flex;
  border-bottom: 1px solid #e6e8ea;
  padding: 18px 34px 21px;
  align-items: flex-end;
}

@media only screen and (max-width: 767px) {
  .talk__head {
    padding: 19px 10px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.talk__head .talk__meta {
  padding-bottom: 3px;
}

@media only screen and (max-width: 767px) {
  .talk__head .talk__meta {
    padding-bottom: 0;
  }
}

.talk__title {
  margin-right: 25px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  line-height: 1;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .talk__title {
    margin-bottom: 10px;
    font-size: 18px;
  }
}

.talk__body {
  display: flex;
  padding: 10px 34px;
  border-bottom: 1px solid #e6e8ea;
}

@media only screen and (max-width: 767px) {
  .talk__body {
    display: block;
    padding: 15px 10px;
  }
}

.talk__info {
  display: flex;
  margin-bottom: 19px;
}

@media only screen and (max-width: 767px) {
  .talk__info {
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.talk__info .talk__name {
  margin: 0 15px 0 0;
}

.talk__col {
  padding-right: 10px;
}

@media only screen and (max-width: 767px) {
  .talk__col {
    display: flex;
    padding-right: 0;
    flex-direction: row;
  }
}

.talk__col:first-child {
  flex: 0 0 40%;
}

@media only screen and (max-width: 1169px) {
  .talk__col:first-child {
    flex: 0 0 35%;
  }
}

@media only screen and (max-width: 767px) {
  .talk__col:first-child {
    flex: 0 0 100%;
    margin-bottom: 17px;
  }
}

.talk__col:nth-child(2) {
  flex: 0 0 33%;
}

@media only screen and (max-width: 1169px) {
  .talk__col:nth-child(2) {
    flex: 0 0 41%;
  }
}

@media only screen and (max-width: 767px) {
  .talk__col:nth-child(2) {
    flex: 0 0 100%;
    margin-bottom: 19px;
  }
}

.talk__col:last-child {
  flex: 0 0 27%;
}

@media only screen and (max-width: 1169px) {
  .talk__col:last-child {
    flex: 0 0 24%;
    align-self: center;
  }
}

@media only screen and (max-width: 767px) {
  .talk__col:last-child {
    flex: 0 0 100%;
    align-self: flex-start;
  }
}

.talk__services {
  margin-bottom: 5px;
  font-size: 12px;
  color: #777;
}

@media only screen and (max-width: 767px) {
  .talk__services {
    margin: 0 20px 0 0;
  }
}

.talk__foot {
  padding: 17px 15px 35px 34px;
}

@media only screen and (max-width: 767px) {
  .talk__foot {
    padding: 25px 10px 30px;
  }
}

.talk_review .talk__item {
  padding: 0;
}

.talk_review .talk__meta {
  margin: 0;
}

.talk_review .talk__name {
  margin-bottom: 0;
  line-height: 1;
  color: #121212;
}

@media only screen and (max-width: 1169px) {
  .talk_review .talk__name {
    line-height: 1.2;
  }
}

@media only screen and (max-width: 767px) {
  .talk_review .talk__wrap .talk__name {
    max-width: 220px;
    min-height: 40px;
    margin: 0 0 16px 0;
    padding-left: 60px;
    font-size: 13px;
    line-height: 1.6;
  }
}

.talk_review .talk__in {
  padding-top: 32px;
}

.talk_review .talk__wrap {
  padding-left: 28px;
}

@media only screen and (max-width: 767px) {
  .talk_review .talk__wrap {
    margin-top: -45px;
    padding-left: 0;
  }
}

.talk_review .talk__answer {
  height: auto;
  overflow: inherit;
}

.talk_review .talk__question {
  height: 81px;
  overflow: hidden;
}

@media only screen and (max-width: 1169px) {
  .talk_review .talk__question {
    height: 113px;
  }
}

.talk_review .talk__user {
  flex: 0 0 82px;
}

@media only screen and (max-width: 767px) {
  .talk_review .talk__user {
    flex: 0 0 50px;
    width: 50px;
    margin: 0 10px 0 0;
  }
}

.talk_review .talk__preview {
  width: 100%;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .talk_review .talk__preview {
    padding: 0;
  }
}

.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  text-overflow: ellipsis;
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.filter {
  position: relative;
}

@media only screen and (max-width: 767px) {
  .filter {
    height: 60px;
    margin-bottom: 10px;
  }
}

.filter__toggle {
  display: none;
  width: 100%;
  padding: 10px 50px;
}

@media only screen and (max-width: 767px) {
  .filter__toggle {
    display: block;
  }
}

.filter__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 5px 12px;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  text-align: center;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
  color: #444;
}

.filter__title .icon {
  margin-left: 10px;
  fill: #444;
}

.filter__title .icon-arrow-down {
  font-size: 6px;
}

.filter__title .icon-close {
  display: none;
  font-size: 10px;
}

@media only screen and (max-width: 767px) {
  .filter__overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }
}

.filter__body {
  display: flex;
  margin-bottom: 20px;
  padding-left: 20px;
  align-items: flex-end;
}

@media only screen and (max-width: 1169px) {
  .filter__body {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .filter__body {
    display: none;
    padding: 10px;
    flex-direction: column;
    border-radius: 0 0 5px 5px;
  }
}

.filter__select {
  flex: 0 0 calc(50% - 130px);
  margin: 0 15px;
  width: 100%;
}

@media only screen and (max-width: 1169px) {
  .filter__select {
    margin: 0 10px;
  }
}

@media only screen and (max-width: 767px) {
  .filter__select {
    margin: 0;
  }
}

@media only screen and (max-width: 1169px) {
  .filter__select:first-child {
    flex: 0 0 calc(45% - 110px);
  }
}

@media only screen and (max-width: 767px) {
  .filter__select:first-child {
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 1169px) {
  .filter__select:nth-child(2) {
    flex: 0 0 calc(55% - 110px);
  }
}

@media only screen and (max-width: 767px) {
  .filter__select:nth-child(2) {
    flex: 0 0 100%;
  }
}

@media only screen and (max-width: 767px) {
  .filter__btns {
    width: 100%;
    margin-top: 20px;
  }
}

.filter__show, .filter__close {
  margin-left: 15px;
}

.filter__show {
  min-width: 130px;
}

@media only screen and (max-width: 1169px) {
  .filter__show {
    min-width: 110px;
  }
}

@media only screen and (max-width: 767px) {
  .filter__show {
    min-width: calc(100% - 55px);
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .filter.active .filter__head {
    border-radius: 5px 5px 0 0;
    background: #f8f8f8;
  }
}

@media only screen and (max-width: 767px) {
  .filter.active .filter__title {
    background-color: #eceff1;
    box-shadow: none;
  }
  .filter.active .filter__title .icon-arrow-down {
    display: none;
  }
  .filter.active .filter__title .icon-close {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .filter.active .filter__container {
    position: relative;
    border-radius: 5px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 30;
  }
}

@media only screen and (max-width: 767px) {
  .filter.active .filter__body {
    display: block;
  }
}

.filter.active .filter__overlay {
  display: block;
  z-index: 20;
}

.meta {
  display: flex;
  align-items: center;
}

.meta__item {
  display: flex;
  margin-right: 20px;
  align-items: center;
}

.meta__item:last-child {
  margin-right: 0;
}

.meta__wrap, .meta__inner {
  display: flex;
  align-items: center;
}

.meta__icon {
  margin-right: 7px;
  font-size: 0;
}

.meta__icon .icon {
  font-size: 11px;
  fill: #777;
}

.meta__icon .icon-eye {
  font-size: 9px;
}

.meta__text {
  display: block;
  font-size: 12px;
  color: #777;
}

.meta__preview {
  margin-right: 15px;
}

.meta__ava {
  width: 30px;
}

.meta__pic {
  max-width: 100%;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .meta_row {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .meta_row .meta__wrap {
    margin-top: 10px;
  }
}

.banner {
  display: flex;
  padding: 53px 45px 50px;
  border-radius: 5px;
  background-color: #3b83eb;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 767px) {
  .banner {
    flex-direction: column;
    padding: 20px 20px 30px;
  }
}

.banner__preview {
  flex: 0 0 124px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .banner__preview {
    margin: 0 auto 18px;
  }
}

.banner__photo {
  max-width: 100%;
  border-radius: 50%;
}

.banner__info {
  padding-left: 47px;
}

@media only screen and (max-width: 767px) {
  .banner__info {
    padding-left: 0;
  }
}

.banner__name {
  margin-bottom: 8px;
  font-family: 'MuseoSansCyrl300', serif;
  font-size: 25px;
  line-height: 1;
  color: white;
}

@media only screen and (max-width: 767px) {
  .banner__name {
    text-align: center;
    font-size: 18px;
  }
}

.banner__post {
  margin-bottom: 30px;
  font-size: 12px;
  color: white;
  opacity: .5;
}

@media only screen and (max-width: 767px) {
  .banner__post {
    margin-bottom: 20px;
    text-align: center;
  }
}

.banner__btn {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .banner__btn {
    min-width: 100%;
    margin: 0 0 10px 0;
  }
}

.banner__btn:last-child {
  margin-right: 0;
}

@media only screen and (max-width: 767px) {
  .banner__btn:last-child {
    margin: 0;
  }
}

.banner .btn_white {
  min-width: 190px;
}

@media only screen and (max-width: 767px) {
  .banner .btn_white {
    min-width: 100%;
  }
}

.widget {
  padding: 35px 30px 40px;
  background: #f8f8f8;
}

@media only screen and (max-width: 1169px) {
  .widget {
    padding: 35px 20px 40px;
  }
}

@media only screen and (max-width: 1023px) {
  .widget {
    position: relative;
    background: #FFF;
  }
}

.widget__head {
  display: flex;
  margin-bottom: 36px;
  justify-content: space-between;
}

.widget__wrap {
  position: relative;
  z-index: 10;
}

.widget__title {
  color: #232323;
}

.widget__phone {
  margin-bottom: 25px;
}

.widget__address {
  margin-bottom: 30px;
}

.widget__head .widget__title {
  margin-bottom: 0;
}

.widget__tags {
  margin-right: -10px;
}

@media only screen and (max-width: 767px) {
  .widget__category {
    margin-left: -20px;
  }
}

.widget__owl {
  width: calc(100% + 60px);
  margin: 0 -30px;
}

@media only screen and (max-width: 1169px) {
  .widget__owl {
    width: calc(100% + 40px);
    margin: 0 -20px;
  }
}

.widget__owl .owl-nav {
  padding-top: 30px;
  text-align: center;
}

.widget_blue {
  margin: 0 30px;
  padding: 35px 30px 45px;
  border-radius: 5px;
  background: #3b83eb;
}

@media only screen and (max-width: 1169px) {
  .widget_blue {
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .widget_blue {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background: #3b83eb;
  }
}

.widget_blue .widget__title {
  color: white;
}

.widget_mb80 {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1023px) {
  .widget_mb80 {
    margin-bottom: 0;
  }
}

.box {
  padding: 35px 25px 45px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background: white;
}

@media only screen and (max-width: 767px) {
  .box {
    flex: 0 0 calc(100% - 30px);
    margin-bottom: 20px;
    padding: 23px 25px 30px;
  }
}

.box .icon-email {
  fill: #444;
}

.box .address {
  margin-bottom: 37px;
}

.box .schedule {
  margin-bottom: 47px;
}

.box__title {
  font-family: 'MuseoSansCyrl500', serif;
  margin-bottom: 21px;
  font-size: 25px;
  text-transform: none;
}

@media only screen and (max-width: 767px) {
  .box__title {
    margin-bottom: 27px;
    font-size: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .box_wide.box {
    flex: 0 0 calc(100% - 30px);
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .box_wide.box {
    margin: 0 15px;
  }
}

.doctors__item {
  display: flex;
  margin-bottom: 24px;
}

@media only screen and (max-width: 1169px) {
  .doctors__item {
    margin-bottom: 30px;
  }
}

.doctors__item:last-child {
  margin-bottom: 0;
}

.doctors__preview {
  flex: 0 0 70px;
  padding-top: 7px;
}

@media only screen and (max-width: 1169px) {
  .doctors__preview {
    flex: 0 0 60px;
  }
}

.doctors__pic {
  display: block;
  max-width: 100%;
  border-radius: 50%;
}

.doctors__info {
  padding-left: 30px;
}

@media only screen and (max-width: 1169px) {
  .doctors__info {
    padding-left: 20px;
  }
}

.doctors__name {
  margin-bottom: 12px;
  font-weight: 700;
  line-height: 1.46667;
}

.doctors__post {
  font-size: 12px;
  color: #666;
}

.worker {
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: inset 0 0 0 1px #f6f6f6, 0 2px 5px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  z-index: 2;
}

@media only screen and (max-width: 1023px) {
  .worker:nth-child(n+7) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .worker {
    display: flex;
    flex-direction: column;
  }
  .worker:nth-child(n+7) {
    display: block;
  }
}

.worker:hover {
  box-shadow: none;
  z-index: 4;
}

.worker:hover .worker__btns {
  display: flex;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid #f6f6f6;
  border-radius: 5px;
}

@media only screen and (max-width: 767px) {
  .worker:hover .worker__btns {
    border: none;
  }
}

.worker__wrap {
  position: relative;
  flex: 1 0 auto;
  z-index: 4;
}

.worker__ava {
  font-size: 0;
}

.worker__photo {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.worker__info {
  padding: 25px 20px 25px;
}

@media only screen and (max-width: 767px) {
  .worker__info {
    padding: 20px 10px 15px;
  }
}

.worker__name {
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 18px;
}

@media only screen and (max-width: 767px) {
  .worker__name {
    font-size: 13px;
    line-height: 1.4;
  }
}

.worker__post {
  font-size: 12px;
  color: #777;
}

.worker__btns {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: -135px;
  padding: 0 20px 35px;
  justify-content: flex-end;
  flex-direction: column;
  background: white;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .worker__btns {
    display: flex;
    position: relative;
    top: auto;
    bottom: auto;
    padding: 0 10px 15px;
  }
}

.worker__btns .worker__btn {
  min-width: 100%;
  margin-bottom: 17px;
}

@media only screen and (max-width: 767px) {
  .worker__btns .worker__btn {
    margin-bottom: 10px;
    padding: 8px 5px 7px;
  }
}

.worker__btns .worker__btn:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .worker__btns .worker__btn:last-child {
    margin-bottom: 0;
  }
}

.disease {
  margin: 0 15px 30px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background-color: white;
}

@media only screen and (max-width: 1023px) {
  .disease:nth-child(n+5) {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .disease:nth-child(n+3) {
    display: none;
  }
}

.disease__preview {
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .disease__preview {
    display: none;
  }
}

.disease__img {
  width: 100%;
  height: auto;
  border-radius: 5px 5px 0 0;
}

.disease__category {
  min-height: 50px;
  margin-bottom: 20px;
  overflow: hidden;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
  line-height: 1.44444;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .disease__category {
    min-height: auto;
    margin-bottom: 15px;
  }
}

.disease__info {
  padding: 25px 30px 43px;
}

@media only screen and (max-width: 1169px) {
  .disease__info {
    padding: 25px 20px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .disease__info {
    padding: 25px 20px 30px;
  }
}

.disease__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.disease__name {
  margin-bottom: 20px;
  text-decoration: underline;
}

.disease__name:last-child {
  margin-bottom: 0;
}

.disease__name:hover {
  text-decoration: none;
}

.article {
  display: block;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background-color: white;
  transition: box-shadow .2s;
}

.article__preview {
  font-size: 0;
}

.article__img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.article__wrap {
  padding: 25px 30px 40px;
}

.article__title {
  display: block;
  min-height: 50px;
  margin-bottom: 13px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
  line-height: 1.44444;
  color: #121212;
}

.article__title_mb30 {
  margin-bottom: 30px;
}

.article__text {
  margin-bottom: 23px;
  line-height: 1.6;
  transition: color .2s;
}

.article__btn {
  display: inline-block;
  min-width: 230px;
  margin: 0 auto;
}

.article__head {
  display: flex;
  margin-bottom: 10px;
}

@media only screen and (max-width: 767px) {
  .article__head {
    margin-bottom: 5px;
    flex-direction: column;
  }
}

.article_small {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 1023px) {
  .article_small {
    height: 100%;
  }
}

.article_small .article__title {
  min-height: auto;
}

@media only screen and (max-width: 1023px) {
  .article_small .article__title {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .article_small .article__title {
    margin-bottom: 10px;
    font-size: 15px;
  }
}

.article_small .article__text {
  height: 96px;
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .article_small .article__text {
    height: 80px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .article_small .article__btn {
    min-width: 100%;
  }
}

.article_small .article__wrap {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 25px 20px 40px;
}

@media only screen and (max-width: 767px) {
  .article_small .article__wrap {
    padding: 25px 10px 30px;
  }
}

.article_small .article__btns {
  display: flex;
  flex: 1 0 auto;
  align-items: flex-end;
  text-align: center;
}

.article_small:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.article_small:hover .article__title {
  color: #3b83eb;
}

.article_small:hover .article__btn {
  background-color: #ff5e6a;
}

.article_big {
  margin-bottom: 30px;
}

.article_big:last-child {
  margin-bottom: 0;
}

.article_big .article__wrap {
  padding: 25px 50px 45px;
}

@media only screen and (max-width: 1169px) {
  .article_big .article__wrap {
    padding: 18px 30px 45px;
  }
}

@media only screen and (max-width: 767px) {
  .article_big .article__wrap {
    padding: 17px 10px 30px;
  }
}

.article_big .article__meta {
  margin-right: 25px;
}

@media only screen and (max-width: 767px) {
  .article_big .article__meta {
    margin: 0 0 20px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .article_big .article__meta .meta__wrap {
    margin-top: 8px;
  }
}

.article_big .article__title {
  display: block;
  min-height: auto;
  margin-bottom: 20px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  color: #232323;
}

@media only screen and (max-width: 1169px) {
  .article_big .article__title {
    line-height: 1.3;
  }
}

@media only screen and (max-width: 767px) {
  .article_big .article__title {
    margin-bottom: 15px;
    font-size: 15px;
    line-height: 1.45;
  }
}

.article_big .article__text {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .article_big .article__text {
    margin-bottom: 25px;
    font-size: 13px;
  }
}

.article_big .article__btn {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .article_big .article__btn {
    min-width: 100%;
  }
}

.preview__pic {
  position: relative;
  font-size: 0;
}

.preview__img {
  width: 100%;
  border-radius: 5px 5px 0 0;
}

.preview__counter {
  position: absolute;
  right: 50px;
  bottom: -26px;
}

@media only screen and (max-width: 1023px) {
  .preview__counter {
    right: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .preview__counter {
    right: 32px;
    bottom: -15px;
  }
}

.preview__wrap {
  padding: 23px 50px 16px 53px;
}

@media only screen and (max-width: 1169px) {
  .preview__wrap {
    padding: 20px 25px 16px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .preview__wrap {
    padding: 20px 10px 20px;
  }
}

.preview__rating {
  margin-bottom: 3px;
}

.preview__title {
  margin-bottom: 20px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  color: #232323;
}

@media only screen and (max-width: 767px) {
  .preview__title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .preview__meta {
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .preview__meta .meta__wrap {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .preview__meta .meta__item {
    margin-right: 10px;
  }
  .preview__meta .meta__item:last-child {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .preview__meta .meta__wrap > .meta__item {
    margin: 0 0 5px 0;
  }
}

.share {
  display: flex;
  padding: 25px 50px 40px 53px;
  border-top: 1px solid #e6e8ea;
  align-items: center;
}

@media only screen and (max-width: 1169px) {
  .share {
    padding: 25px 50px 30px 53px;
  }
}

@media only screen and (max-width: 767px) {
  .share {
    flex-direction: column;
    padding: 20px 10px 30px 10px;
    align-items: flex-start;
  }
}

.share__text {
  margin-right: 30px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  line-height: 1.44;
  color: #232323;
}

@media only screen and (max-width: 767px) {
  .share__text {
    margin: 0 0 10px 0;
    font-size: 18px;
  }
}

.share__social {
  flex-direction: row;
}

.counter {
  display: flex;
  flex-direction: column;
}

.counter__item {
  position: relative;
  width: 55px;
  height: 55px;
  margin-bottom: 13px;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .counter__item {
    width: 34px;
    height: 34px;
    margin-bottom: 10px;
  }
}

.counter__item .icon {
  font-size: 16px;
  fill: white;
}

.counter__item:last-child {
  margin-bottom: 0;
}

.counter__item_like {
  background: #ff5e6a;
}

.counter__item_review {
  background: #43b9f6;
}

.counter__item_like .counter__number {
  border: 2px solid #ff5e6a;
  color: #ff5e6a;
}

.counter__item_review .counter__number {
  border: 2px solid #43b9f6;
  color: #43b9f6;
}

.counter__number {
  position: absolute;
  top: 15px;
  right: -19px;
  width: 29px;
  height: 29px;
  padding-top: 6px;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
}

@media only screen and (max-width: 767px) {
  .counter__number {
    top: 5px;
    width: 24px;
    height: 24px;
    padding-top: 4px;
  }
}

.content {
  padding: 10px 50px 5px 53px;
  *zoom: 1;
}

.content:after {
  content: " ";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1169px) {
  .content {
    padding: 10px 25px 5px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .content {
    padding: 0px 10px 5px 10px;
  }
}

.content p {
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 1.6;
  color: #444;
}

@media only screen and (max-width: 767px) {
  .content p {
    margin-bottom: 20px;
    font-size: 13px;
    line-height: 1.7;
  }
}

.content blockquote {
  max-width: 600px;
  margin: 0 auto 42px;
  padding: 26px 26px 30px 31px;
  border-left: 10px solid #3b83eb;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 0 0 1px #f6f6f6;
  background-color: white;
}

@media only screen and (max-width: 1169px) {
  .content blockquote {
    max-width: 570px;
  }
}

@media only screen and (max-width: 767px) {
  .content blockquote {
    max-width: 100%;
    margin: 0 0 34px 0;
    padding: 15px 10px 22px 17px;
  }
}

.content blockquote p {
  margin-bottom: 4px;
  font-style: italic;
  font-size: 18px;
  color: #3b83eb;
}

@media only screen and (max-width: 767px) {
  .content blockquote p {
    margin-bottom: 7px;
    font-size: 15px;
  }
}

.content blockquote footer {
  color: #232323;
}

.content * + blockquote {
  margin-top: 45px;
}

@media only screen and (max-width: 767px) {
  .content * + blockquote {
    margin-top: 35px;
  }
}

.content figure {
  text-align: center;
}

.content figure img {
  max-width: 100%;
  font-size: 0;
}

.content figure[align="left"] {
  float: left;
  margin: 0 50px 35px 0;
}

@media only screen and (max-width: 1169px) {
  .content figure[align="left"] {
    max-width: 35%;
    margin: 0 30px 35px 0;
  }
}

@media only screen and (max-width: 767px) {
  .content figure[align="left"] {
    float: none;
    max-width: 100%;
    margin: 0 0 22px 0;
    text-align: center;
  }
}

.content figure[align="right"] {
  float: right;
  margin: 0 0 35px 50px;
}

@media only screen and (max-width: 1169px) {
  .content figure[align="right"] {
    max-width: 35%;
    margin: 0 0 35px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .content figure[align="right"] {
    float: none;
    max-width: 100%;
    margin: 0 0 22px 0;
    text-align: center;
  }
}

.content * + figure,
.content * + figure[align="left"],
.content * + figure[align="right"] {
  margin-top: 7px;
}

@media only screen and (max-width: 1169px) {
  .content * + figure,
  .content * + figure[align="left"],
  .content * + figure[align="right"] {
    margin-top: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .content * + figure,
  .content * + figure[align="left"],
  .content * + figure[align="right"] {
    margin-top: 13px;
  }
}

.content ul {
  margin-bottom: 55px;
  overflow: hidden;
}

@media only screen and (max-width: 1169px) {
  .content ul {
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .content ul {
    margin-bottom: 20px;
  }
}

.content ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 27px;
}

@media only screen and (max-width: 767px) {
  .content ul li {
    font-size: 13px;
  }
}

.content ul li:before {
  content: "";
  position: absolute;
  top: 4px;
  left: 0;
  width: 13px;
  height: 9px;
  background: url("/img/check.png") no-repeat 50% 50%/100% 100%;
}

.content ul li:last-child {
  margin-bottom: 0;
}

.content * + ul {
  margin-top: 50px;
}

@media only screen and (max-width: 1169px) {
  .content * + ul {
    margin-top: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .content * + ul {
    margin-top: 15px;
  }
}

.content * + h1,
.content * + h2,
.content * + h3 {
  margin-top: 50px;
}

@media only screen and (max-width: 1169px) {
  .content * + h1,
  .content * + h2,
  .content * + h3 {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .content * + h1,
  .content * + h2,
  .content * + h3 {
    margin-top: 30px;
  }
}

.content h1,
.content h2,
.content h3 {
  margin-bottom: 60px;
}

@media only screen and (max-width: 1169px) {
  .content h1,
  .content h2,
  .content h3 {
    margin-bottom: 20px;
  }
}

.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
  line-height: 1.3;
  font-weight: 400;
  color: #121212;
}

.content h1 {
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 40px;
  line-height: 1.25;
}

.content h2 {
  font-family: 'MuseoSansCyrl300', serif;
  font-size: 30px;
  line-height: 1.33333;
}

.content h3 {
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  line-height: 1.44;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .content h3 {
    font-size: 20px;
  }
}

.content h4 {
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 18px;
  text-transform: uppercase;
}

.content h5 {
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
}

.content h6 {
  font-weight: 700;
  font-size: 15px;
  line-height: 1.6;
}

.doctor__bg {
  padding: 20px 0 17px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1023px) {
  .doctor__bg {
    margin-bottom: 45px;
    padding: 20px 0 25px;
  }
}

.doctor__breadcrumbs {
  margin-bottom: 89px;
}

@media only screen and (max-width: 1023px) {
  .doctor__breadcrumbs {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__breadcrumbs {
    margin-bottom: 40px;
  }
}

.doctor__wrap {
  padding-left: 320px;
}

@media only screen and (max-width: 1169px) {
  .doctor__wrap {
    padding-left: 295px;
  }
}

@media only screen and (max-width: 1023px) {
  .doctor__wrap {
    padding-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__wrap {
    padding-left: 105px;
  }
}

.doctor__name {
  margin-bottom: 2px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 40px;
  color: white;
}

@media only screen and (max-width: 767px) {
  .doctor__name {
    margin-bottom: 12px;
    font-size: 24px;
  }
}

.doctor__inner {
  margin-top: -100px;
}

@media only screen and (max-width: 1023px) {
  .doctor__inner {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__inner {
    margin-top: -155px;
  }
}

.doctor__inner .doctor__center {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .doctor__inner .doctor__center {
    display: block;
  }
}

.doctor__aside {
  flex: 0 0 270px;
}

@media only screen and (max-width: 1169px) {
  .doctor__aside {
    flex: 0 0 245px;
  }
}

@media only screen and (max-width: 1023px) {
  .doctor__aside {
    flex: 0 0 220px;
  }
}

.doctor__preview {
  width: 100%;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .doctor__preview {
    margin-bottom: 25px;
    text-align: left;
    border: none;
    background: none;
    box-shadow: none;
  }
}

.doctor__ava {
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .doctor__ava {
    margin-bottom: 35px;
  }
}

.doctor__pic {
  max-width: 100%;
  border-radius: 5px 5px 0 0;
}

@media only screen and (max-width: 767px) {
  .doctor__pic {
    width: 95px;
    border-radius: 5px;
  }
}

.doctor__action {
  padding: 20px 0;
}

@media only screen and (max-width: 1023px) {
  .doctor__action {
    padding: 20px 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__action {
    padding: 0;
  }
}

.doctor__btn {
  min-width: 230px;
}

@media only screen and (max-width: 1169px) {
  .doctor__btn {
    min-width: 200px;
  }
}

@media only screen and (max-width: 1023px) {
  .doctor__btn {
    min-width: 180px;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__btn {
    min-width: 100%;
    padding: 19px 20px 17px;
  }
}

.doctor__container {
  padding: 143px 0 65px 50px;
}

@media only screen and (max-width: 1023px) {
  .doctor__container {
    padding: 0 0 65px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__container {
    padding: 0 0 70px 0;
  }
}

.doctor__title {
  margin-bottom: 22px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
}

@media only screen and (max-width: 767px) {
  .doctor__title {
    margin-bottom: 20px;
  }
}

.doctor__info {
  margin-bottom: 45px;
  line-height: 1.6;
}

@media only screen and (max-width: 1023px) {
  .doctor__info {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__info {
    font-size: 13px;
    line-height: 1.7;
  }
}

.doctor__info p {
  margin-bottom: 25px;
}

@media only screen and (max-width: 1023px) {
  .doctor__info p {
    margin-bottom: 15px;
  }
}

.doctor__info p:last-child {
  margin-bottom: 0;
}

.doctor__feature {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@media only screen and (max-width: 767px) {
  .doctor__feature {
    flex-direction: column;
  }
}

.doctor__item {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .doctor__item {
    flex-direction: column;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__item {
    margin-bottom: 20px;
    flex-direction: row;
    align-items: center;
  }
  .doctor__item:last-child {
    margin-bottom: 0;
  }
}

.doctor__item:nth-child(2) {
  padding: 0 10px;
}

@media only screen and (max-width: 767px) {
  .doctor__item:nth-child(2) {
    padding: 0;
  }
}

.doctor__icon {
  display: flex;
  width: 55px;
  height: 55px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 1023px) {
  .doctor__icon {
    margin: 0 0 15px 0;
  }
}

@media only screen and (max-width: 767px) {
  .doctor__icon {
    margin: 0 15px 0 0;
  }
}

.doctor__icon .icon {
  font-size: 28px;
  fill: #f8f8f8;
}

.doctor__icon_experience {
  background-color: #43b9f6;
}

.doctor__icon_category {
  background-color: #3b83eb;
}

.doctor__icon_stage {
  background-color: #ff5e6a;
}

.doctor__text {
  font-weight: 700;
  color: #121212;
}

.breadcrumbs__wrap {
  display: flex;
}

.breadcrumbs__title {
  margin-bottom: 10px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 40px;
  line-height: 1;
  color: white;
}

.breadcrumbs__item {
  position: relative;
  margin-right: 10px;
  padding-right: 19px;
  color: #eceff1;
  transition: color .2s;
}

.breadcrumbs__item:before {
  content: "";
  position: absolute;
  right: 0;
  bottom: 4px;
  width: 5px;
  height: 9px;
  background: url("/icons/arrow-right-breadcrumbs.svg") no-repeat 50% 50%/100% 100%;
}

.breadcrumbs__item:last-child {
  margin-right: 0;
  padding-right: 0;
  opacity: .5;
}

.breadcrumbs__item:last-child:before {
  display: none;
}

.breadcrumbs__item:last-child:hover {
  color: #eceff1;
}

.breadcrumbs__item:hover {
  color: #3b83eb;
}

.breadcrumbs_bg {
  display: flex;
  min-height: 220px;
  background: url("/img/bg-breadcrumbs.jpg") no-repeat 50% 50%/cover;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .breadcrumbs_bg {
    min-height: 100px;
  }
}

.breadcrumbs_bg .breadcrumbs__center {
  width: 100%;
}

.services {
  display: flex;
  min-height: 90px;
  margin-bottom: 30px;
  padding: 15px 35px 15px 25px;
  align-items: center;
  position: relative;
  background: white;
  transition: box-shadow .2s;
}

@media only screen and (max-width: 767px) {
  .services {
    min-height: 40px;
    margin-bottom: 10px;
    padding: 5px 25px 5px 0;
  }
}

.services__icon {
  flex: 0 0 65px;
  text-align: center;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .services__icon {
    flex: 0 0 52px;
  }
}

.services__icon .icon {
  font-size: 42px;
  fill: #777;
  transition: fill .2s;
}

@media only screen and (max-width: 767px) {
  .services__icon .icon {
    font-size: 22px;
  }
}

.services__icon .icon-analyse-adult,
.services__icon .icon-analyse-child {
  font-size: 44px;
}

@media only screen and (max-width: 767px) {
  .services__icon .icon-analyse-adult,
  .services__icon .icon-analyse-child {
    font-size: 24px;
  }
}

.services__icon .icon-doctor-adult,
.services__icon .icon-doctor-child {
  font-size: 47px;
}

@media only screen and (max-width: 767px) {
  .services__icon .icon-doctor-adult,
  .services__icon .icon-doctor-child {
    font-size: 25px;
  }
}

.services__icon .icon-procedural,
.services__icon .icon-uzi-child,
.services__icon .icon-uzi-adult {
  font-size: 35px;
}

@media only screen and (max-width: 767px) {
  .services__icon .icon-procedural,
  .services__icon .icon-uzi-child,
  .services__icon .icon-uzi-adult {
    font-size: 19px;
  }
}

.services__text {
  padding-left: 15px;
  font-size: 15px;
  line-height: 1.6;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .services__text {
    padding-left: 0;
    font-size: 13px;
  }
}

.services__arrow {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -2px;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .services__arrow {
    right: 15px;
  }
}

.services__arrow .icon {
  font-size: 8px;
  fill: #3b83eb;
}

.services:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.services:hover .services__icon .icon {
  fill: #3b83eb;
}

.abc__item {
  display: flex;
  margin-bottom: 50px;
  break-inside: avoid-column;
  overflow: hidden;
}

.abc__item:last-child {
  margin-bottom: 0;
}

.abc__letter {
  flex: 0 0 45px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 30px;
  line-height: 1;
  color: #121212;
}

.abc__list {
  display: flex;
  padding: 8px 0 0 0;
  flex-direction: column;
  align-items: flex-start;
}

.abc__link {
  margin-bottom: 18px;
  font-family: 'MuseoSansCyrl300', serif;
  font-size: 18px;
  line-height: 1.44444;
  text-decoration: underline;
}

.abc__link:last-child {
  margin-bottom: 0;
}

.abc__link:hover {
  text-decoration: none;
}

.abc_one .abc__list {
  margin-top: -5px;
  padding: 0;
}

.abc_one .abc__link {
  margin-bottom: 12px;
  font-size: 16px;
}

.abc_one .abc__item {
  margin-bottom: 24px;
}

.abc_two {
  padding: 0 45px;
  column-count: 2;
  column-gap: 90px;
}

@media only screen and (max-width: 1169px) {
  .abc_two {
    padding: 0;
    column-gap: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .abc_two {
    column-count: auto;
    column-gap: 0;
  }
}

.abc_two .abc__item {
  margin-bottom: 20px;
}

@media only screen and (max-width: 1169px) {
  .abc_two .abc__item {
    margin-bottom: 30px;
  }
}

.abc_two .abc__letter {
  flex: 0 0 55px;
  font-size: 40px;
}

.abc_two .abc__list {
  padding: 0;
}

.abc_two .abc__link {
  margin-bottom: 17px;
}

.abc_three {
  padding-left: 60px;
  column-count: 3;
  column-gap: 80px;
}

@media only screen and (max-width: 1169px) {
  .abc_three {
    padding-left: 15px;
    column-gap: 25px;
  }
}

@media only screen and (max-width: 1023px) {
  .abc_three {
    column-count: 2;
    padding-left: 0;
    column-gap: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .abc_three {
    column-count: auto;
    column-gap: 0;
  }
}

.reception {
  padding: 55px 0;
  background: #3b83eb;
}

@media only screen and (max-width: 767px) {
  .reception {
    padding: 35px 0 45px;
  }
}

.reception__title {
  margin-bottom: 40px;
  text-align: center;
  font-family: 'MuseoSansCyrl300', serif;
  font-size: 30px;
  color: #f8f8f8;
}

@media only screen and (max-width: 767px) {
  .reception__title {
    margin-bottom: 35px;
    font-size: 24px;
  }
}

.reception__wrap {
  display: flex;
  margin: 0 -15px 50px;
}

@media only screen and (max-width: 767px) {
  .reception__wrap {
    display: block;
    margin: 0;
    padding: 0 25px;
  }
}

.reception__field {
  flex: 0 0 calc(50% - 30px);
  margin: 0 15px;
}

@media only screen and (max-width: 767px) {
  .reception__field {
    margin: 0 0 15px 0;
  }
}

.reception__btns {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .reception__btns {
    margin-top: 18px;
  }
}

.reception__btn {
  min-width: 230px;
}

@media only screen and (max-width: 767px) {
  .reception__btn {
    min-width: 240px;
  }
}

.form {
  position: relative;
}

.form__wrap {
  margin-bottom: 40px;
}

.form__field {
  margin-bottom: 30px;
}

.form__field:last-child {
  margin-bottom: 0;
}

.form__btns {
  text-align: center;
}

.form__btn {
  min-width: 100%;
}

.form__res {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form__text {
  margin-bottom: 10px;
  font-size: 18px;
}

.form__state .icon {
  font-size: 40px;
}

.form__state .icon-success {
  fill: green;
}

.form__state .icon-error {
  fill: #ff4957;
}

.form_popup .form__field {
  margin-bottom: 30px;
}

.form_popup .form__btns {
  margin-top: 50px;
}

.form_popup .form__btn {
  min-width: 230px;
}

.form.loading {
  position: relative;
}

.form.loading:after {
  content: '';
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  border: 4px solid #dadada;
  border-radius: 50%;
  border-bottom-color: #3b83eb;
  animation: loader 1s linear infinite;
  z-index: 10;
}

.form.loading .form__container,
.form.done .form__container,
.form.error .form__container {
  opacity: .2;
}

.form.done .form__res,
.form.error .form__res {
  display: flex;
}

.form.done .form__state .icon-error {
  display: none;
}

.form.error .form__state .icon-success {
  display: none;
}

@keyframes loader {
  0 {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.review__title {
  margin-bottom: 25px;
  font-size: 25px;
  color: #232323;
}

@media only screen and (max-width: 767px) {
  .review__title {
    margin-bottom: 25px;
  }
}

.review__text {
  max-width: 460px;
  margin-bottom: 25px;
  line-height: 1.5;
  color: #232323;
}

@media only screen and (max-width: 767px) {
  .review__text {
    margin-bottom: 20px;
    font-size: 13px;
  }
}

.review__text_mb10 {
  margin-bottom: 10px;
}

.review__select {
  margin-bottom: 9px;
}

.review__rating {
  margin: 25px 0;
}

.review__field {
  margin-bottom: 15px;
}

.review__btn {
  margin-top: 33px;
}

@media only screen and (max-width: 767px) {
  .review__btn {
    margin-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .review__btn .btn {
    min-width: 100%;
  }
}

.question {
  padding: 55px 40px 55px 45px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .question {
    padding: 35px 10px 40px;
  }
}

.question__title {
  margin-bottom: 27px;
}

.question__text {
  margin-bottom: 19px;
}

@media only screen and (max-width: 767px) {
  .question__text {
    font-size: 13px;
  }
}

.question__field {
  margin-bottom: 35px;
}

@media only screen and (max-width: 767px) {
  .question__field {
    margin-bottom: 20px;
  }
}

.question__field_mb20 {
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .question__field_mb20 {
    margin-bottom: 15px;
  }
}

.question__add {
  margin-bottom: 10px;
}

.question__btns {
  margin-top: 28px;
}

.question__btn {
  min-width: 230px;
}

@media only screen and (max-width: 767px) {
  .question__btn {
    min-width: 100%;
  }
}

.add {
  display: flex;
  height: 40px;
  align-items: center;
}

.add__text {
  border-bottom: 1px dashed #3b83eb;
  color: #3b83eb;
}

.add__field {
  display: none;
  width: 100%;
}

.add__field .choices__inner {
  background: none;
}

.certificates {
  display: flex;
  width: 168px;
  height: 168px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background-color: white;
  font-size: 0;
}

@media only screen and (max-width: 767px) {
  .certificates {
    width: 135px;
    height: 135px;
    margin: 0 10px 20px;
  }
}

.certificates__pic {
  width: auto !important;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.owl-item .certificates {
  width: auto;
  margin-bottom: 0;
}

.prices__head {
  margin-bottom: 30px;
  padding: 0 30px;
}

@media only screen and (max-width: 1169px) {
  .prices__head {
    padding: 0;
  }
}

.prices__item {
  margin-bottom: 20px;
  padding: 25px 30px 20px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  background-color: white;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.07);
}

@media only screen and (max-width: 767px) {
  .prices__item {
    padding: 30px 10px 15px;
  }
}

.prices__item:last-child {
  margin-bottom: 0;
}

.prices__title {
  margin-bottom: 10px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 25px;
  color: #232323;
}

@media only screen and (max-width: 767px) {
  .prices__title {
    margin-bottom: 0;
    font-size: 15px;
  }
}

.prices__row {
  display: flex;
  padding: 22px 0;
  border-bottom: 1px solid #dadada;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .prices__row {
    padding: 13px 0;
    align-items: center;
    font-size: 13px;
  }
}

.prices__row_head {
  font-weight: 700;
}

.prices__row:last-child {
  border-bottom: none;
}

.prices__col:last-child {
  flex: 0 0 100px;
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .prices__col:last-child {
    flex: 0 0 55px;
  }
}

.prices__ruble {
  font-size: 16px;
}

@media only screen and (max-width: 767px) {
  .prices__ruble {
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .prices__show {
    display: none;
  }
}

.prices__hide {
  display: none;
}

@media only screen and (max-width: 767px) {
  .prices__hide {
    display: block;
  }
}

.total {
  margin-bottom: 80px;
}

@media only screen and (max-width: 1023px) {
  .total {
    position: relative;
    width: 280px;
    margin: 0;
    background: white;
    z-index: 40;
  }
}

.total__wrap {
  position: relative;
  padding: 0 30px;
  z-index: 6;
}

@media only screen and (max-width: 1169px) {
  .total__wrap {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 1023px) {
  .total__wrap {
    padding: 35px 15px;
  }
}

.total__head {
  margin-bottom: 35px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 18px;
  text-transform: uppercase;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .total__head {
    margin-bottom: 20px;
  }
}

.total__list {
  margin-bottom: 17px;
  padding-bottom: 25px;
  border-bottom: 1px solid #dadada;
}

.total__item {
  position: relative;
  margin-bottom: 15px;
  padding-right: 25px;
  line-height: 1.5;
}

.total__item:last-child {
  margin-bottom: 0;
}

.total__del {
  position: absolute;
  top: 0;
  right: -6px;
  padding: 6px;
  font-size: 0;
}

.total__del:hover .icon {
  fill: #ff4957;
}

.total__del .icon {
  font-size: 12px;
  transition: fill .2s;
}

.total__foot {
  display: flex;
  margin-bottom: 38px;
  justify-content: space-between;
  font-weight: 700;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .total__foot {
    margin-bottom: 25px;
  }
}

.total__money {
  padding-right: 10px;
}

.total__btn {
  min-width: 100%;
}

.total__ruble {
  font-size: 16px;
}

.quality {
  display: flex;
  margin-bottom: 40px;
}

@media only screen and (max-width: 1169px) {
  .quality {
    flex-direction: column;
  }
}

@media only screen and (max-width: 767px) {
  .quality {
    flex-direction: row;
    margin-bottom: 25px;
  }
}

.quality__icon {
  display: flex;
  flex: 0 0 90px;
  width: 90px;
  height: 90px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 1169px) {
  .quality__icon {
    margin-bottom: 17px;
  }
}

@media only screen and (max-width: 767px) {
  .quality__icon {
    flex: 0 0 60px;
    width: 60px;
    height: 60px;
    margin: 0 15px 0 0;
  }
}

.quality__icon .icon {
  font-size: 43px;
  fill: white;
}

@media only screen and (max-width: 767px) {
  .quality__icon .icon {
    font-size: 30px;
  }
}

.quality__icon .icon-work {
  font-size: 40px;
}

@media only screen and (max-width: 767px) {
  .quality__icon .icon-work {
    margin-left: -3px;
  }
}

.quality__icon .icon-experience {
  font-size: 48px;
}

@media only screen and (max-width: 767px) {
  .quality__icon .icon-experience {
    font-size: 35px;
  }
}

.quality__icon .icon-doc-house {
  font-size: 60px;
}

@media only screen and (max-width: 767px) {
  .quality__icon .icon-doc-house {
    font-size: 42px;
  }
}

.quality__icon_work {
  background: #43b9f6;
}

.quality__icon_experience {
  background: #6686FF;
}

.quality__icon_clients {
  background: #4bb3c3;
}

.quality__icon_analyse {
  background: #85df89;
}

.quality__icon_doc-house {
  background: #3a82e9;
}

.quality__icon_deadline {
  background: #ff5e6b;
}

.quality__info {
  padding-left: 30px;
}

@media only screen and (max-width: 1169px) {
  .quality__info {
    padding-left: 0;
  }
}

.quality__name {
  margin-bottom: 5px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 18px;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .quality__name {
    font-size: 15px;
  }
}

.quality__text {
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .quality__text {
    font-size: 13px;
    line-height: 1.7;
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
}

.tags__item {
  margin: 0 11px 13px 0;
  padding: 7px 9px;
  border: 1px solid #dadada;
  border-radius: 5px;
  background-color: white;
  color: #666;
  transition: border-color .2s, color .2s;
}

.tags__item:hover {
  border: 1px solid #3b83eb;
  color: #3b83eb;
}

.category {
  display: flex;
  flex-direction: column;
}

.category__item {
  margin-bottom: 2px;
  padding: 15px 15px 15px 23px;
  transition: background .2s, box-shadow .2s;
  color: #232323;
}

.category__item:hover {
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.category__item.active {
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), inset 10px 0 0 #3b83eb;
}

.clinic {
  background: #ECEFF1;
}

.clinic__head {
  background-image: url("/img/clinic.jpg");
  background-repeat: no-repeat;
  background-position: 50% 0;
  background-size: cover;
}

@media only screen and (max-width: 1169px) {
  .clinic__head {
    background-image: url("/img/clinic-1.jpg");
    background-position: 50% 0;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__head {
    background-position: 68% 0;
  }
}

@media only screen and (max-width: 474px) {
  .clinic__head {
    background-image: url("/img/clinic-2.jpg");
  }
}

.clinic__head .clinic__center {
  min-height: 675px;
  display: flex;
}

@media only screen and (max-width: 1023px) {
  .clinic__head .clinic__center {
    min-height: 430px;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__head .clinic__center {
    min-height: 320px;
  }
}

.clinic__info {
  display: flex;
  flex-direction: column;
  flex: 0 0 52%;
  margin-left: auto;
  padding-bottom: 33px;
  justify-content: center;
}

@media only screen and (max-width: 1169px) {
  .clinic__info {
    flex: 0 0 49%;
    padding-bottom: 70px;
  }
}

@media only screen and (max-width: 1023px) {
  .clinic__info {
    flex: 0 0 49%;
    padding-bottom: 35px;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__info {
    flex: 0 0 55%;
    padding-bottom: 26px;
  }
}

.clinic__title {
  margin-bottom: 18px;
  font-family: 'MuseoSansCyrl700', serif;
  font-size: 40px;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .clinic__title {
    font-size: 26px;
  }
}

.clinic__slogan {
  max-width: 500px;
  margin-bottom: 30px;
  font-family: 'MuseoSansCyrl700', serif;
  color: #121212;
  font-size: 18px;
}

@media only screen and (max-width: 1023px) {
  .clinic__slogan {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__slogan {
    font-size: 13px;
    line-height: 1.7;
  }
}

.clinic__description {
  line-height: 1.6;
}

@media only screen and (max-width: 1023px) {
  .clinic__description {
    display: none;
  }
}

.clinic__foot .clinic__center {
  margin-top: -60px;
}

@media only screen and (max-width: 767px) {
  .clinic__foot .clinic__center {
    margin-top: -35px;
  }
}

.clinic__wrap {
  display: flex;
  margin: 0 -15px;
}

@media only screen and (max-width: 1023px) {
  .clinic__wrap {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__wrap {
    margin: 0;
  }
}

.clinic__item {
  flex: 0 0 calc(25% - 30px);
  margin: 0 15px;
  padding: 10px 10px 20px;
  border-radius: 5px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: white;
}

@media only screen and (max-width: 1023px) {
  .clinic__item {
    display: flex;
    min-height: 100px;
    flex-direction: row;
    flex: 0 0 calc(50% - 30px);
    margin-bottom: 30px;
    padding: 20px 10px 10px 15px;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__item {
    flex: 0 0 calc(50% - 5px);
    min-height: auto;
    margin: 0 2.5px 5px;
    padding: 10px 5px;
    align-items: center;
  }
}

.clinic__item_work {
  background: #43b9f6;
}

.clinic__item_work .icon {
  font-size: 40px;
}

.clinic__item_experience {
  background: #6686ff;
}

.clinic__item_experience .icon {
  font-size: 49px;
}

.clinic__item_clients {
  background: #4bb3c3;
}

.clinic__item_clients .icon {
  font-size: 43px;
}

.clinic__item_analyse {
  background: #85df89;
}

.clinic__item_analyse .icon {
  font-size: 45px;
}

.clinic__icon {
  display: flex;
  min-height: 60px;
  margin-bottom: 13px;
  justify-content: center;
  align-items: flex-end;
  font-size: 0;
}

@media only screen and (max-width: 1023px) {
  .clinic__icon {
    margin: 0 15px 0 0;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__icon {
    flex: 0 0 45px;
    min-height: auto;
    margin: 0;
  }
}

.clinic__icon .icon {
  fill: white;
}

@media only screen and (max-width: 767px) {
  .clinic__icon .icon {
    font-size: 28px;
  }
}

@media only screen and (max-width: 1023px) {
  .clinic__in {
    text-align: left;
  }
}

.clinic__numbers {
  margin-bottom: 10px;
  font-weight: 700;
  font-size: 20px;
}

@media only screen and (max-width: 767px) {
  .clinic__numbers {
    margin-bottom: 0;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .clinic__text {
    font-size: 13px;
  }
}

.pagination {
  text-align: center;
}

.pagination__list {
  display: inline-flex;
  margin: 0 auto;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .pagination__list {
    width: calc(100% + 30px);
    margin: 0 -15px;
  }
}

.pagination__item, .pagination__next, .pagination__prev {
  display: flex;
  width: 55px;
  height: 55px;
  margin: 0 5px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  line-height: 1;
  color: #232323;
  transition: color .2s, fill .2s;
}

@media only screen and (max-width: 767px) {
  .pagination__item, .pagination__next, .pagination__prev {
    margin: 0;
  }
}

.pagination__item .icon, .pagination__next .icon, .pagination__prev .icon {
  font-size: 14px;
  fill: #232323;
}

.pagination__item:hover, .pagination__next:hover, .pagination__prev:hover {
  color: #3b83eb;
}

.pagination__item:hover .icon, .pagination__next:hover .icon, .pagination__prev:hover .icon {
  fill: #3b83eb;
}

.pagination__item.active, .pagination__next.active, .pagination__prev.active {
  background-color: #3b83eb;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.07);
  color: white;
}

.overview {
  display: flex;
  min-height: 600px;
  margin-bottom: 80px;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1023px) {
  .overview {
    min-height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .overview {
    min-height: 150px;
  }
}

.overview__icon {
  margin-bottom: 25px;
  text-align: center;
  font-size: 0;
}

.overview__icon .icon {
  font-size: 60px;
  fill: white;
}

.overview__text {
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
}

@media only screen and (max-width: 767px) {
  .overview__text {
    font-size: 15px;
  }
}

.overview:last-child {
  margin-bottom: 0;
}

.overview_tour {
  background-image: url("/img/bg-tour.jpg");
}

.overview_video {
  background-image: url("/img/bg-video.jpg");
}

.popup {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 320px;
  background: rgba(0, 0, 0, 0.2);
  visibility: hidden;
  pointer-events: none;
  overflow: auto;
  z-index: 999;
}

@media only screen and (max-width: 767px) {
  .popup {
    padding: 15px;
  }
}

.popup.visible {
  pointer-events: all;
  visibility: visible;
}

.popup.animation .popup__wrap {
  opacity: 1;
  transform: scale(1);
}

.popup__wrap {
  position: relative;
  max-width: 770px;
  width: 100%;
  padding: 49px 30px 65px;
  margin: auto;
  opacity: 0;
  transform: scale(0.8);
  transition: opacity .2s, transform .2s;
  border-radius: 5px;
  background-color: #f8f8f8;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

@media only screen and (max-width: 1023px) {
  .popup__wrap {
    max-width: 600px;
    padding: 60px 15px 65px;
  }
}

.popup__title {
  margin-bottom: 20px;
  font-family: 'MuseoSansCyrl500', serif;
  font-size: 40px;
  line-height: 1.25;
  color: #121212;
}

@media only screen and (max-width: 767px) {
  .popup__title {
    margin-bottom: 15px;
    font-size: 30px;
  }
}

.popup__text {
  margin-bottom: 35px;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .popup__text {
    margin-bottom: 25px;
    font-size: 13px;
  }
}

.popup__form {
  display: inline-block;
  max-width: 370px;
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .popup__form {
    max-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .popup__form .form__btn {
    min-width: 250px;
  }
}

@media only screen and (max-width: 767px) {
  .popup__form .form__btns {
    margin-top: 40px;
  }
}

.popup__btn {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
}

.popup__btn:hover .icon {
  fill: #121212;
}

.popup__btn .icon {
  margin-bottom: 4px;
  font-size: 12px;
  fill: #777;
  transition: fill .2s;
}

.choices {
  margin-bottom: 0;
  padding: 0;
  font-size: 15px;
  color: #444;
}

.choices:hover .choices__inner {
  box-shadow: inset 0 -1px 0 0 #3b83eb;
}

.choices[data-type*="select-one"]:hover:after {
  background-image: url("/icons/arrow-down-blue.svg");
}

.choices[data-type*="select-one"].is-open:after {
  background-image: url("/icons/arrow-down-blue.svg");
}

.choices.error {
  z-index: 2;
}

.choices.error .choices__inner {
  box-shadow: inset 0 -1px 0 0 #ff4957;
}

.choices .choices__inner {
  padding: 16px 0 13px 0;
  border: 0;
  box-shadow: inset 0 -1px 0 0 #e5e5e5;
  border-radius: 0;
  font-size: 15px;
}

.choices .choices__list--dropdown {
  border-width: 1px 0 0 0;
  border-style: solid;
  border-color: #3b83eb;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 20;
}

.choices .choices__list {
  right: 0;
  background: white;
}

.choices .choices__list--single {
  background: none;
}

.choices .choices__list--dropdown .choices__item {
  padding: 12px 15px;
  font-size: 15px;
}

.choices .choices__list--dropdown .choices__item:hover {
  background: none;
  color: #3b83eb;
}

.choices .choices__item--disabled {
  display: none;
}

.choices .choices__list--dropdown .choices__item--selectable:after {
  display: none;
}

.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background: none;
}

.choices .choices__list--single {
  padding: 0;
}

.choices[data-type*="select-one"] .choices__input {
  padding: 12px 15px;
  font-size: 15px;
  color: #121212;
}

.choices[data-type*="select-one"] .choices__inner {
  padding-bottom: 13px;
}

.choices[data-type*="select-one"]:after {
  width: 12px;
  height: 7px;
  margin-top: 1px;
  background-image: url("/icons/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: auto auto;
  border-style: none;
  border-color: transparent;
  border-width: 0;
}

@media only screen and (max-width: 1169px) {
  .choices[data-type*="select-one"]:after {
    width: 10px;
    height: 6px;
  }
}

.choices[data-type*="select-one"].is-open:after {
  margin-top: 0;
  transform: rotate(180deg);
}

.choices label.error {
  position: absolute;
  left: 0;
  bottom: -15px;
  font-size: 12px;
  color: #ff4957;
}

.map {
  width: 100%;
  height: 630px;
}

@media only screen and (max-width: 767px) {
  .map {
    height: 550px;
  }
}

.map .title {
  margin: 0;
}

.map .title:before {
  display: none;
}

.map .address {
  display: block;
}

.infobox {
  overflow: visible !important;
}

.baloon {
  position: relative;
  width: 10px;
  height: 10px;
}

.baloon__wrap {
  position: absolute;
  top: -150px;
  left: 40px;
  width: 400px;
  border-radius: 5px;
  box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
}

@media only screen and (max-width: 1169px) {
  .baloon__wrap {
    width: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .baloon__wrap {
    width: 250px;
  }
}

.baloon__wrap:before {
  content: "";
  position: absolute;
  top: 167px;
  left: -15px;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 9.5px 15px 9.5px 0;
  border-color: transparent white transparent transparent;
}

.baloon__head {
  padding: 25px 22px 18px;
  border-bottom: 1px solid #e6e8ea;
}

@media only screen and (max-width: 767px) {
  .baloon__head {
    padding: 25px 15px 15px;
  }
}

.baloon__title {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 12px;
  font-family: 'MuseoSansCyrl500', serif;
  text-transform: none;
  font-size: 18px;
  color: #121212;
}

.baloon__title:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 104px;
  height: 6px;
  background: url("/img/line-red.png") repeat-x 0 0/auto 100%;
}

.baloon__address.address {
  display: flex;
  margin-bottom: 15px;
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
}

.baloon__address .address__metro {
  margin-bottom: 12px;
}

.baloon__schedule .schedule__icon {
  margin-right: 15px;
}

.baloon__foot {
  padding: 15px 22px 30px;
}

.baloon__phone .icon-phone {
  fill: white;
}

.baloon__phone .btn {
  min-width: 175px;
}

.baloon__phone .btn:hover .icon-phone {
  fill: white;
}

.likely__icon, .likely__counter {
  display: none;
}
