.icon-analyse-adult {
  width: 1.02em;
  height: 1em;
  fill: #777;
}
.icon-analyse-child {
  width: 1.14em;
  height: 1em;
  fill: #777;
}
.icon-analyse {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-arrow-down-blue {
  width: 1.75em;
  height: 1em;
  fill: initial;
}
.icon-arrow-down {
  width: 1.75em;
  height: 1em;
  fill: initial;
}
.icon-arrow-left {
  width: 0.57em;
  height: 1em;
  fill: #000;
}
.icon-arrow-next {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-prev {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right-breadcrumbs {
  width: 0.57em;
  height: 1em;
  fill: initial;
}
.icon-arrow-right {
  width: 0.57em;
  height: 1em;
  fill: initial;
}
.icon-arrow {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-burger {
  width: 1.25em;
  height: 1em;
  fill: initial;
}
.icon-calendar {
  width: 0.9em;
  height: 1em;
  fill: initial;
}
.icon-check {
  width: 1.33em;
  height: 1em;
  fill: initial;
}
.icon-clients {
  width: 0.83em;
  height: 1em;
  fill: #fff;
}
.icon-clock {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-close {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-deadline {
  width: 0.96em;
  height: 1em;
  fill: #fff;
}
.icon-dms {
  width: 0.81em;
  height: 1em;
  fill: #777;
}
.icon-doc-cat {
  width: 1.29em;
  height: 1em;
  fill: #fff;
}
.icon-doc-house {
  width: 0.51em;
  height: 1em;
  fill: #fff;
}
.icon-doctor-adult {
  width: 1.06em;
  height: 1em;
  fill: #777;
}
.icon-doctor-child {
  width: 1.13em;
  height: 1em;
  fill: #777;
}
.icon-email {
  width: 1.45em;
  height: 1em;
  fill: initial;
}
.icon-error {
  width: 1em;
  height: 1em;
  fill: #000;
}
.icon-experience {
  width: 0.88em;
  height: 1em;
  fill: #fff;
}
.icon-eye-circle {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-eye {
  width: 1.47em;
  height: 1em;
  fill: initial;
}
.icon-fb {
  width: 0.54em;
  height: 1em;
  fill: initial;
}
.icon-functional-diagnostics {
  width: 1em;
  height: 1em;
  fill: #777;
}
.icon-gift {
  width: 0.95em;
  height: 1em;
  fill: #ffede7;
}
.icon-hand {
  width: 1.99em;
  height: 1em;
  fill: initial;
}
.icon-like {
  width: 1.11em;
  height: 1em;
  fill: initial;
}
.icon-massage-adult {
  width: 1.33em;
  height: 1em;
  fill: #777;
}
.icon-massage-child {
  width: 1.43em;
  height: 1em;
  fill: #777;
}
.icon-metro {
  width: 1.22em;
  height: 1em;
  fill: initial;
}
.icon-phone {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-play {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-procedural {
  width: 1.22em;
  height: 1em;
  fill: #777;
}
.icon-review {
  width: 1.18em;
  height: 1em;
  fill: initial;
}
.icon-sale {
  width: 1.06em;
  height: 1em;
  fill: #ffede7;
}
.icon-search {
  width: 1em;
  height: 1em;
  fill: none;
}
.icon-sertificate-adult {
  width: 1.27em;
  height: 1em;
  fill: #777;
}
.icon-sertificate-child {
  width: 1.37em;
  height: 1em;
  fill: #777;
}
.icon-stacsh {
  width: 1em;
  height: 1em;
  fill: #fff;
}
.icon-stage {
  width: 0.88em;
  height: 1em;
  fill: initial;
}
.icon-success {
  width: 1em;
  height: 1em;
  fill: #000;
}
.icon-time {
  width: 1em;
  height: 1em;
  fill: initial;
}
.icon-tw {
  width: 1.23em;
  height: 1em;
  fill: initial;
}
.icon-uzi-adult {
  width: 1.33em;
  height: 1em;
  fill: #777;
}
.icon-uzi-child {
  width: 1.44em;
  height: 1em;
  fill: #777;
}
.icon-vk {
  width: 0.77em;
  height: 1em;
  fill: initial;
}
.icon-work {
  width: 1.25em;
  height: 1em;
  fill: #fff;
}
.icon-youtube {
  width: 0.84em;
  height: 1em;
  fill: initial;
}
