@mixin d {
  @media only screen and (max-width: "1169px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@mixin rmenu {
  @media only screen and (min-width: "1023px") and (max-height: "730px") {
    @content; } }

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700&subset=cyrillic,cyrillic-ext');
@mixin f {
  font-family: 'Open Sans', sans-serif; }

@import url('https://fonts.googleapis.com/css?family=Roboto');
@mixin fr {
  font-family: 'Roboto', sans-serif; }

@include font(MyriadProRegular, MyriadProRegular);
@mixin fmy {
  font-family: 'MyriadProRegular', serif; }

@include font(MuseoSansCyrl300, MuseoSansCyrl300);
@mixin fm3 {
  font-family: 'MuseoSansCyrl300', serif; }

@include font(MuseoSansCyrl500, MuseoSansCyrl500);
@mixin fm {
  font-family: 'MuseoSansCyrl500', serif; }

@include font(MuseoSansCyrl700, MuseoSansCyrl700);
@mixin fm7 {
  font-family: 'MuseoSansCyrl700', serif; }

$light-black: #444;
$black: #121212;
$light-blue: #c5daf9;
$blue: #3b83eb;
$pink: #ff5e6a;
$gray: #777;
$red: #ff4957;
$border: #dadada;

body {
  @include f;
  line-height: 1.3;
  min-width: 320px;
  font-size: 15px;
  color: $light-black;
  &.no-scroll {
    overflow: hidden; }
  &.touch-device {
    .nav__item:hover .nav__drop {
      @include m {
        display: none; } }
    .nav__item.hover > .nav__link {
      .icon-arrow-down {
        opacity: 0;
        transform: translateY(-1px); }
      .icon-close {
        opacity: 1; } }
    .nav__item.hover .nav__drop {
      @include m {
        display: block; } } } }

body,
button,
input,
textarea,
select {}

textarea {
  border: none; }

a {
  text-decoration: none;
  color: $light-black; }

button {
  background: none; }

.out {
  position: relative;
  min-height: 100vh;
  background-color: #eceff1;
  overflow: hidden; }

.btn-scroll {
  position: fixed;
  bottom: 30px;
  left: 200px;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s;
  z-index: 1000;
  &.visible {
    opacity: 1;
    pointer-events: all; }
  @include r(1900) {
    left: 50px; }
  @include r(1600) {
    left: 10px;
    bottom: 10px;
    left: 30px; }
  @include d {
    bottom: 30px; }
  @include t {
    left: 20px;
    bottom: 55px; }
  @include m {
    left: 5px;
    bottom: 5px; } }

.center {
  max-width: 1230px;
  margin: 0 auto;
  padding: 0 30px;
  @include t {
    padding: 0 25px; }
  @include m {
    padding: 0 15px; }
  &_small {
    max-width: 660px;
    padding: 0 15px; }
  &_big {
    max-width: 1360px; } }

.header {
  position: relative;
  z-index: 20;
  &__nav {
    display: flex;
    justify-content: center; }
  &__top {
    padding: 11px 0;
    background: #02519C;
    @include m {
      height: 40px;
      padding: 0; } }
  &__top &__center {
    @include m {
      display: none; } }
  &__link {
    margin-right: 38px;
    font-size: 12px;
    line-height: (18/12);
    color: $light-blue;
    transition: color .2s;
    &:last-child {
      margin-right: 0; }
    &:hover {
      color: white; } }
  &__container {
    padding: 18px 0 16px;
    background-color: #0277bd;
    @include d {
      padding: 18px 0; }
    @include m {
      padding: 25px 0; } }
  &__inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @include t {
      align-items: center; } }
  &__logo {
    padding-top: 4px; }
  &__schedule,
  &__address {
    padding: 0 5px; }
  &__schedule .schedule__icon {
    @include t {
      display: none; } }
  &__address {
    @include d {
      max-width: 250px; }
    @include m {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      max-width: 100%;
      padding: 13px 15px 12px 15px; } }
  &__address .address__icon {
    @include m {
      display: block;
      font-size: 0;
      .icon {
        vertical-align: middle; } } }
  &__address .address__icon.active {
    .icon-arrow-down {
      transform: rotate(180deg); } }
  &__address .address__info {
    @include m {
      display: none;
      position: absolute;
      top: 40px;
      left: 0;
      right: 0;
      padding: 0 15px 11px 15px;
      background: #02519C; } }
  &__address .address__info.visible {
    @include m {
      display: block; } }
  &__phone {
    margin-top: -6px;
    @include d {
      margin-top: 0; }
    @include m {
      position: absolute;
      top: 10px;
      right: 15px;
      margin-top: 0; } }
  &__phone .phone__number {
    @include m {
      display: flex;
      margin: 0;
      align-items: center; } }
  &__phone .phone__btn {
    @include m {
      display: none; } }
  &__phone .phone__number .icon {
    display: none;
    margin: -4px 10px 0 0;
    fill: white;
    @include m {
      display: inline-block; } } }

.logo {
  display: inline-block;
  position: relative;
  font-size: 0;
  &__pic {
    width: 184px;
    @include t {
      width: 160px; } }
  &__text {
    position: absolute;
    right: 0;
    bottom: 3px;
    font-size: 12px;
    color: $light-blue; }
  &_footer &__pic {
    width: 130px; } }

.schedule {
  display: flex;
  align-items: flex-start;
  &__icon {
    margin-right: 10px;
    .icon {
      font-size: 15px;
      fill: $light-blue; } }
  &__info {
    color: $light-blue; }
  &__item {
    display: flex;
    margin-bottom: 8px;
    align-items: flex-end;
    &:last-child {
      margin-bottom: 0; } }
  &__days {
    margin-right: 3px;
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1; }
  &__time {
    font-size: 0;
    line-height: 1; }
  &__hours,
  &__minutes {
    display: inline-block;
    vertical-align: top; }
  &__hours {
    font-size: 15px; }
  &__minutes {
    font-size: 10px; }
  &_black &__info {
    color: $light-black; }
  &_black &__icon {
    .icon {
      fill: $light-black; } }
  &_opacity &__icon {
    .icon {
      opacity: .5; } }
  &_widget &__icon {
    margin-right: 17px; } }


.address {
  display: flex;
  align-items: flex-start;
  &__icon {
    display: block;
    margin-right: 13px;
    @include t {
      margin-right: 5px; }
    .icon-metro {
      font-size: 15px;
      fill: $light-blue; }
    .icon-arrow-down {
      display: none;
      font-size: 5px;
      fill: $light-blue;
      @include m {
        display: inline-block;
        margin-left: 10px;
        font-size: 5px;
        fill: $light-blue; } } }
  &__info {
    color: $light-blue; }
  &__metro {
    margin-bottom: 8px;
    font-weight: 700;
    line-height: 1.2; }
  &__name {
    margin-bottom: 6px; }
  &__span {
    @include d {
      display: block;
      margin-bottom: 8px; } }
  &__wrap {
    @include t {
      margin-left: -23px; }
    @include m {
      margin-left: 0; } }
  &__maps {
    border-bottom: 1px dashed $light-blue;
    color: $light-blue;
    transition: border-color .2s;
    &:hover {
      border-color: transparent; } }
  &_black &__info {
    color: $light-black; }
  &_black &__maps {
    border-bottom: 1px dashed $blue;
    color: $blue;
    &:hover {
      border-color: transparent; } }
  &_black &__icon {
    .icon-metro {
      fill: $light-black; } }
  &_opacity &__icon {
    .icon-metro {
      opacity: .5; } } }

.phone {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__number {
    display: inline-block;
    margin-bottom: 10px;
    @include fm7;
    font-size: 18px;
    line-height: (25/18);
    color: white;
    .icon {
      font-size: 18px; } }
  &__btn {
    @include t {
      min-width: 157px; } }
  &_black &__number {
    color: $light-black; }
  &_black &__btn {
    min-width: 200px; }
  &_simple {
    flex-direction: row;
    .icon {
      margin: -2px 13px 0 0; } }
  &_simple &__number {
    display: flex;
    align-items: center;
    margin: 0; }
  &_widget {
    .icon {
      fill: white; } }
  &_widget &__number {
    @include fm; } }

.email {
  display: flex;
  align-items: flex-start;
  &__icon {
    margin-right: 12px;
    .icon {
      font-size: 13px;
      fill: #959697; } }
  &__info {
    @include fm7;
    color: $light-black; }
  &__title {
    margin-bottom: 8px; }
  &_simple &__info {
    display: flex; } }


.btn {
  display: inline-block;
  min-width: 174px;
  padding: 17px 15px 15px;
  border-radius: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  @include fm7;
  font-size: 12px;
  text-transform: uppercase;
  transition: background .2s, color .2s, box-shadow .2s;
  &:hover {
    box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1); }
  &_icon {
    padding: 15px 5px;
    .icon {
      vertical-align: middle;
      fill: $light-black;
      transition: fill .2s;
      &-phone {
        margin: -2px 10px 0 0;
        font-size: 15px; }
      &-close {
        margin-top: -2px;
        font-size: 12px; }
      &-arrow {
        font-size: 14px;
        transform: rotate(90deg);
        fill: white; } }
    &:hover {
      .icon {
        fill: $blue; } } }
  &_icon &__title {
      display: inline-block;
      vertical-align: middle; }
  &_white {
    border: 1px solid #f1f1f1;
    background-color: white;
    color: $light-black;
    &:hover {
      color: $blue; } }
  &_red {
    background-color: $pink;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    color: white;
    &:hover {
      background-color: #ff4957; } }
  &_blue {
    background-color: $blue;
    color: white;
    &:hover {
      background-color: #2f7ceb; } }
  &.disabled,
  &[disabled] {
    background-color: #e6e8ea;
    box-shadow: none;
    color: $gray;
    cursor: not-allowed;
    &:hover {
      box-shadow: none;
      background-color: #e6e8ea; } }
  &_square {
    min-width: 40px;
    height: 40px; }
  &_circle {
    min-width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: $blue;
    box-shadow: none;
    &:hover {
      box-shadow: none;
      background: $pink;
      .icon {
        fill: white; } } }
  &_small {
    padding: 14px 15px 12px; }
  &_big {
    padding: 24px 15px 22px;
    @include m {
      padding: 19px 15px 17px; } } }

.nav {
  position: relative;
  background: white;
  z-index: 20;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 6; }
  &__menu {
    display: flex;
    margin: 0 15px 0 35px;
    justify-content: center;
    @include m {
      margin: 0;
      align-items: center; } }
  &__inner {
    @include m {
      margin-right: auto; } }
  &__burger {
    position: relative;
    display: none;
    width: 50px;
    height: 60px;
    z-index: 24;
    @include m {
      display: block; }
    .icon {
      margin-top: -2px;
      fill: $gray; }
    .icon-burger {
      font-size: 13px; }
    .icon-close {
      display: none;
      font-size: 13px; } }
  &__burger.active {
    .icon-burger {
      display: none; }
    .icon-close {
      display: inline-block; } }
  &__box {
    @include m {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      left: 0;
      padding: 30px 15px 35px 30px;
      border-radius: 0px 0px 5px 5px;
      background-color: white;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      z-index: 2; } }
  &__box.visible {
    display: flex; }
  &__section {
    display: flex;
    @include m {
      flex-direction: column;
      margin-right: 40px; } }
  &__section &__item {
    @include m {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__section &__item > &__link {
    @include m {
      height: auto;
      padding: 5px 10px; } }
  &__chapter {
    display: none;
    @include m {
      display: flex;
      flex-direction: column; } }
  &__chapter &__link {
    margin-bottom: 5px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 18px;
    color: #777;
    &:last-child {
      margin-bottom: 0; } }
  &__item {
    display: block; }
  &__item > &__link {
    position: relative;
    display: flex;
    height: 60px;
    padding: 18px 24px;
    align-items: center;
    @include fm7;
    font-size: 14px;
    line-height: (25/14);
    text-transform: uppercase;
    transition: color .2s, box-shadow .2s;
    z-index: 8;
    // &:last-child
    //   margin-right: 0
    .icon {
      transition: opacity .2s, transform .2s; }
    .icon-arrow-down {
      margin: -2px 0 0 11px;
      font-size: 5px; }
    .icon-close {
      position: absolute;
      top: 25px;
      right: 0;
      font-size: 9px;
      opacity: 0; } }
  &__menu &__link_simple {
    cursor: default;
    @include m {
      padding: 18px 0 18px 0px; } }
  &__item_search,
  &__item_phone {
    position: relative;
    z-index: 8; }
  &__item_search {
    width: 75px;
    height: 60px;
    font-size: 0;
    .icon-search {
      fill: $light-black;
      font-size: 19px; }
    .icon-close {
      display: none;
      font-size: 14px; } }
  &__item_search.active {
    box-shadow: inset 0 -3px 0 #e6e8ea; }
  &__item_search.active .icon-search {
    display: none; }
  &__item_search.active .icon-close {
    display: inline-block; }
  &__item_phone {
    display: none;
    width: 46px;
    height: 46px;
    margin-left: 15px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    @include m {
      display: block; } }
  &__item_search > &__link {
    padding: 0; }
  &__item_phone .icon {
    font-size: 16px;
    fill: $light-black; }
  &__item:hover > &__link {
    box-shadow: inset 0 -3px 0 $blue;
    color: $black; }
  &__item:hover &__drop {
    display: block; }
  &__drop {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0; }
  &__l {
    display: flex;
    margin-bottom: 20px;
    border-radius: 0px 0px 5px 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
    overflow: hidden;
    @include m {
      display: block;
      position: relative;
      margin: 0 -15px;
      overflow: visible; } }
  &__sidebar {
    position: relative;
    flex: 0 0 300px;
    min-height: 520px;
    background-color: $blue;
    @include rmenu {
      min-height: 413px; }
    @include t {
      flex: 0 0 283px; }
    @include m {
      min-height: 0;
      flex: 0 0 100%;
      z-index: 2; } }
  &__sort {
    display: flex;
    position: relative;
    justify-content: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 10; }
  &__sort &__link {
    padding: 30px 20px 21px;
    border-bottom: 3px solid transparent;
    font-size: 13px;
    text-transform: uppercase;
    color: $light-blue;
    transition: color .2s, border-color .2s;
    &:hover {
      color: white; }
    &.active {
      border-color: white;
      color: white; } }
  &__types {
    position: absolute;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px 0;
    border-radius: 0px 0px 0px 5px;
    direction: rtl;
    overflow: auto;
    z-index: 6;
    @include m {
      position: relative;
      top: auto; }
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px; }
    &::-webkit-scrollbar-thumb {
      box-shadow: inset 0 0 0 2px #3270c8;
      background-color: #5f9df6;
      border-radius: 5px; }
    &::-webkit-scrollbar-track {
      background-color: #3270c8;
      background-image: url('/img/scroll-up.png'), url('/img/scroll-down.png');
      background-repeat: no-repeat, no-repeat;
      background-position: 0 0, 0 100%;
      background-size: contain, contain; } }
  &__list {
    direction: ltr; }
  &__list &__link {
    display: flex;
    padding: 11px 38px 10px 20px;
    color: white;
    align-items: center;
    transition: background .2s;
    @include t {
      padding: 11px 21px 10px 20px; }
    &:after {
      content: "";
      display: block;
      position: relative;
      top: 1px;
      margin-left: 10px;
      @include arr(5, 9, white, r); }
    &:hover {
      background: #4a8ceb; }
    &.active {
      background: #377adb; } }
  &__total {
    display: inline-block;
    margin-top: 10px; }
  &__container {
    flex: 0 0 calc(100% - 300px);
    max-width: calc(100% - 300px);
    background: white;
    @include t {
      flex: 0 0 calc(100% - 283px);
      max-width: calc(100% - 283px); }
    @include m {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      padding-left: 15px;
      flex: 0 0 100%;
      max-width: 100%;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      z-index: 4;
      &.visible {
        display: block; } } }
  &__back {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    bottom: 0;
    background: $blue;
    @include m {
      display: block; }
    .icon {
      font-size: 10px;
      fill: white; } }
  &__group {
    display: none;
    &_hide {
      @include t {
        display: none; } }
    &.active {
      display: block; } }
  &__head {
    display: flex;
    align-items: center;
    padding: 21px 30px 23px 42px;
    @include rmenu {
      padding-top: 10px!important;
      padding-bottom: 10px!important; }
    @include d {
      padding: 20px 20px 25px 30px;
      flex-direction: column;
      align-items: flex-start; }
    @include t {
      padding: 23px 5px 25px 27px; }
    @include m {
      display: block;
      padding: 15px 15px 20px 20px; } }
  &__title {
    @include fm;
    font-size: 25px;
    line-height: (36/25);
    color: $black;
    @include m {
      font-size: 18px; } }
  &__categories {
    padding: 13px 15px 0 43px;
    box-shadow: 0 1px 0 #e6e8ea, inset 0 1px 0 #e6e8ea;
    @include d {
      padding: 13px 15px 0 30px; }
    @include t {
      padding: 13px 10px 0 30px; }
    @include m {
      padding: 13px 0 3px 20px; } }
  &__categories &__link {
    display: inline-block;
    margin: 0 29px 17px 0;
    @include m {
      margin: 0 15px 10px 0; }
    &:last-child {
      margin-right: 0; } }
  &__wrap {
    padding: 25px 20px 20px 43px;
    @include rmenu {
      padding-top: 20px!important;
      padding-bottom: 20px!important; }
    @include d {
      padding: 25px 20px 20px 30px; }
    @include t {
      padding: 33px 10px 20px 30px; }
    @include m {
      padding: 20px; } }
  &__direction {
    text-align: center;
    padding-top: 40px; }
  &__direction-icon {
    margin: 0 0 40px -160px;
    @include d {
      margin: 0 0 40px -135px; }
    @include t {
      margin: 0 0 40px -40px; }
    .icon {
      font-size: 200px;
      fill: #e6e8ea;
      @include rmenu {
        font-size: 165px; }
      @include d {
        font-size: 156px; } } }
  &__direction-text {
    width: 100%;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase; }
  &__btns {
    flex: 0 0 55%;
    text-align: right;
    @include d {
      margin-top: 15px;
      flex: 0 0 100%;
      text-align: left; } }
  &__btns &__btn {
    min-width: 190px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    @include m {
      min-width: 100%; }
    &:not(:first-child) {
      margin-left: 20px;
      @include r(1240) {
        margin-left: 15px; }
      @include t {
        margin-left: 10px; }
      @include m {
        margin: 10px 0 0; } } }
  &__services {
    &_links {
      margin-bottom: 20px;
      column-count: 3;
      @include t {
        margin-bottom: 10px;
        column-count: 2;
        column-gap: 45px; }
      @include m {
        column-count: 1;
        column-gap: 0; } }
    &_preview {
      display: flex; } }
  &__services_links &__link:nth-child(n+9) {
    @include m {
      display: none; } }
  &__services &__link {
    display: block;
    margin-bottom: 20px;
    text-decoration: underline;
    color: $light-black;
    &:last-child {
     margin-bottom: 0; }
    &:hover {
      text-decoration: none; } }
  &__col:first-child {
    flex: 0 0 33.33%;
    padding-right: 10px;
    @include d {
      flex: 0 0 40%; }
    @include t {
      flex: 0 0 100%;
      margin-bottom: 10px;
      column-count: 2;
      column-gap: 45px; }
    @include m {
      column-count: 1;
      column-gap: 0; } }
  &__col:last-child {
    flex: 0 0 66.66%;
    max-width: 66.66%;
    @include d {
      flex: 0 0 60%;
      max-width: 60%;
      padding: 0 50px; }
    @include t {
      display: none; } }
  &__all {
    padding-left: 68%;
    @include t {
      padding-left: 56%; }
    @include m {
      padding: 0; } }
  &__search {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 4; } }

.link {
  text-decoration: underline;
  color: $blue;
  transition: color .2s;
  &:hover {
    color: $pink; }
  &:active {
    color: #901148; } }

.search {
  display: none;
  max-width: 770px;
  margin: 0 auto;
  background-color: white;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  @include t {
    margin: 0 25px; }
  @include m {
    margin: 0 15px; }
  &.visible {
    display: block; }
  &.visible {}
  &__input {
    width: 100%;
    height: 80px;
    padding: 0 80px 0 40px;
    background: none;
    @include fm;
    font-size: 18px;
    line-height: (26/18);
    @include placeholder {
      color: #7d8b99; }
    @include d {
      height: 60px; }
    @include t {
      height: 80px; }
    @include m {
      padding: 0 50px 0 15px; } }
  &__btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    transition: fill .2s;
    @include d {
      height: 60px; }
    @include t {
      height: 80px; }
    @include m {
      width: 50px; }
    &:hover {
      .icon-search {
        fill: $blue; } } }
  .icon {
    fill: $light-black; }
  .icon-search {
    font-size: 19px; } }

.social {
  display: flex;
  &__item {
    margin-right: 15px;
    &:last-child {
      margin-right: 0; } }
  &__icon {
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transition: background .2s;
    .icon {
      fill: white;
      transition: fill .2s; }
    .icon-vk {
      font-size: 13px; }
    .icon-tw {
      font-size: 11px; }
    .icon-youtube {
      font-size: 18px; }
    .icon-fb {
      font-size: 20px; } }
  &__text {
    margin-left: 20px;
    @include fm7;
    font-size: 18px;
    color: $black;
    transition: color .2s; }
  &_row {
    flex-direction: column;
    @include t {
      flex-direction: row; }
    @include m {
      flex-direction: column; } }
  &_row &__item {
    margin: 0 0 15px 0;
    @include t {
      margin: 0 50px 0 0; }
    @include m {
      margin: 0 0 20px 0; } }
  &_gray &__item {
    margin-right: 8px;
    &:last-child {
      margin-right: 0; } }
  &_gray &__icon {
    background: #C6C7C9;
    .icon {
      fill: $gray; }
    &:hover {
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
      .icon {
        fill: white; } } }
  &_gray &__item_vk:hover &__icon {
    background-color: #507299; }
  &_gray &__item_tw:hover &__icon {
    background-color: #1da1f2; }
  &_gray &__item_youtube:hover &__icon {
    background-color: #cc181e; }
  &_color &__item {
    display: flex;
    align-items: center;
    &:last-child {
      margin: 0; } }
  &_color &__icon {
    width: 55px;
    height: 55px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07); }
  &_color &__item_vk &__icon {
    background-color: #507299; }
  &_color &__item_tw &__icon {
    background-color: #1da1f2; }
  &_color &__item_youtube &__icon {
    background-color: #cc181e; }
  &_color &__item_fb &__icon {
    background-color: #3b5998; }
  &_color &__item_vk:hover &__text {
    color: #507299; }
  &_color &__item_tw:hover &__text {
    color: #1da1f2; }
  &_color &__item_youtube:hover &__text {
    color: #cc181e; }
  &_color &__item_fb:hover &__text {
    color: #3b5998; } }

.slick-prev,
.slick-next {
  width: 57px;
  height: 57px;
  background-color: white;
  border: 1px solid $border;
  border-radius: 50%;
  .icon {
    margin-top: 2px;
    font-size: 14px;
    fill: #666; }
  &:hover {
    border: 1px solid $blue;
    .icon {
      fill: $blue; } } }
.slick-next {
  .icon {
    transform: rotate(180deg); } }

.rating {
  &__text {
    margin-bottom: 10px; }
  &__scale {
    left: -3px; }
  &__result {
    display: flex;
    align-items: center;
    @include fm;
    color: #ffc400; }
  &__value {
    font-size: 18px;
    line-height: 1; }
  &__max {
    margin-top: 4px;
    font-size: 12px;
    line-height: 1; }
  &_result {
    display: flex; }
  &_result &__scale {
    margin-right: 12px; } }

.rateit {
  cursor: pointer;
  &[data-rateit-readonly="true"] {
    cursor: default; }
  .rateit-range,
  .rateit-hover,
  .rateit-selected {
    background-image: url(/img/stars.png);
    background-repeat: repeat-x;
    background-size: 24px auto; }
  .rateit-range {
    display: block;
    width: 5 * 24px;
    height: 17px;
    background-position: 0 -17px; }
  .rateit-hover {
    height: 17px;
    background-position: 0 0; }
  .rateit-selected {
    background-position: 0 0; }
  button.rateit-reset {
    display: none!important; }
  &:hover:not([data-rateit-readonly="true"]) .rateit-range {
    background-position: 0 -51px; }
  &:hover:not([data-rateit-readonly="true"]) .rateit-selected,
  &:hover:not([data-rateit-readonly="true"]) .rateit-hover, {
    background-position: 0 -34px; } }

.checkbox,
.radio {
  display: inline-block;
  user-select: none;
  cursor: pointer;
  &__input {
    display: none; }
  &__in {
    position: relative;
    padding-left: 25px;
    font-size: 0; }
  &__tick {
    position: absolute;
    top: 1px;
    left: 0;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $border;
    background-color: white;
    transition: border-color .2s; }
  &__text {
    font-size: 15px;
    transition: color .2s; }
  &__input:checked+&__in &__tick {
    border: 1px solid $blue; }
  &__input:checked+&__in &__text {
    color: $black; }
  &:hover &__tick {
    border: 1px solid $blue; }
  &:hover &__text {
    color: $black; } }

.checkbox {
  &__tick {
    border-radius: 3px;
    .icon-check {
      display: none;
      font-size: 6px;
      fill: white; } }
  &__input:checked+&__in &__tick {
    background-color: $blue;
    .icon-check {
      display: block; } }
  &__ruble {
    font-size: 16px; }
  &_prices {
    display: block; }
  &_prices &__tick {
    top: 50%;
    margin-top: -8px; }
  &_prices &__name {
    @include d {
      line-height: 1.6; }
    @include m {
      font-size: 13px; } }
  &_prices &__text {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &_prices &__in {
    padding: 20px 0 20px 30px;
    border-bottom: 1px solid $border;
    @include m {
      padding: 11px 0 13px 30px; } }
  &_prices:last-child &__in {
    border: 1px solid transparent; }
  &_prices &__number {
    flex: 0 0 100px;
    margin-left: auto;
    text-align: right;
    @include m {
      flex: 0 0 55px; } }
  &_prices &__code {
    flex: 0 0 70px;
    padding-right: 10px;
    @include m {
      display: none; } }
  &_prices &__input:checked+&__in {
    border-bottom: 1px solid $blue; }
  &_head {
    cursor: auto; }
  &_head &__in {
    padding: 36px 0 22px 30px;
    font-weight: 700;
    @include m {
      padding: 42px 0 14px 30px; } }
  &__show {
    @include m {
      display: none; } }
  &__hide {
    display: none;
    @include m {
      display: block; } } }

.radio {
  &__tick {
    border-radius: 50%;
    &:before {
      display: none;
      content: "";
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $blue;
      transition: .2s; } }
  &__input:checked+&__in &__tick {
    &:before {
      display: block; } } }

.field {
  position: relative;
  &__label {
    position: absolute;
    top: 10px;
    left: 0;
    pointer-events: none;
    font-size: 15px;
    color: $light-black;
    transition: transform .2s, font-size .2s;
    z-index: 5; }
  &__input,
  &__textarea {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #d3d3d3;
    background: none;
    @include f;
    font-size: 15px;
    color: $light-black;
    transition: border-color .2s;
    &:hover {
      border-bottom: 1px solid $blue; }
    &.error {
      border-color: $red; } }
  &__input {
    padding: 0 10px; }
  &__textarea {
    padding: 8px 10px; }
  &__wrap {
    position: relative; }
  &__title {
    margin-bottom: 5px;
    color: $light-black; }
  &__btn {
    display: flex;
    position: absolute;
    width: 40px;
    top: 0;
    right: -12px;
    bottom: 0;
    justify-content: center;
    align-items: center;
    z-index: 20;
    .icon {
      font-size: 14px;
      fill: $black; } }
  label.error {
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 12px;
    color: $red; }
  &.active &__input {
    border-bottom: 1px solid $blue; }
  &.active &__label {
    transform: translateY(-18px);
    font-size: 12px;
    color: $gray; }
  &_white &__label,
  &_white &__input {
    color: #f8f8f8; }
  &_white.active &__label {
    color: #f8f8f8; }
  &_white &__input {
    border-bottom: 1px solid #f8f8f8;
    &:hover {
      border-bottom: 1px solid #f8f8f8; } }
  &_white.active &__input {
    border-bottom: 1px solid #f8f8f8; } }

.footer {
  &__top {
    padding: 30px 0 32px;
    background: #E6E8EA; }
  &__inner {
    display: flex;
    justify-content: space-between;
    @include m {
      flex-direction: column; } }
  &__schedule {
    @include m {
      margin-bottom: 20px;
      padding-left: 20px; } }
  &__email,
  &__address {
    padding: 0 5px;
    @include m {
      margin-bottom: 20px;
      padding: 0 0 0 20px; } }
  &__email {
    @include t {
      display: none; } }
  &__phone .phone__btn {
    @include m {
      min-width: 100%; } }
  &__container {
    padding: 33px 0 50px;
    background: white;
    @include d {
      padding: 45px 0 50px; }
    @include m {
      padding: 45px 0; } }
  &__row {
    display: flex; }
  &__col {
    &:nth-child(1) {
      flex: 0 0 19.6%;
      padding-top: 15px;
      border-right: 1px solid #e6e8ea;
      @include d {
        flex: 0 0 22.6%; }
      @include t {
        flex: 0 0 26.6%; }
      @include m {
        flex: 0 0 100%;
        padding: 0;
        border: none;
        text-align: center; } }
    &:nth-child(2) {
      flex: 0 0 29%;
      padding: 32px 15px 0 70px;
      @include d {
        flex: 0 0 25%;
        padding: 32px 30px 0 30px; }
      @include t {
        flex: 0 0 31%;
        padding: 32px 10px 0 60px; }
      @include m {
        display: none; } }
    &:nth-child(3) {
      flex: 0 0 51.4%;
      padding: 32px 0 0 30px;
      @include d {
        flex: 0 0 52.4%; }
      @include t {
        flex: 0 0 42.4%; }
      @include m {
        display: none; } } }
  &__logo {
    margin-bottom: 46px;
    @include m {
      margin-bottom: 34px; } }
  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
      align-items: center; } }
  &__menu &__link {
    margin-bottom: 19px;
    @include fm7;
    font-size: 14px;
    line-height: (25/14);
    text-transform: uppercase;
    color: $black;
    transition: color .2s;
    &:last-child {
      margin-bottom: 0; }
    &:hover {
      color: $blue; } }
  &__title {
    margin-bottom: 18px;
    @include fm7;
    color: $black;
    font-size: 14px;
    line-height: (25/14);
    text-transform: uppercase; }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &_cols {
      display: block;
      column-count: 2;
      column-gap: 30px;
      @include t {
        column-count: 1;
        column-gap: 0; } } }
  &__list_cols &__link {
    display: block;
    &:nth-child(n+9):not(:last-child) {
      @include t {
        display: none; } } }
  &__list &__link {
    margin-bottom: 12px;
    transition: color .2s;
    line-height: 1.25;
    &:last-child {
      margin-bottom: 0; }
    &:hover {
      color: $blue; } }
  &__link.link {
    display: none;
    margin-top: 10px;
    display: inline-block; }
  &__bottom {
    background: #E6E8EA; }
  &__bottom &__inner {
    position: relative;
    padding: 15px 0;
    align-items: center;
    @include m {
      padding: 30px 0;
      flex-direction: column-reverse; } }
  &__social {
    @include m {
      margin-bottom: 10px; } }
  &__copyright-text {
    margin-bottom: 5px;
    font-size: 12px;
    color: #666;
    @include m {
      text-align: center; }
    &:last-child {
      margin-bottom: 0; } } }

.title {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 15px;
  text-align: center;
  @include fm3;
  font-size: 30px;
  color: $black;
  @include m {
    margin-bottom: 33px;
    font-size: 24px; }
  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 104px;
    height: 6px;
    margin-left: -52px;
    background: url('/img/line-blue.png') repeat-x 0 0 / auto 100%; }
  &_left {
    text-align: left;
    &:before {
      left: 0;
      margin-left: 0; } }
  &_red {
    &:before {
      background: url('/img/line-red.png') repeat-x 0 0 / auto 100%; } }
  &_white {
    color: white;
    &:before {
      background: url('/img/line-white.png') repeat-x 0 0 / auto 100%; } }
  &_small {
    font-size: 18px;
    text-transform: uppercase;
    @include m {
      font-size: 18px; } }
  &_mb16 {
    margin-bottom: 16px;
    @include m {
      margin-bottom: 22px; } }
  &_mb24 {
    margin-bottom: 24px;
    @include m {
      margin-bottom: 16px; } }
  &_mb30 {
    margin-bottom: 30px; }
  &_mb60 {
    margin-bottom: 60px; } }

.info {
  max-width: 615px;
  margin: 0 auto 65px;
  text-align: center;
  font-size: 15px;
  line-height: (24/15);
  color: #666; }

.section {
  margin-bottom: 20px;
  padding: 50px 0 60px;
  background: #F8F8F8;
  &__info {
    margin-bottom: 65px;
    @include m {
      margin-bottom: 35px; }
    &_mb75 {
      margin-bottom: 75px;
      @include d {
        margin-bottom: 60px; } } }
  &__row {
    display: flex;
    margin: 0 -15px; }
  &__row_one {
    display: block; }
  &__row_four,
  &__row_three,
  &__row_six {
    flex-wrap: wrap; }
  &__row_four &__col {
    flex: 0 0 calc(25% - 30px);
    @include t {
      flex: 0 0 calc(33.33% - 30px); } }
  &__row_three &__col {
    flex: 0 0 calc(33.33% - 30px);
    @include t {
      flex: 0 0 calc(50% - 30px); }
    @include m {
      flex: 0 0 calc(100% - 30px); } }
  &__row_six &__col {
    flex: 0 0 calc(16.66% - 30px);
    justify-content: center;
    @include t {
      flex: 0 0 calc(25% - 30px); }
    @include m {
      flex: 0 0 calc(50% - 20px); } }
  &__row_tablet {
    @include t {
      width: calc(100% + 30px);
      margin: 0 -15px; }
    .owl-stage-outer {
      padding-bottom: 40px;
      @include m {
        padding-bottom: 30px; } }
    .owl-nav {
      text-align: center; } }
  &__row_owl {
    .owl-stage-outer {
      padding-bottom: 50px;
      @include m {
        padding-bottom: 30px; } }
    .owl-nav {
      text-align: center; }
    // .owl-stage
    //   display: flex
    // .owl-stage-outer
    //   padding-bottom: 50px
    //   +t
    //     padding-top: 40px
    // .owl-item
    //   display: flex
    // .owl-nav
    //   padding-bottom: 30px
 }    //   text-align: center
  // mobile
  &__row_owl[data-items-mobile] {
    @include m {
      width: calc(100% + 30px); } }
  &__row_owl[data-items-mobile] &__col {
    @include m {
      flex: 0 0 calc(100% - 30px); } }
  // mobile workers
  &__row_four[data-items-mobile] &__col {
    @include m {
      display: flex;
      height: 100%;
      margin: 0 5px;
      flex-direction: column;
      flex: 0 0 calc(100% - 10px); } }
  &__row_four[data-items-mobile] {
    .owl-stage {
      display: flex; }
    .owl-nav {
      margin-bottom: 30px; } }
  // tablet
  &__row_owl[data-items-tablet] {
    .owl-stage {
      display: flex; }
    @include t {
      width: calc(100% + 30px);
      margin: 0 -15px; } }
  &__row_owl[data-items-tablet] &__col {
    @include t {
      margin: 0 15px;
      flex: 0 0 calc(100% - 30px); } }
  // desktop
  &__row_owl[data-items-desktop] {
    display: block;
    width: calc(100% + 30px); }
  &__col {
    margin: 0 15px; }
  &__container {
    max-width: calc(70% - 30px);
    flex: 0 0 calc(70% - 30px);
    min-height: 1px;
    margin: 0 15px;
    @include t {
      max-width: calc(100% - 30px);
      flex: 0 0 calc(100% - 30px); }
    @include m {
      max-width: calc(100% - 30px);
      flex: 0 0 calc(100% - 30px); }
    &_w58 {
      max-width: calc(58% - 30px);
      flex: 0 0 calc(58% - 30px);
      @include d {
        max-width: calc(100% - 30px);
        flex: 0 0 calc(70% - 30px); }
      @include t {
        max-width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px); } }
    &_w74 {
      max-width: calc(74.3% - 30px);
      flex: 0 0 calc(74.3% - 30px);
      @include d {
        max-width: calc(70% - 30px);
        flex: 0 0 calc(70% - 30px); }
      @include t {
        max-width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px); }
      @include m {
        max-width: calc(100% - 30px);
        flex: 0 0 calc(100% - 30px); } } }
  &__aside {
    max-width: calc(30% - 30px);
    flex: 0 0 calc(30% - 30px);
    margin: 0 15px;
    @include t {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      max-width: 280px;
      margin: 0;
      transform: translateX(280px);
      transition: transform .4s;
      z-index: 99; } }
  &__scroll {
    @include t {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #fff;
      box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
      overflow: auto;
      z-index: 4; } }
  &__toggle {
    display: none;
    position: absolute;
    top: 250px;
    left: -40px;
    width: 40px;
    height: 50px;
    border-radius: 5px 0 0 5px;
    background: #fff;
    box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
    z-index: 2;
    @include t {
      display: block; }
    @include m {
      top: 50%; }
    &.active .icon {
      @include t {
        transform: rotate(180deg); } }
    .icon {
      display: inline-block;
      font-size: 13px;
      fill: $gray; } }
  &__overlay {
    @include t {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(black, .2); } }
  &__foot {
    padding-top: 20px;
    text-align: center;
    @include t {
      padding-top: 10px; }
    @include m {
      padding-top: 0; } }
  &__foot &__btn {
    min-width: 230px;
    @include m {
      min-width: 100%; } }
  &__owl {
    @include m {
      width: calc(100% + 30px);
      margin: 0 -15px; } }
  &__owl {
    .owl-stage-outer {
      margin-bottom: 15px;
      padding-bottom: 5px; }
    .owl-nav {
      text-align: center; } }
  &__article {
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07); }
  &_bg {
    background: #ECEFF1; }
  &_p0 {
    padding: 0; }
  &_pb30 {
    padding: 60px 0 30px;
    @include m {
      padding: 50px 0 30px; } }
  &_pb40 {
    padding: 50px 0 40px; }
  &_pb55 {
    padding: 50px 0 55px;
    @include m {
      padding: 50px 0 40px; } }
  &_p60 {
    padding: 60px 0; }
  &_def {
    margin-bottom: 40px;
    padding: 0;
    background: none; }
  &.visible &__aside {
    @include t {
      transform: translateX(0); } }
  &.visible &__overlay {
    @include t {
      display: block;
      z-index: 30; } } }

.action {
  display: block;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  transition: box-shadow .2s;
  &_col {
    text-align: center; }
  &_row {
    display: flex;
    margin-bottom: 23px;
    @include m {
      flex-direction: column;
      margin: 0 15px;
      // margin: 5px
      // border: 1px solid #f6f6f6
      // border-radius: 5px
 }      // box-shadow: 1px 1.7px 5px rgba(0, 0, 0, 0.07)
    &:last-child {
      margin-bottom: 0; } }
  &_col &__pic {
    border-radius: 5px 5px 0 0; }
  &_row &__preview {
    flex: 0 0 42.5%;
    @include d {
      flex: 0 0 32%; }
    @include m {
      flex: 0 0 auto; } }
  &_row &__pic {
    border-radius: 5px 0 0 5px;
    @include d {
      border-radius: 5px; }
    @include m {
      border-radius: 5px 5px 0 0; } }
  &_col &__info {
    padding: 28px 25px 55px;
    @include d {
      padding: 28px 25px 38px; }
    @include m {
      padding: 28px 15px 32px; } }
  &_row &__info {
    padding: 38px 40px 15px;
    text-align: left;
    @include d {
      padding: 30px 38px 40px; }
    @include t {
      padding: 34px 38px 40px; }
    @include m {
      padding: 26px 20px 30px 20px; } }
  &_row &__btn {
    @include d {
      min-width: 150px; }
    @include m {
      min-width: 100%;
      margin: 0 0 10px 0;
      &:last-child {
       margin: 0; } } }
  &_row &__icon {
    top: 25px;
    left: 0;
    @include m {
      top: -20px;
      left: 40px; } }
  &_col &__name {
    max-width: 270px;
    height: 46px;
    margin: 0 auto 15px;
    overflow: hidden; }
  &_row &__name {
    margin-bottom: 9px; }
  &_col &__text {
    height: 66px;
    margin-bottom: 48px;
    overflow: hidden;
    @include m {
      margin-bottom: 32px; } }
  &_row &__date {
    margin-bottom: 17px; }
  &_row &__text {
    margin-bottom: 25px;
    @include m {
      height: 66px;
      margin-bottom: 20px;
      overflow: hidden; } }
  &_col:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  &_col:hover &__btn {
    background-color: #ff5e6a; }
  &_simple {
    padding: 0 30px;
    background: none;
    border-radius: 0;
    box-shadow: none;
    @include d {
      padding: 0 20px; } }
  &_simple &__name {
    height: auto; }
  &_simple &__info {
    padding: 35px 0 0;
    @include d {
      padding: 35px 0 0; } }
  &_simple &__date {
    margin-bottom: 0; }
  &_simple &__pic {
    border-radius: 0; }
  &_simple:hover {
      box-shadow: none; }
  &_full {
    display: flex;
    @include m {
      display: block; } }
  &_full &__preview {
    flex: 0 0 50%;
    font-size: 0;
    @include t {
      flex: 0 0 39%; } }
  &_full &__pic {
    @include m {
      border-radius: 5px 5px 0 0; } }
  &_full &__info {
    padding: 74px 40px 40px 59px;
    flex: 0 0 50%;
    @include d {
      padding: 5px 15px 30px 38px; }
    @include t {
      flex: 0 0 61%;
      padding: 31px 15px 30px 33px; }
    @include m {
      padding: 20px 10px 35px; } }
  &_full &__bonus {
    display: flex;
    margin-bottom: 18px;
    align-items: center; }
  &_full &__icon {
    position: relative;
    top: auto;
    left: auto;
    margin: 0 15px 0 0; }
  &_full &__title {
    display: block;
    margin-bottom: 20px;
    @include t {
      line-height: 1.1; }
    @include m {
      line-height: 1.25; } }
  &_full &__date {
    margin-bottom: 43px;
    @include d {
      margin-bottom: 24px; } }
  &_full &__text {
    margin-bottom: 34px;
    @include d {
      margin-bottom: 22px; } }
  &_full &__text_mb48 {
    margin-bottom: 48px;
    @include d {
      margin-bottom: 34px; } }
  &_full &__name {
    margin-bottom: 10px;
    @include fm;
    font-size: 25px;
    color: $black;
    @include m {
      font-size: 18px; } }
  &_full &__btn {
    @include m {
      min-width: 100%; } }
  &__preview {
    position: relative; }
  &__pic {
    display: block;
    width: 100%; }
  &__info {
    position: relative; }
  &__bonus {
    margin-bottom: 15px; }
  &__bonus_gift &__icon {
    background: #FCC200; }
  &__bonus_gift &__label {
    color: #FCC200; }
  &__icon {
    display: flex;
    position: absolute;
    top: -20px;
    left: 50%;
    margin-left: -20px;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #ff8a65;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.25);
    .icon {
      fill: #ffede7; }
    .icon-sale {
      font-size: 16px; }
    .icon-gift {
      font-size: 21px; } }
  &__label {
    font-weight: 700;
    font-size: 12px;
    text-transform: uppercase;
    color: #ff8a65; }
  &__name {
    display: block;
    @include fm7;
    font-size: 18px; }
  &__date {
    margin-bottom: 15px;
    font-weight: 700;
    color: $gray;
    font-size: 12px;
    text-transform: uppercase; }
  &__text {
    line-height: 1.5; }
  &__btns &__btn {
    margin-right: 20px;
    &:last-child {
      margin-right: 0; } }
  &_nav {
    padding: 0 10px;
    box-shadow: none;
    transition: transform .2s;
    &:hover {
      box-shadow: none; } }
  &_nav &__pic {
    border-radius: 5px; }
  &_nav:hover &__info {
    background: $blue;
    transform: translateY(-42px);
    @include rmenu {
      transform: translateY(-60px); } }
  &_nav:hover &__more {
    visibility: visible; }
  &_nav:hover &__name {
    color: white; }
  &_nav &__info {
    padding: 28px 10px 15px;
    background: white;
    border-radius: 0 0 5px 5px;
    transition: background .2s, transform .2s; }
  &_nav &__name {
    @include f;
    font-size: 15px;
    color: $light-black;
    transition: color .2s; }
  &_nav &__more {
    min-width: 190px;
    visibility: hidden; } }

.owl-carousel {
  .owl-prev,
  .owl-next {
    width: 57px;
    height: 57px;
    margin: 0 5px;
    background-color: white;
    border: 1px solid $border;
    border-radius: 50%;
    transition: border-color .2s, opacity .2s;
    .icon {
      margin-top: 2px;
      font-size: 14px;
      fill: #666;
      transition: fill .2s; }
    &:hover {
      border-color: $blue;
      .icon {
        fill: $blue; } }
    &.disabled {
      border-color: $border;
      pointer-events: none;
      opacity: .5;
      .icon {
        fill: #666; } } } }

.owl-carousel_nav {
  height: 280px;
  .owl-prev {
    display: none; }
  .owl-next {
    position: absolute;
    right: -26px;
    top: 63px;
    width: 40px;
    height: 40px;
    margin: 0;
    border: none;
    background: none;
    .icon {
      fill: #7d8b99; }
    &:hover {
      border: none; } } }

.owl-carousel_portfolio {
  .owl-item {
    transition: opacity .2s;
    @include rmin(1200) {
      opacity: .2; }
    &.center {
      opacity: 1; } }
  .owl-nav {
    padding: 50px 0;
    background: white;
    text-align: center;
    @include d {
      padding: 40px 0 50px; }
    @include m {
      padding: 20px 0 50px; } }
  .center {
    max-width: 1170px;
    padding: 0; } }

.talk {
  &__item {
    margin-bottom: 20px;
    padding: 28px 15px 30px 35px;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    @include m {
      padding: 16px 10px 30px 20px; }
    &:last-child {
      margin-bottom: 0; } }
  &__meta {
    margin-bottom: 14px; }
  &__question {
    margin-bottom: 4px;
    @include fm3;
    font-size: 18px;
    line-height: 1.55;
    @include m {
      font-size: 13px;
      line-height: 1.7; } }
  &__link {
    display: inline-flex;
    align-items: center; }
  &__link.active &__link-icon {
    .icon {
      transform: rotate(180deg); } }
  &__link:hover &__link-text {
    text-decoration: none; }
  &__link-icon {
    margin-right: 10px;
    font-size: 0;
    .icon {
      font-size: 5px;
      fill: $blue; } }
  &__link-text {
    text-decoration: underline;
    color: $blue; }
  &__answer {
    height: 0;
    overflow: hidden; }
  &__in {
    display: flex;
    padding-top: 27px;
    @include m {
      display: block; } }
  &__wrap {
    padding-left: 20px;
    @include m {
      padding-left: 0; } }
  &__user {
    flex: 0 0 150px;
    text-align: center;
    @include m {
      display: flex;
      flex: 0 0 100%;
      margin-bottom: 15px; } }
  &__preview {
    width: 102px;
    margin: 0 auto 10px;
    font-size: 0;
    @include m {
      flex: 0 0 50px;
      margin: 0 15px 0 0;
      padding-top: 5px; } }
  &__box {
    @include m {
      text-align: left; } }
  &__pic {
    max-width: 100%;
    border-radius: 50%; }
  &__name {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 15px;
    line-height: (22/15); }
  &__post {
    height: 15px;
    overflow: hidden;
    font-size: 12px;
    color: $gray; }
  &__text {
    font-size: 15px;
    line-height: (24/15);
    @include m {
      font-size: 13px;
      line-height: 1.7; } }
  &__head {
    display: flex;
    border-bottom: 1px solid #e6e8ea;
    padding: 18px 34px 21px;
    align-items: flex-end;
    @include m {
      padding: 19px 10px;
      flex-direction: column;
      align-items: flex-start; } }
  &__head &__meta {
    padding-bottom: 3px;
    @include m {
      padding-bottom: 0; } }
  &__title {
    margin-right: 25px;
    @include fm;
    font-size: 25px;
    line-height: 1;
    color: $black;
    @include m {
      margin-bottom: 10px;
      font-size: 18px; } }
  &__body {
    display: flex;
    padding: 10px 34px;
    border-bottom: 1px solid #e6e8ea;
    @include m {
      display: block;
      padding: 15px 10px; } }
  &__info {
    display: flex;
    margin-bottom: 19px;
    @include m {
      flex-direction: column;
      margin-bottom: 10px; } }
  &__info &__name {
    margin: 0 15px 0 0; }
  &__col {
    padding-right: 10px;
    @include m {
      display: flex;
      padding-right: 0;
      flex-direction: row; }
    &:first-child {
      flex: 0 0 40%;
      @include d {
        flex: 0 0 35%; }
      @include m {
        flex: 0 0 100%;
        margin-bottom: 17px; } }
    &:nth-child(2) {
      flex: 0 0 33%;
      @include d {
        flex: 0 0 41%; }
      @include m {
        flex: 0 0 100%;
        margin-bottom: 19px; } }
    &:last-child {
      flex: 0 0 27%;
      @include d {
        flex: 0 0 24%;
        align-self: center; }
      @include m {
        flex: 0 0 100%;
        align-self: flex-start; } } }
  &__services {
    margin-bottom: 5px;
    font-size: 12px;
    color: $gray;
    @include m {
      margin: 0 20px 0 0; } }
  &__foot {
    padding: 17px 15px 35px 34px;
    @include m {
      padding: 25px 10px 30px; } }
  &_review &__item {
    padding: 0; }
  &_review &__meta {
    margin: 0; }
  &_review &__name {
    margin-bottom: 0;
    line-height: 1;
    color: $black;
    @include d {
      line-height: 1.2; } }
  &_review &__wrap &__name {
    @include m {
      max-width: 220px;
      min-height: 40px;
      margin: 0 0 16px 0;
      padding-left: 60px;
      font-size: 13px;
      line-height: 1.6; } }
  &_review &__in {
    padding-top: 32px;
    @include m; }
  &_review &__wrap {
    padding-left: 28px;
    @include m {
      margin-top: -45px;
      padding-left: 0; } }
  &_review &__answer {
    height: auto;
    overflow: inherit; }
  &_review &__question {
    height: 81px;
    overflow: hidden;
    @include d {
      height: 113px; } }
  &_review &__user {
    flex: 0 0 82px;
    @include m {
      flex: 0 0 50px;
      width: 50px;
      margin: 0 10px 0 0; } }
  &_review &__preview {
    width: 100%;
    margin: 0;
    @include m {
      padding: 0; } } }

.line-clamp-1,
.line-clamp-2,
.line-clamp-3,
.line-clamp-4 {
  display: -webkit-box;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  /* autoprefixer: on */
  text-overflow: ellipsis; }

.line-clamp-1 {
  -webkit-line-clamp: 1; }

.line-clamp-2 {
  -webkit-line-clamp: 2; }

.line-clamp-3 {
  -webkit-line-clamp: 3; }

.line-clamp-4 {
  -webkit-line-clamp: 4; }

.filter {
  position: relative;
  @include m {
    height: 60px;
    margin-bottom: 10px; }
  &__toggle {
    display: none;
    width: 100%;
    padding: 10px 50px;
    @include m {
      display: block; } }
  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 5px 12px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    box-shadow: 2.5px 4.3px 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    text-align: center;
    @include fm7;
    font-size: 12px;
    text-transform: uppercase;
    line-height: 1;
    color: $light-black;
    .icon {
      margin-left: 10px;
      fill: $light-black; }
    .icon-arrow-down {
      font-size: 6px; }
    .icon-close {
      display: none;
      font-size: 10px; } }
  &__overlay {
    @include m {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(black, .2); } }
  &__body {
    display: flex;
    margin-bottom: 20px;
    padding-left: 20px;
    align-items: flex-end;
    @include d {
      padding-left: 0; }
    @include m {
      display: none;
      padding: 10px;
      flex-direction: column;
      border-radius: 0 0 5px 5px;
      // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1)
 } }      // background-color: #f8f8f8
  &__select {
    flex: 0 0 calc(50% - 130px);
    margin: 0 15px;
    width: 100%;
    @include d {
      margin: 0 10px; }
    @include m {
      margin: 0; }
    &:first-child {
      @include d {
        flex: 0 0 calc(45% - 110px); }
      @include m {
        flex: 0 0 100%; } }
    &:nth-child(2) {
      @include d {
        flex: 0 0 calc(55% - 110px); }
      @include m {
        flex: 0 0 100%; } } }
  &__btns {
    @include m {
      width: 100%;
      margin-top: 20px; } }
  &__show,
  &__close {
    margin-left: 15px; }
  &__show {
    min-width: 130px;
    @include d {
      min-width: 110px; }
    @include m {
      min-width: calc(100% - 55px);
      margin: 0; } }
  &.active &__head {
    @include m {
      border-radius: 5px 5px 0 0;
      background: #f8f8f8; } }
  &.active &__title {
    @include m {
      background-color: #eceff1;
      box-shadow: none;
      .icon-arrow-down {
        display: none; }
      .icon-close {
        display: block; } } }
  &.active &__container {
    @include m {
      position: relative;
      border-radius: 5px;
      background-color: #f8f8f8;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      z-index: 30; } }
  &.active &__body {
    @include m {
      display: block; } }
  &.active &__overlay {
    display: block;
    z-index: 20; } }

.meta {
  display: flex;
  align-items: center;
  &__item {
    display: flex;
    margin-right: 20px;
    align-items: center;
    &:last-child {
      margin-right: 0; }
    &_pic {
      @include m {
        posab {} } } }
  &__wrap,
  &__inner {
    display: flex;
    align-items: center; }
  &__icon {
    margin-right: 7px;
    font-size: 0;
    .icon {
      font-size: 11px;
      fill: $gray; }
    .icon-eye {
      font-size: 9px; } }
  &__text {
    display: block;
    font-size: 12px;
    color: $gray; }
  &__preview {
    margin-right: 15px; }
  &__ava {
    width: 30px; }
  &__pic {
    max-width: 100%;
    border-radius: 50%; }
  &_row {
    @include m {
      flex-direction: column;
      align-items: flex-start; } }
  &_row &__wrap {
    @include m {
      margin-top: 10px; } } }

.banner {
  display: flex;
  padding: 53px 45px 50px;
  border-radius: 5px;
  background-color: $blue;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  @include m {
    flex-direction: column;
    padding: 20px 20px 30px; }
  &__preview {
    flex: 0 0 124px;
    font-size: 0;
    @include m {
      margin: 0 auto 18px; } }
  &__photo {
    max-width: 100%;
    border-radius: 50%; }
  &__info {
    padding-left: 47px;
    @include m {
      padding-left: 0; } }
  &__name {
    margin-bottom: 8px;
    @include fm3;
    font-size: 25px;
    line-height: 1;
    color: white;
    @include m {
      text-align: center;
      font-size: 18px; } }
  &__post {
    margin-bottom: 30px;
    font-size: 12px;
    color: white;
    opacity: .5;
    @include m {
      margin-bottom: 20px;
      text-align: center; } }
  &__btn {
    margin-right: 20px;
    @include m {
      min-width: 100%;
      margin: 0 0 10px 0; }
    &:last-child {
      margin-right: 0;
      @include m {
         margin: 0; } } }
  .btn_white {
    min-width: 190px;
    @include m {
      min-width: 100%; } } }

.widget {
  padding: 35px 30px 40px;
  background: #f8f8f8;
  @include d {
    padding: 35px 20px 40px; }
  @include t {
    position: relative;
    background: #FFF; }
  &__head {
    display: flex;
    margin-bottom: 36px;
    justify-content: space-between; }
  &__wrap {
    position: relative;
    z-index: 10; }
  &__title {
    color: #232323; }
  &__phone {
    margin-bottom: 25px; }
  &__address {
    margin-bottom: 30px; }
  &__head &__title {
    margin-bottom: 0; }
  &__tags {
    margin-right: -10px; }
  &__category {
    @include m {
      margin-left: -20px; } }
  &__owl {
    width: calc(100% + 60px);
    margin: 0 -30px;
    @include d {
      width: calc(100% + 40px);
      margin: 0 -20px; }
    .owl-nav {
      padding-top: 30px;
      text-align: center; } }
  &_blue {
    margin: 0 30px;
    padding: 35px 30px 45px;
    border-radius: 5px;
    background: $blue;
    @include d {
      margin-right: 15px;
      margin-left: 15px; }
    @include t {
      margin-left: 0;
      margin-right: 0;
      border-radius: 0;
      background: $blue; } }
  &_blue &__title {
    color: white; }
  &_mb80 {
    margin-bottom: 80px;
    @include t {
      margin-bottom: 0; } } }

.box {
  padding: 35px 25px 45px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background: white;
  @include m {
    flex: 0 0 calc(100% - 30px);
    margin-bottom: 20px;
    padding: 23px 25px 30px; }
  .icon-email {
    fill: $light-black; }
  .address {
    margin-bottom: 37px; }
  .schedule {
    margin-bottom: 47px; }
  &__title {
    @include fm;
    margin-bottom: 21px;
    font-size: 25px;
    text-transform: none;
    @include m {
      margin-bottom: 27px;
      font-size: 25px; } }
  &_wide.box {
    @include t {
      flex: 0 0 calc(100% - 30px);
      margin-top: 30px; }
    @include m {
      margin: 0 15px; } } }

.doctors {
  &__item {
    display: flex;
    margin-bottom: 24px;
    @include d {
      margin-bottom: 30px; }
    &:last-child {
      margin-bottom: 0; } }
  &__preview {
    flex: 0 0 70px;
    padding-top: 7px;
    @include d {
      flex: 0 0 60px; } }
  &__pic {
    display: block;
    max-width: 100%;
    border-radius: 50%; }
  &__info {
    padding-left: 30px;
    @include d {
      padding-left: 20px; } }
  &__name {
    margin-bottom: 12px;
    font-weight: 700;
    line-height: (22/15); }
  &__post {
    font-size: 12px;
    color: #666; } }

.worker {
  position: relative;
  margin-bottom: 30px;
  border-radius: 5px;
  background: #FFF;
  box-shadow: inset 0 0 0 1px #f6f6f6, 0 2px 5px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  z-index: 2;
  @include t {
    &:nth-child(n+7) {
      display: none; } }
  @include m {
    display: flex;
    // margin: 0 5px
    flex-direction: column;
    &:nth-child(n+7) {
      display: block; } }
  &:hover {
    box-shadow: none;
    z-index: 4; }
  &:hover &__btns {
    display: flex;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    @include m {
      border: none; } }
  &__wrap {
    position: relative;
    flex: 1 0 auto;
    z-index: 4; }
  &__ava {
    font-size: 0; }
  &__photo {
    width: 100%;
    border-radius: 5px 5px 0 0; }
  &__info {
    padding: 25px 20px 25px;
    @include m {
      padding: 20px 10px 15px; } }
  &__name {
    margin-bottom: 15px;
    font-weight: 700;
    font-size: 18px;
    @include m {
      font-size: 13px;
      line-height: 1.4; } }
  &__post {
    font-size: 12px;
    color: $gray; }
  &__btns {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: -135px;
    padding: 0 20px 35px;
    justify-content: flex-end;
    flex-direction: column;
    background: white;
    z-index: 2;
    @include m {
      display: flex;
      position: relative;
      top: auto;
      bottom: auto;
      padding: 0 10px 15px; } }
  &__btns &__btn {
    min-width: 100%;
    margin-bottom: 17px;
    @include m {
      margin-bottom: 10px;
      padding: 8px 5px 7px; }
    &:last-child {
      margin-bottom: 0;
      @include m {
        margin-bottom: 0; } } } }

.disease {
  margin: 0 15px 30px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background-color: white;
  &:nth-child(n+5) {
    @include t {
      display: none; } }
  &:nth-child(n+3) {
    @include m {
      display: none; } }
  &__preview {
    font-size: 0;
    @include m {
      display: none; } }
  &__img {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0 0; }
  &__category {
    min-height: 50px;
    margin-bottom: 20px;
    overflow: hidden;
    @include fm7;
    font-size: 18px;
    line-height: (26/18);
    color: $black;
    @include m {
      min-height: auto;
      margin-bottom: 15px; } }
  &__info {
    padding: 25px 30px 43px;
    @include d {
      padding: 25px 20px 40px; }
    @include m {
      padding: 25px 20px 30px; } }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
  &__name {
    margin-bottom: 20px;
    text-decoration: underline;
    &:last-child {
      margin-bottom: 0; }
    &:hover {
      text-decoration: none; } } }

.article {
  display: block;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background-color: white;
  transition: box-shadow .2s;
  &__preview {
    font-size: 0; }
  &__img {
    width: 100%;
    border-radius: 5px 5px 0 0; }
  &__wrap {
    padding: 25px 30px 40px; }
  &__title {
    display: block;
    min-height: 50px;
    margin-bottom: 13px;
    @include fm7;
    font-size: 18px;
    line-height: (26/18);
    color: $black;
    &_mb30 {
      margin-bottom: 30px; } }
  &__text {
    margin-bottom: 23px;
    line-height: 1.6;
    transition: color .2s; }
  &__btn {
    display: inline-block;
    min-width: 230px;
    margin: 0 auto; }
  &__head {
    display: flex;
    margin-bottom: 10px;
    @include m {
      margin-bottom: 5px;
      flex-direction: column; } }
  &_small {
    display: flex;
    flex-direction: column;
    @include t {
      height: 100%; } }
  &_small &__title {
    min-height: auto;
    @include t {
      margin-bottom: 20px; }
    @include m {
      margin-bottom: 10px;
      font-size: 15px; } }
  &_small &__text {
    height: 96px;
    overflow: hidden;
    @include m {
      height: 80px;
      font-size: 13px; } }
  &_small &__btn {
    @include m {
      min-width: 100%; } }
  &_small &__wrap {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding: 25px 20px 40px;
    @include m {
      padding: 25px 10px 30px; } }
  &_small &__btns {
    display: flex;
    flex: 1 0 auto;
    align-items: flex-end;
    text-align: center; }
  &_small:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1); }
  &_small:hover &__title {
    color: $blue; }
  &_small:hover &__btn {
    background-color: #ff5e6a; }
  &_big {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0; } }
  &_big &__wrap {
    padding: 25px 50px 45px;
    @include d {
      padding: 18px 30px 45px; }
    @include m {
      padding: 17px 10px 30px; } }
  &_big &__meta {
    margin-right: 25px;
    @include m {
      margin: 0 0 20px 0;
      flex-direction: column;
      align-items: flex-start; } }
  &_big &__meta .meta__wrap {
    @include m {
      margin-top: 8px; } }
  &_big &__title {
    display: block;
    min-height: auto;
    margin-bottom: 20px;
    @include fm;
    font-size: 25px;
    color: #232323;
    @include d {
      line-height: 1.3; }
    @include m {
      margin-bottom: 15px;
      font-size: 15px;
      line-height: 1.45; } }
  &_big &__text {
    margin-bottom: 35px;
    @include m {
      margin-bottom: 25px;
      font-size: 13px; } }
  &_big &__btn {
    margin: 0;
    @include m {
      min-width: 100%; } } }

.preview {
  &__pic {
    position: relative;
    font-size: 0; }
  &__img {
    width: 100%;
    border-radius: 5px 5px 0 0; }
  &__counter {
    position: absolute;
    right: 50px;
    bottom: -26px;
    @include t {
      right: 70px; }
    @include m {
      right: 32px;
      bottom: -15px; } }
  &__wrap {
    padding: 23px 50px 16px 53px;
    @include d {
      padding: 20px 25px 16px 20px; }
    @include m {
      padding: 20px 10px 20px; } }
  &__rating {
    margin-bottom: 3px; }
  &__title {
    margin-bottom: 20px;
    @include fm;
    font-size: 25px;
    color: #232323;
    @include m {
      font-size: 15px; } }
  &__meta {
    @include m {
      align-items: center; } }
  &__meta .meta__wrap {
    @include m {
      flex-direction: column;
      align-items: flex-start; } }
  &__meta .meta__item {
    @include m {
      margin-right: 10px;
      &:last-child {
        margin-right: 0; } } }
  &__meta .meta__wrap > .meta__item {
    @include m {
      margin: 0 0 5px 0; } } }

.share {
  display: flex;
  padding: 25px 50px 40px 53px;
  border-top: 1px solid #e6e8ea;
  align-items: center;
  @include d {
    padding: 25px 50px 30px 53px; }
  @include m {
    flex-direction: column;
    padding: 20px 10px 30px 10px;
    align-items: flex-start; }
  &__text {
    margin-right: 30px;
    @include fm;
    font-size: 25px;
    line-height: (36/25);
    color: #232323;
    @include m {
      margin: 0 0 10px 0;
      font-size: 18px; } }
  &__social {
    flex-direction: row; } }

.counter {
  display: flex;
  flex-direction: column;
  &__item {
    position: relative;
    width: 55px;
    height: 55px;
    margin-bottom: 13px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    font-size: 0;
    @include m {
      width: 34px;
      height: 34px;
      margin-bottom: 10px; }
    .icon {
      font-size: 16px;
      fill: white; }
    &:last-child {
      margin-bottom: 0; }
    &_like {
      background: $pink; }
    &_review {
      background: #43b9f6; } }
  &__item_like &__number {
    border: 2px solid $pink;
    color: $pink; }
  &__item_review &__number {
    border: 2px solid #43b9f6;
    color: #43b9f6; }
  &__number {
    position: absolute;
    top: 15px;
    right: -19px;
    width: 29px;
    height: 29px;
    padding-top: 6px;
    border-radius: 50%;
    background-color: white;
    text-align: center;
    @include fr;
    font-size: 10px;
    @include m {
      top: 5px;
      width: 24px;
      height: 24px;
      padding-top: 4px; } } }

.content {
  padding: 10px 50px 5px 53px;
  @include clr;
  @include d {
    padding: 10px 25px 5px 20px; }
  @include m {
    padding: 0px 10px 5px 10px; }
  p {
    margin-bottom: 25px;
    font-size: 15px;
    line-height: (24/15);
    color: $light-black;
    @include m {
      margin-bottom: 20px;
      font-size: 13px;
      line-height: 1.7; } }
  blockquote {
    max-width: 600px;
    margin: 0 auto 42px;
    padding: 26px 26px 30px 31px;
    border-left: 10px solid $blue;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), 0 0 0 1px #f6f6f6;
    background-color: white;
    @include d {
      max-width: 570px; }
    @include m {
      max-width: 100%;
      margin: 0 0 34px 0;
      padding: 15px 10px 22px 17px; }
    p {
      margin-bottom: 4px;
      font-style: italic;
      font-size: 18px;
      color: $blue;
      @include m {
        margin-bottom: 7px;
        font-size: 15px; } }
    footer {
      color: #232323; } }
  * + blockquote {
    margin-top: 45px;
    @include m {
      margin-top: 35px; } }
  figure {
    text-align: center;
    img {
      max-width: 100%;
      font-size: 0; } }
  figure[align="left"] {
    float: left;
    margin: 0 50px 35px 0;
    @include d {
      max-width: 35%;
      margin: 0 30px 35px 0; }
    @include m {
      float: none;
      max-width: 100%;
      margin: 0 0 22px 0;
      text-align: center; } }
  figure[align="right"] {
    float: right;
    margin: 0 0 35px 50px;
    @include d {
      max-width: 35%;
      margin: 0 0 35px 30px; }
    @include m {
      float: none;
      max-width: 100%;
      margin: 0 0 22px 0;
      text-align: center; } }
  * + figure,
  * + figure[align="left"],
  * + figure[align="right"] {
    margin-top: 7px;
    @include d {
      margin-top: 7px; }
    @include m {
      margin-top: 13px; } }
  ul {
    margin-bottom: 55px;
    overflow: hidden;
    @include d {
      margin-bottom: 35px; }
    @include m {
      margin-bottom: 20px; }
    li {
      position: relative;
      margin-bottom: 10px;
      padding-left: 27px;
      @include m {
        font-size: 13px; }
      &:before {
        content: "";
        position: absolute;
        top: 4px;
        left: 0;
        width: 13px;
        height: 9px;
        background: url('/img/check.png') no-repeat 50% 50% / 100% 100%; }
      &:last-child {
        margin-bottom: 0; } } }
  * + ul {
    margin-top: 50px;
    @include d {
      margin-top: 35px; }
    @include m {
      margin-top: 15px; } }
  * + h1,
  * + h2,
  * + h3 {
    margin-top: 50px;
    @include d {
      margin-top: 40px; }
    @include m {
      margin-top: 30px; } }
  h1,
  h2,
  h3 {
    margin-bottom: 60px;
    @include d {
      margin-bottom: 20px; } }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.3;
    font-weight: 400;
    color: $black; }
  h1 {
    @include fm7;
    font-size: 40px;
    line-height: (50/40); }
  h2 {
    @include fm3;
    font-size: 30px;
    line-height: (40/30); }
  h3 {
    @include fm;
    font-size: 25px;
    line-height: (36/25);
    text-align: center;
    @include m {
      font-size: 20px; } }
  h4 {
    @include fm;
    font-size: 18px;
    text-transform: uppercase; }
  h5 {
    @include fm7;
    font-size: 18px; }
  h6 {
    font-weight: 700;
    font-size: 15px;
    line-height: (24/15); } }

.doctor {
  &__bg {
    padding: 20px 0 17px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include t {
      margin-bottom: 45px;
      padding: 20px 0 25px; } }
  &__breadcrumbs {
    margin-bottom: 89px;
    @include t {
      margin-bottom: 25px; }
    @include m {
      margin-bottom: 40px; } }
  &__wrap {
    padding-left: 320px;
    @include d {
      padding-left: 295px; }
    @include t {
      padding-left: 0; }
    @include m {
      padding-left: 105px; } }
  &__name {
    margin-bottom: 2px;
    @include fm7;
    font-size: 40px;
    color: white;
    @include m {
      margin-bottom: 12px;
      font-size: 24px; } }
  &__inner {
    margin-top: -100px;
    @include t {
      margin-top: 0; }
    @include m {
      margin-top: -155px; } }
  &__inner &__center {
    display: flex;
    @include m {
      display: block; } }
  &__aside {
    flex: 0 0 270px;
    @include d {
      flex: 0 0 245px; }
    @include t {
      flex: 0 0 220px; } }
  &__preview {
    width: 100%;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    text-align: center;
    @include m {
      margin-bottom: 25px;
      text-align: left;
      border: none;
      background: none;
      box-shadow: none; } }
  &__ava {
    font-size: 0;
    @include m {
      margin-bottom: 35px; } }
  &__pic {
    max-width: 100%;
    border-radius: 5px 5px 0 0;
    @include m {
      width: 95px;
      border-radius: 5px; } }
  &__action {
    padding: 20px 0;
    @include t {
      padding: 20px 0 30px; }
    @include m {
      padding: 0; } }
  &__btn {
    min-width: 230px;
    @include d {
      min-width: 200px; }
    @include t {
      min-width: 180px; }
    @include m {
      min-width: 100%;
      padding: 19px 20px 17px; } }
  &__container {
    padding: 143px 0 65px 50px;
    @include t {
      padding: 0 0 65px 30px; }
    @include m {
      padding: 0 0 70px 0; } }
  &__title {
    margin-bottom: 22px;
    @include fm;
    font-size: 25px;
    @include m {
      margin-bottom: 20px; } }
  &__info {
    margin-bottom: 45px;
    line-height: 1.6;
    @include t {
      margin-bottom: 30px; }
    @include m {
      font-size: 13px;
      line-height: 1.7; }
    p {
      margin-bottom: 25px;
      @include t {
        margin-bottom: 15px; }
      &:last-child {
        margin-bottom: 0; } } }
  &__feature {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    @include m {
      flex-direction: column; } }
  &__item {
    display: flex;
    align-items: center;
    @include t {
      flex-direction: column;
      align-items: flex-start; }
    @include m {
      margin-bottom: 20px;
      flex-direction: row;
      align-items: center;
      &:last-child {
        margin-bottom: 0; } } }
  &__item:nth-child(2) {
    padding: 0 10px;
    @include m {
      padding: 0; } }
  &__icon {
    display: flex;
    width: 55px;
    height: 55px;
    margin-right: 20px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
    @include t {
      margin: 0 0 15px 0; }
    @include m {
      margin: 0 15px 0 0; }
    .icon {
      font-size: 28px;
      fill: #f8f8f8; }
    &_experience {
      background-color: #43b9f6; }
    &_category {
      background-color: $blue; }
    &_stage {
      background-color: $pink; } }
  &__text {
    font-weight: 700;
    color: $black; } }

.breadcrumbs {
  &__wrap {
    display: flex; }
  &__title {
    margin-bottom: 10px;
    @include fm;
    font-size: 40px;
    line-height: 1;
    color: white; }
  &__item {
    position: relative;
    margin-right: 10px;
    padding-right: 19px;
    color: #eceff1;
    transition: color .2s;
    &:before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 4px;
      width: 5px;
      height: 9px;
      background: url('/icons/arrow-right-breadcrumbs.svg') no-repeat 50% 50% / 100% 100%; }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      opacity: .5;
      &:before {
        display: none; }
      &:hover {
        color: #eceff1; } } }
  &__item:hover {
      color: $blue; }
  &_bg {
    display: flex;
    min-height: 220px;
    background: url('/img/bg-breadcrumbs.jpg') no-repeat 50% 50% / cover;
    align-items: center;
    @include m {
      min-height: 100px; } }
  &_bg &__center {
    width: 100%; } }


.services {
  display: flex;
  min-height: 90px;
  margin-bottom: 30px;
  padding: 15px 35px 15px 25px;
  align-items: center;
  position: relative;
  background: white;
  transition: box-shadow .2s;
  @include m {
    min-height: 40px;
    margin-bottom: 10px;
    padding: 5px 25px 5px 0; }
  &__icon {
    flex: 0 0 65px;
    text-align: center;
    font-size: 0;
    @include m {
      flex: 0 0 52px; }
    .icon {
      font-size: 42px;
      fill: $gray;
      transition: fill .2s;
      @include m {
        font-size: 22px; } }
    .icon-analyse-adult,
    .icon-analyse-child {
      font-size: 44px;
      @include m {
        font-size: 24px; } }
    .icon-doctor-adult,
    .icon-doctor-child {
      font-size: 47px;
      @include m {
        font-size: 25px; } }
    .icon-procedural,
    .icon-uzi-child,
    .icon-uzi-adult {
      font-size: 35px;
      @include m {
        font-size: 19px; } } }
  &__text {
    padding-left: 15px;
    font-size: 15px;
    line-height: (24/15);
    color: $black;
    @include m {
      padding-left: 0;
      font-size: 13px; } }
  &__arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -2px;
    font-size: 0;
    @include m {
      right: 15px; }
    .icon {
      font-size: 8px;
      fill: $blue; } }
  &:hover {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
  &:hover &__icon {
    .icon {
      fill: $blue; } } }

.abc {
  &__item {
    display: flex;
    margin-bottom: 50px;
    break-inside: avoid-column;
    overflow: hidden;
    &:last-child {
      margin-bottom: 0; } }
  &__letter {
    flex: 0 0 45px;
    @include fm7;
    font-size: 30px;
    line-height: 1;
    color: $black; }
  &__list {
    display: flex;
    padding: 8px 0 0 0;
    flex-direction: column;
    align-items: flex-start; }
  &__link {
    margin-bottom: 18px;
    @include fm3;
    font-size: 18px;
    line-height: (26/18);
    text-decoration: underline;
    &:last-child {
      margin-bottom: 0; }
    &:hover {
      text-decoration: none; } }
  &_one &__list {
    margin-top: -5px;
    padding: 0; }
  &_one &__link {
    margin-bottom: 12px;
    font-size: 16px; }
  &_one &__item {
    margin-bottom: 24px; }
  &_two {
    padding: 0 45px;
    column-count: 2;
    column-gap: 90px;
    @include d {
      padding: 0;
      column-gap: 10px; }
    @include m {
      column-count: auto;
      column-gap: 0; } }
  &_two &__item {
    margin-bottom: 20px;
    @include d {
      margin-bottom: 30px; } }
  &_two &__letter {
    flex: 0 0 55px;
    font-size: 40px; }
  &_two &__list {
    padding: 0; }
  &_two &__link {
    margin-bottom: 17px; }
  &_three {
    padding-left: 60px;
    column-count: 3;
    column-gap: 80px;
    @include d {
      padding-left: 15px;
      column-gap: 25px; }
    @include t {
      column-count: 2;
      padding-left: 0;
      column-gap: 25px; }
    @include m {
      column-count: auto;
      column-gap: 0; } } }

.reception {
  padding: 55px 0;
  background: $blue;
  @include m {
    padding: 35px 0 45px; }
  &__title {
    margin-bottom: 40px;
    text-align: center;
    @include fm3;
    font-size: 30px;
    color: #f8f8f8;
    @include m {
      margin-bottom: 35px;
      font-size: 24px; } }
  &__wrap {
    display: flex;
    margin: 0 -15px 50px;
    @include m {
      display: block;
      margin: 0;
      padding: 0 25px; } }
  &__field {
    flex: 0 0 calc(50% - 30px);
    margin: 0 15px;
    @include m {
      margin: 0 0 15px 0; } }
  &__btns {
    text-align: center;
    @include m {
      margin-top: 18px; } }
  &__btn {
    min-width: 230px;
    @include m {
      min-width: 240px; } } }

.form {
  position: relative;
  // &__container
  //   opacity: .2
  &__wrap {
    margin-bottom: 40px; }
  &__field {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0; } }
  &__btns {
    text-align: center; }
  &__btn {
    min-width: 100%; }
  &__res {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
  &__text {
    margin-bottom: 10px;
    font-size: 18px; }
  &__state {
    .icon {
      font-size: 40px;
      &-success {
        fill: green; }
      &-error {
        fill: $red; } } }
  &_popup &__field {
    margin-bottom: 30px; }
  &_popup &__btns {
    margin-top: 50px; }
  &_popup &__btn {
    min-width: 230px; }
  &.loading {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      width: 50px;
      height: 50px;
      margin: -25px 0 0 -25px;
      border: 4px solid $border;
      border-radius: 50%;
      border-bottom-color: $blue;
      animation: loader 1s linear infinite;
      z-index: 10; } }
  &.loading &__container,
  &.done &__container,
  &.error &__container {
    opacity: .2; }
  &.done &__res,
  &.error &__res {
    display: flex; }
  &.done &__state {
    .icon-error {
      display: none; } }
  &.error &__state {
    .icon-success {
      display: none; } } }

@keyframes loader {
  0 {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.review {
  &__title {
    margin-bottom: 25px;
    font-size: 25px;
    color: #232323;
    @include m {
      margin-bottom: 25px; } }
  &__text {
    max-width: 460px;
    margin-bottom: 25px;
    line-height: 1.5;
    color: #232323;
    @include m {
      margin-bottom: 20px;
      font-size: 13px; }
    &_mb10 {
      margin-bottom: 10px; } }
  &__select {
    margin-bottom: 9px; }
  &__rating {
    margin: 25px 0; }
  &__field {
    margin-bottom: 15px; }
  &__btn {
    margin-top: 33px;
    @include m {
      margin-top: 25px; } }
  &__btn .btn {
    @include m {
      min-width: 100%; } } }

.question {
  padding: 55px 40px 55px 45px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  @include m {
    padding: 35px 10px 40px; }
  &__title {
    margin-bottom: 27px; }
  &__text {
    margin-bottom: 19px;
    @include m {
      font-size: 13px; } }
  &__field {
    margin-bottom: 35px;
    @include m {
      margin-bottom: 20px; }
    &_mb20 {
      margin-bottom: 20px;
      @include m {
        margin-bottom: 15px; } } }
  &__add {
    margin-bottom: 10px; }
  &__btns {
    margin-top: 28px; }
  &__btn {
    min-width: 230px;
    @include m {
      min-width: 100%; } } }

.add {
  display: flex;
  height: 40px;
  align-items: center;
  &__text {
    border-bottom: 1px dashed $blue;
    color: $blue; }
  &__field {
    display: none;
    width: 100%;
    .choices__inner {
      background: none; } } }

.certificates {
  display: flex;
  width: 168px;
  height: 168px;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.07);
  background-color: white;
  font-size: 0;
  @include m {
    width: 135px;
    height: 135px;
    margin: 0 10px 20px; }
  &__pic {
    width: auto!important;
    height: auto;
    max-width: 100%;
    max-height: 100%; } }

.owl-item {
  .certificates {
    width: auto;
    margin-bottom: 0; } }

.prices {
  &__head {
    margin-bottom: 30px;
    padding: 0 30px;
    @include d {
      padding: 0; } }
  &__item {
    margin-bottom: 20px;
    padding: 25px 30px 20px;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
    background-color: white;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.07);
    @include m {
      padding: 30px 10px 15px; }
    &:last-child {
      margin-bottom: 0; } }
  &__title {
    margin-bottom: 10px;
    @include fm;
    font-size: 25px;
    color: #232323;
    @include m {
      margin-bottom: 0;
      font-size: 15px; } }
  &__row {
    display: flex;
    padding: 22px 0;
    border-bottom: 1px solid $border;
    justify-content: space-between;
    @include m {
      padding: 13px 0;
      align-items: center;
      font-size: 13px; }
    &_head {
      font-weight: 700; }
    &:last-child {
      border-bottom: none; } }
  &__col:last-child {
    flex: 0 0 100px;
    text-align: right;
    @include m {
      flex: 0 0 55px; } }
  &__ruble {
    font-size: 16px;
    @include m {
      font-size: 14px; } }
  &__show {
    @include m {
      display: none; } }
  &__hide {
    display: none;
    @include m {
      display: block; } } }

.total {
  margin-bottom: 80px;
  @include t {
    position: relative;
    width: 280px;
    margin: 0;
    background: white;
    z-index: 40; }
  &__wrap {
    position: relative;
    padding: 0 30px;
    z-index: 6;
    @include d {
      padding: 0 15px; }
    @include t {
      padding: 35px 15px; } }
  &__head {
    margin-bottom: 35px;
    @include fm;
    font-size: 18px;
    text-transform: uppercase;
    color: $black;
    @include m {
      margin-bottom: 20px; } }
  &__list {
    margin-bottom: 17px;
    padding-bottom: 25px;
    border-bottom: 1px solid $border; }
  &__item {
    position: relative;
    margin-bottom: 15px;
    padding-right: 25px;
    line-height: 1.5;
    &:last-child {
      margin-bottom: 0; } }
  &__del {
    position: absolute;
    top: 0;
    right: -6px;
    padding: 6px;
    font-size: 0;
    &:hover {
      .icon {
        fill: $red; } }
    .icon {
      font-size: 12px;
      transition: fill .2s; } }
  &__foot {
    display: flex;
    margin-bottom: 38px;
    justify-content: space-between;
    font-weight: 700;
    color: $black;
    @include m {
      margin-bottom: 25px; } }
  &__money {
    padding-right: 10px; }
  &__btn {
    min-width: 100%; }
  &__ruble {
    font-size: 16px; } }

.quality {
  display: flex;
  margin-bottom: 40px;
  @include d {
    flex-direction: column; }
  @include m {
    flex-direction: row;
    margin-bottom: 25px; }
  &__icon {
    display: flex;
    flex: 0 0 90px;
    width: 90px;
    height: 90px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    @include d {
      margin-bottom: 17px; }
    @include m {
      flex: 0 0 60px;
      width: 60px;
      height: 60px;
      margin: 0 15px 0 0; }
    .icon {
      font-size: 43px;
      fill: white;
      @include m {
        font-size: 30px; } }
    .icon-work {
      font-size: 40px;
      @include m {
        margin-left: -3px; } }
    .icon-experience {
      font-size: 48px;
      @include m {
        font-size: 35px; } }
    .icon-doc-house {
      font-size: 60px;
      @include m {
        font-size: 42px; } }
    &_work {
      background: #43b9f6; }
    &_experience {
      background: #6686FF; }
    &_clients {
      background: #4bb3c3; }
    &_analyse {
      background: #85df89; }
    &_doc-house {
      background: #3a82e9; }
    &_deadline {
      background: #ff5e6b; } }
  &__info {
    padding-left: 30px;
    @include d {
      padding-left: 0; } }
  &__name {
    margin-bottom: 5px;
    @include fm7;
    font-size: 18px;
    color: $black;
    @include m {
      font-size: 15px; } }
  &__text {
    line-height: (24/15);
    @include m {
      font-size: 13px;
      line-height: 1.7; } } }

.tags {
  display: flex;
  flex-wrap: wrap;
  &__item {
    margin: 0 11px 13px 0;
    padding: 7px 9px;
    border: 1px solid $border;
    border-radius: 5px;
    background-color: white;
    color: #666;
    transition: border-color .2s, color .2s;
    &:hover {
      border: 1px solid $blue;
      color: $blue; } } }

.category {
  display: flex;
  flex-direction: column;
  &__item {
    margin-bottom: 2px;
    padding: 15px 15px 15px 23px;
    transition: background .2s, box-shadow .2s;
    color: #232323;
    &:hover {
      background-color: white;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05); }
    &.active {
      background-color: white;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05), inset 10px 0 0 $blue; } } }

.clinic {
  background: #ECEFF1;
  &__head {
    background-image: url('/img/clinic.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
    @include d {
      background-image: url('/img/clinic-1.jpg');
      background-position: 50% 0; }
    @include m {
      background-position: 68% 0; }
    @include s {
      background-image: url('/img/clinic-2.jpg'); } }
  &__head &__center {
    min-height: 675px;
    display: flex;
    @include t {
      min-height: 430px; }
    @include m {
      min-height: 320px; } }
  &__info {
    display: flex;
    flex-direction: column;
    flex: 0 0 52%;
    margin-left: auto;
    padding-bottom: 33px;
    justify-content: center;
    @include d {
      flex: 0 0 49%;
      padding-bottom: 70px; }
    @include t {
      flex: 0 0 49%;
      padding-bottom: 35px; }
    @include m {
      flex: 0 0 55%;
      padding-bottom: 26px; } }
  &__title {
    margin-bottom: 18px;
    @include fm7;
    font-size: 40px;
    color: $black;
    @include m {
      font-size: 26px; } }
  &__slogan {
    max-width: 500px;
    margin-bottom: 30px;
    @include fm7;
    color: $black;
    font-size: 18px;
    @include t {
      margin-bottom: 0; }
    @include m {
      font-size: 13px;
      line-height: 1.7; } }
  &__description {
    line-height: 1.6;
    @include t {
      display: none; } }
  &__foot &__center {
    margin-top: -60px;
    @include m {
      margin-top: -35px; } }
  &__wrap {
    display: flex;
    margin: 0 -15px;
    @include t {
      flex-wrap: wrap; }
    @include m {
      margin: 0; } }
  &__item {
    flex: 0 0 calc(25% - 30px);
    margin: 0 15px;
    padding: 10px 10px 20px;
    border-radius: 5px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    color: white;
    @include t {
      display: flex;
      min-height: 100px;
      flex-direction: row;
      flex: 0 0 calc(50% - 30px);
      margin-bottom: 30px;
      padding: 20px 10px 10px 15px;
      align-items: flex-start; }
    @include m {
      flex: 0 0 calc(50% - 5px);
      min-height: auto;
      margin: 0 2.5px 5px;
      padding: 10px 5px;
      align-items: center; }
    &_work {
      background: #43b9f6;
      .icon {
        font-size: 40px; } }
    &_experience {
      background: #6686ff;
      .icon {
        font-size: 49px; } }
    &_clients {
      background: #4bb3c3;
      .icon {
        font-size: 43px; } }
    &_analyse {
      background: #85df89;
      .icon {
        font-size: 45px; } } }
  &__icon {
    display: flex;
    min-height: 60px;
    margin-bottom: 13px;
    justify-content: center;
    align-items: flex-end;
    font-size: 0;
    @include t {
      margin: 0 15px 0 0;
      align-items: center; }
    @include m {
      flex: 0 0 45px;
      min-height: auto;
      margin: 0; }
    .icon {
      fill: white;
      @include m {
        font-size: 28px; } } }
  &__in {
    @include t {
      text-align: left; } }
  &__numbers {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    @include m {
      margin-bottom: 0;
      font-size: 13px; } }
  &__text {
    @include m {
      font-size: 13px; } } }

.pagination {
  text-align: center;
  &__list {
    display: inline-flex;
    margin: 0 auto;
    align-items: center;
    @include m {
      width: calc(100% + 30px);
      margin: 0 -15px; } }
  &__item,
  &__next,
  &__prev {
    display: flex;
    width: 55px;
    height: 55px;
    margin: 0 5px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    @include fr;
    font-size: 20px;
    line-height: 1;
    color: #232323;
    transition: color .2s, fill .2s;
    @include m {
      margin: 0; }
    .icon {
      font-size: 14px;
      fill: #232323; }
    &:hover {
      color: $blue;
      .icon {
        fill: $blue; } }
    &.active {
      background-color: $blue;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.07);
      color: white; } } }

.overview {
  display: flex;
  min-height: 600px;
  margin-bottom: 80px;
  justify-content: center;
  align-items: center;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @include t {
    min-height: 300px; }
  @include m {
    min-height: 150px; }
  &__icon {
    margin-bottom: 25px;
    text-align: center;
    font-size: 0;
    .icon {
      font-size: 60px;
      fill: white; } }
  &__text {
    @include fm;
    font-size: 20px;
    text-transform: uppercase;
    color: white;
    @include m {
      font-size: 15px; } }
  &:last-child {
    margin-bottom: 0; }
  &_tour {
    background-image: url('/img/bg-tour.jpg'); }
  &_video {
    background-image: url('/img/bg-video.jpg'); } }

.popup {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  min-width: 320px;
  background: rgba(black, .2);
  visibility: hidden;
  pointer-events: none;
  overflow: auto;
  z-index: 999;
  @include m {
    padding: 15px; }
  &.visible {
    pointer-events: all;
    visibility: visible; }
  &.animation &__wrap {
    opacity: 1;
    transform: scale(1); }
  &__wrap {
    position: relative;
    max-width: 770px;
    width: 100%;
    padding: 49px 30px 65px;
    margin: auto;
    opacity: 0;
    transform: scale(.8);
    transition: opacity .2s, transform .2s;
    border-radius: 5px;
    background-color: #f8f8f8;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    @include t {
      max-width: 600px;
      padding: 60px 15px 65px; } }
  &__title {
    margin-bottom: 20px;
    @include fm;
    font-size: 40px;
    line-height: (50/40);
    color: $black;
    @include m {
      margin-bottom: 15px;
      font-size: 30px; } }
  &__text {
    margin-bottom: 35px;
    line-height: (24/15);
    @include m {
      margin-bottom: 25px;
      font-size: 13px; } }
  &__form {
    display: inline-block;
    max-width: 370px;
    width: 100%;
    @include m {
      max-width: 250px; } }
  &__form .form__btn {
    @include m {
      min-width: 250px; } }
  &__form .form__btns {
    @include m {
      margin-top: 40px; } }
  &__btn {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    &:hover {
      .icon {
        fill: $black; } }
    .icon {
      margin-bottom: 4px;
      font-size: 12px;
      fill: $gray;
      transition: fill .2s; } } }

.choices {
  margin-bottom: 0;
  padding: 0;
  font-size: 15px;
  color: $light-black;
  &:hover .choices__inner {
    box-shadow: inset 0 -1px 0 0 $blue; }
  &[data-type*="select-one"]:hover:after {
    background-image: url('/icons/arrow-down-blue.svg'); }
  &[data-type*="select-one"].is-open:after {
    background-image: url('/icons/arrow-down-blue.svg'); }
  &.error {
    z-index: 2; }
  &.error .choices__inner {
    box-shadow: inset 0 -1px 0 0 $red; }
  .choices__inner {
    padding: 16px 0 13px 0;
    border: 0;
    box-shadow: inset 0 -1px 0 0 #e5e5e5;
    border-radius: 0;
    font-size: 15px; }
  .choices__list--dropdown {
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: $blue;
    border-radius: 0 0 5px 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    z-index: 20; }
  .choices__list {
    right: 0;
    background: white; }
  .choices__list--single {
    background: none; }
  .choices__list--dropdown .choices__item {
    padding: 12px 15px;
    font-size: 15px;
    &:hover {
      background: none;
      color: $blue; } }
  .choices__item--disabled {
    display: none; }
  .choices__list--dropdown .choices__item--selectable:after {
    display: none; }
  .choices__list--dropdown .choices__item--selectable.is-highlighted {
    background: none; }
  .choices__list--single {
    padding: 0; }
  &[data-type*="select-one"] .choices__input {
    padding: 12px 15px;
    font-size: 15px;
    color: $black; }
  &[data-type*="select-one"] .choices__inner {
    padding-bottom: 13px; }
  &[data-type*="select-one"]:after {
    width: 12px;
    height: 7px;
    margin-top: 1px;
    background-image: url('/icons/arrow-down.svg');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: auto auto;
    border-style: none;
    border-color: transparent;
    border-width: 0;
    @include d {
      width: 10px;
      height: 6px; } }
  &[data-type*="select-one"].is-open:after {
    margin-top: 0;
    transform: rotate(180deg); }
  label.error {
    position: absolute;
    left: 0;
    bottom: -15px;
    font-size: 12px;
    color: #ff4957; } }

.map {
  width: 100%;
  height: 630px;
  @include m {
    height: 550px; }
  .title {
    margin: 0;
    &:before {
      display: none; } }
  .address {
    display: block; } }

.infobox {
  overflow: visible!important; }

.baloon {
  position: relative;
  width: 10px;
  height: 10px;
  &__wrap {
    position: absolute;
    top: -150px;
    left: 40px;
    width: 400px;
    border-radius: 5px;
    box-shadow: 5px 0 10px rgba(0, 0, 0, 0.1);
    background-color: white;
    @include d {
      width: 300px; }
    @include m {
      width: 250px; }
    &:before {
      content: "";
      position: absolute;
      top: 167px;
      left: -15px;
      @include arr(15, 19, white, l); } }
  &__head {
    padding: 25px 22px 18px;
    border-bottom: 1px solid #e6e8ea;
    @include m {
      padding: 25px 15px 15px; } }
  &__title {
    position: relative;
    margin-bottom: 15px;
    padding-bottom: 12px;
    @include fm;
    text-transform: none;
    font-size: 18px;
    color: $black;
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 104px;
      height: 6px;
      background: url('/img/line-red.png') repeat-x 0 0 / auto 100%; } }
  &__address.address {
    display: flex;
    margin-bottom: 15px;
    @include f;
    font-size: 15px; }
  &__address .address__metro {
    margin-bottom: 12px; }
  &__schedule .schedule__icon {
    margin-right: 15px; }
  &__foot {
    padding: 15px 22px 30px; }
  &__phone .icon-phone {
    fill: white; }
  &__phone .btn {
    min-width: 175px;
    &:hover .icon-phone {
      fill: white; } } }

.likely {
  &__icon,
  &__counter {
    display: none; } }










